import React from "react";

const FloatingButton = () => (
  <div className="z-[999] bg-opacity-45 fixed bottom-10 bg-white h-auto right-10 flex flex-col justify-center items-center p-5 gap-5 rounded-full">
    <span className="w-[104px] h-[104px] rounded-full bg-backgroud flex flex-wrap justify-center items-center">
      <h2 className="text-lg font-bold text-primary inline-block text-center cursor-pointer">
        Contact <br />
        Us
      </h2>
    </span>
    <span className="w-[104px] h-[104px] rounded-full bg-backgroud flex flex-wrap justify-center items-center">
      <a
        href="/#"
        className="text-lg font-bold text-primary inline-block text-center cursor-pointer"
      >
        Top
      </a>
    </span>
  </div>
);

export default FloatingButton;
