import React from "react";
import { atmosfer, arrowRight } from "../../assets";

const Section1 = () => {
  return (
    <section
      style={{
        backgroundImage: `url(${atmosfer})`,
      }}
      className="w-full h-[943px] bg-no-repeat bg-cover bg-center pt-36"
    >
      <div className="text-primary text-2xl flex gap-6 max-w-[1080px] w-full mx-auto px-2 flex-col">
        <div className="flex gap-6">
          <p>MAIN</p>
          <img src={arrowRight} alt={arrowRight} />
          <p>APPLICATION</p>
        </div>
        <div className="text-white pt-14 text-7xl py-8 border-white border-b border-spacing-4">
          IN THE FUTURE
        </div>
        <h1 className="text-white pt-36 text-6xl">
          3ALOGICS는 <br /> NFC 4대 핵심 분야에 <br /> 집중 투자합니다.
        </h1>
        <p className="text-white py-10 text-lg text-wrap mr-[620px]">
          3ALogics는 NFC 4대 핵심 분야에 집중 투자하여 세계적 수준의 NFC IC
          국산화 및 핵심 IP를 확보하며, 경쟁력 있는 NFC 응용 IC 상용화를 통해
          Global Star Fabless로 도약하고 있습니다.
        </p>
      </div>
    </section>
  );
};

export default Section1;
