import React from "react";
import { dashCar, payScanner, labelPrice, scanBridge } from "../../../assets";

const StickyCard = () => (
  <div className="relative w-full flex flex-col gap-28 justify-end items-end">
    <section className="w-full h-auto flex flex-col gap-20">
      <div className="relative flex flex-col pt-32 mb-[-150px] bg-transparent fade-scroll">
        <img src={dashCar} alt={dashCar} className="rounded-xl" />
        <span className="relative left-0 px-10 mt-[-50px]">
          <h1 className="text-white text-[58px] font-bold">Environtment</h1>
          <h3>차량용 NFC 반도체 국산화</h3>
        </span>
      </div>
      <div className="relative flex flex-col pt-32 mb-[-150px] bg-transparent fade-scroll">
        <img src={payScanner} alt={payScanner} className="rounded-xl" />
        <span className="relative left-0 px-10 mt-[-50px]">
          <h1 className="text-white text-[58px] font-bold">Activation Tag</h1>
          <h3>정품 인증 태그칩 국내 1위</h3>
        </span>
      </div>
    </section>
    <section className="w-full h-auto flex flex-col gap-20">
      <div className="relative flex flex-col pt-32 mb-[-150px] bg-transparent fade-scroll">
        <img src={labelPrice} alt={labelPrice} className="rounded-xl" />
        <span className="relative left-0 px-10 mt-[-50px]">
          <h1 className="text-white text-[58px] font-bold">ESL Tag</h1>
          <h3>ESL용 태그칩 국내 2위</h3>
        </span>
      </div>
      <div className="relative flex flex-col pt-32 mb-[-150px] bg-transparent fade-scroll">
        <img src={scanBridge} alt={scanBridge} className="rounded-xl" />
        <span className="relative left-0 px-10 mt-[-50px]">
          <h1 className="text-white text-[58px] font-bold">Access Control</h1>
          <h3>출입제어용 NFC 리더칩 국내 1위</h3>
        </span>
      </div>
    </section>
  </div>
);

export default StickyCard;
