import { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";

import { logo, night, search, hamburger } from "../../assets";

const Header = () => {
  const [language, setLanguage] = useState(false);
  const [bgNavbar, setBgNavbar] = useState(false);
  const [hovermenu, setHovermenu] = useState(0);
  const [submenuToggle, setSubmenuToggle] = useState(true);

  const submenu = (number) => {
    if (number === 6) {
      setSubmenuToggle(!submenuToggle);
      if (submenuToggle) {
        setHovermenu(number);
      } else {
        setHovermenu(0);
      }
    } else {
      setSubmenuToggle(true);
      setHovermenu(number);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", calback);
  }, []);

  const calback = () => {
    let topPage = document.documentElement.scrollTop;
    if (topPage > 100) {
      setBgNavbar(true);
    } else {
      setBgNavbar(false);
    }
  };

  return (
    <>
      <div className="w-full fixed top-0 bg-tranparent z-[999999]">
        <div className="w-full bg-backgroud sticky">
          <div className="w-full max-w-[1440px] flex justify-end mx-auto px-24">
            <h1 className="inline-block px-2 py-1 text-white">정품인증</h1>
          </div>
        </div>
        <div
          className={`w-full relative transition-colors duration-500  ease ${
            bgNavbar ? "bg-backgroud" : "bg-tranparent"
          }`}
        >
          <nav
            className={`mx-auto before:transition-colors before:duration-1000 before:ease after:transition-colors after:duration-1000 after:ease flex flex-wrap  justify-between items-center relative px-10 max-w-[1440px] before:content-[''] before:absolute before:h-[65px] before:w-[100%] before:left-[-100%] before:top-0 after:content-[''] after:absolute after:h-[100%] after:w-[100%] after:right-[-100%] after:top-0 ${
              bgNavbar
                ? "before:bg-backgroud after:bg-backgroud"
                : "before:bg-tranparent after:bg-tranparent "
            }`}
          >
            <ul className="flex flex-wrap justify-center items-center">
              <li className="list-none cursor-pointer text-white flex flex-row justify-center items-end gap-2 text-2xl">
                <Link
                  to={`/`}
                  className="flex flex-row justify-center items-end gap-2"
                >
                  <img src={logo} alt={logo} />
                  <h2 className="uppercase font-bold text-end">3alogics</h2>
                </Link>
              </li>
            </ul>
            <ul className="flex flex-wrap justify-center items-center gap-10">
              <li
                className="list-none cursor-pointer text-white text-xl py-5 hover:font-bold"
                onMouseEnter={() => submenu(1)}
                onMouseLeave={() => submenu(0)}
              >
                <Link to={`company`}> COMPANY </Link>
                <div
                  className={`absolute left-0 right-0 top-[100%] w-full ${
                    hovermenu === 1 ? "visible" : "invisible"
                  }`}
                >
                  <div className="w-full bg-[#FFFFFF] relative before:content-[''] before:absolute before:h-[100%] before:w-[100%] before:bg-[#FFFFFF] before:left-[-100%] before:top-0 after:content-[''] after:absolute after:h-[100%] after:w-[100%] after:bg-[#FFFFFF] after:right-[-100%] after:top-0">
                    <ul className="w-full mx-auto flex flex-wrap justify-center items-center gap-10">
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        회사개요
                        <ul className="absolute left-0 right-0 top-[100%] w-full bg-[#F2F2F2] mx-auto invisible opacity-0 group-hover:opacity-100 transition-opacity duration-800 ease-in-out group-hover:visible flex flex-wrap justify-center items-center gap-10 py-10 before:content-[''] before:absolute before:h-[100%] before:w-[100%] before:bg-[#F2F2F2] before:left-[-100%] before:top-0 after:content-[''] after:absolute after:h-[100%] after:w-[100%] after:bg-[#F2F2F2] after:right-[-100%] after:top-0">
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            회사소개
                          </li>
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            CEO Message
                          </li>
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            History
                          </li>
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            CI
                          </li>
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            Awards
                          </li>
                        </ul>
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        NEWS ROOM
                        <ul className="absolute left-0 right-0 top-[100%] w-full bg-[#F2F2F2] mx-auto invisible opacity-0 group-hover:opacity-100 transition-opacity duration-800 ease-in-out group-hover:visible flex flex-wrap justify-center items-center py-10 gap-10 before:content-[''] before:absolute before:h-[100%] before:w-[100%] before:bg-[#F2F2F2] before:left-[-100%] before:top-0 after:content-[''] after:absolute after:h-[100%] after:w-[100%] after:bg-[#F2F2F2] after:right-[-100%] after:top-0">
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            Notice
                          </li>
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            News
                          </li>
                        </ul>
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        NETWORK
                        <ul className="absolute left-0 right-0 top-[100%] w-full bg-[#F2F2F2] mx-auto invisible opacity-0 group-hover:opacity-100 transition-opacity duration-800 ease-in-out group-hover:visible flex flex-wrap justify-center items-center py-10 gap-10 before:content-[''] before:absolute before:h-[100%] before:w-[100%] before:bg-[#F2F2F2] before:left-[-100%] before:top-0 after:content-[''] after:absolute after:h-[100%] after:w-[100%] after:bg-[#F2F2F2] after:right-[-100%] after:top-0">
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            Network
                          </li>
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            오시는길
                          </li>
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            FAQ
                          </li>
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            Contact
                          </li>
                          <li className="list-none hover:font-bold transition-all duration-150 text-slate-700 cursor-pointer font-normal">
                            Recruit
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li
                className="list-none cursor-pointer text-white text-xl py-5 hover:font-bold transition-all duration-500"
                onMouseEnter={() => submenu(2)}
                onMouseLeave={() => submenu(0)}
              >
                <Link to={`application`}> APPLICATION </Link>
                <div
                  className={`absolute left-0 right-0 top-[100%] w-full ${
                    hovermenu === 2 ? "visible" : "invisible"
                  }`}
                >
                  <div className="w-full bg-[#FFFFFF] relative before:content-[''] before:absolute before:h-[100%] before:w-[100%] before:bg-[#FFFFFF] before:left-[-100%] before:top-0 after:content-[''] after:absolute after:h-[100%] after:w-[100%] after:bg-[#FFFFFF] after:right-[-100%] after:top-0">
                    <ul className="w-full mx-auto flex flex-wrap justify-center items-center gap-10">
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        In the Future
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Smart Logistics & Retail
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Automotive
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Access Control
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Brand Protection
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        NedgeS
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li
                className="list-none cursor-pointer text-white text-xl py-5 hover:font-bold transition-all duration-500"
                onMouseEnter={() => submenu(3)}
                onMouseLeave={() => submenu(0)}
              >
                <Link to={`product`}> PRODUCT </Link>
                <div
                  className={`absolute left-0 right-0 top-[100%] w-full ${
                    hovermenu === 3 ? "visible" : "invisible"
                  }`}
                >
                  <div className="w-full bg-[#FFFFFF] relative before:content-[''] before:absolute before:h-[100%] before:w-[100%] before:bg-[#FFFFFF] before:left-[-100%] before:top-0 after:content-[''] after:absolute after:h-[100%] after:w-[100%] after:bg-[#FFFFFF] after:right-[-100%] after:top-0">
                    <ul className="w-full mx-auto flex flex-wrap justify-center items-center gap-10">
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Reader IC
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Passive Tag IC
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Dynamic Tag IC
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Smart Tag IC
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Specialized Chipset
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li
                className="list-none cursor-pointer text-white text-xl py-5 hover:font-bold transition-all duration-500"
                onMouseEnter={() => submenu(4)}
                onMouseLeave={() => submenu(0)}
              >
                R&D
                <div
                  className={`absolute left-0 right-0 top-[100%] w-full ${
                    hovermenu === 4 ? "visible" : "invisible"
                  }`}
                >
                  <div className="w-full bg-[#FFFFFF] relative before:content-[''] before:absolute before:h-[100%] before:w-[100%] before:bg-[#FFFFFF] before:left-[-100%] before:top-0 after:content-[''] after:absolute after:h-[100%] after:w-[100%] after:bg-[#FFFFFF] after:right-[-100%] after:top-0">
                    <ul className="w-full mx-auto flex flex-wrap justify-center items-center gap-10">
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Certification
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Competitivity
                      </li>
                      <li className="list-none text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group inline-block py-10 font-normal">
                        Patent
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="list-none cursor-pointer text-slate-400 text-xl">
                IR
              </li>
              <li className="list-none cursor-pointer text-slate-400 text-xl">
                ESG
              </li>
            </ul>
            <ul className="flex flex-wrap justify-center items-center gap-10">
              <li
                className={`list-none cursor-pointer text-white text-xl ${
                  language ? "font-normal" : "font-bold"
                }`}
                onClick={() => setLanguage(false)}
              >
                KR
              </li>
              <li className="list-none cursor-pointer text-white text-xl flex flex-row justify-center items-center">
                <div className="w-[30px] h-0.5 bg-white rounded-full relative flex flex-row justify-center items-center">
                  <span
                    className={`absolute h-2 w-2 rounded-full bg-white transition-all ease-in-out delay-100 ${
                      language ? "translate-x-[12px]" : "-translate-x-[12px]"
                    }`}
                  ></span>
                </div>
              </li>
              <li
                className={`list-none cursor-pointer text-white text-xl ${
                  language ? "font-bold" : "font-normal"
                }`}
                onClick={() => setLanguage(true)}
              >
                EN
              </li>
              <li className="list-none cursor-pointer text-white text-xl darktoggle ">
                <img src={night} alt={night} />
              </li>
              <li
                className="list-none cursor-pointer text-white text-xl py-5"
                onMouseEnter={() => submenu(5)}
                onMouseLeave={() => submenu(0)}
              >
                <img src={search} alt={search} />
                <div
                  className={`absolute left-0 right-0 top-[100%] w-full ${
                    hovermenu === 5 ? "visible" : "invisible"
                  }`}
                >
                  <div className="w-full bg-[#FFFFFF] relative px-10 py-5 before:content-[''] before:absolute before:h-[100%] before:w-[100%] before:bg-[#FFFFFF] before:left-[-100%] before:top-0 after:content-[''] after:absolute after:h-[100%] after:w-[100%] after:bg-[#FFFFFF] after:right-[-100%] after:top-0">
                    <div className="flex flex-row justify-start items-center relative px-10 gap-2 py-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="22"
                        fill="none"
                        className="stroke-[#C2C2C2] fill-[#C2C2C2]"
                      >
                        <path d="M9.74 2.06c1.95 0 3.77.76 5.15 2.13 2.84 2.84 2.84 7.46 0 10.3a7.214 7.214 0 0 1-5.15 2.13c-1.95 0-3.77-.76-5.15-2.13-2.84-2.84-2.84-7.46 0-10.3a7.214 7.214 0 0 1 5.15-2.13Zm0-1.5a8.782 8.782 0 1 0 6.21 14.99A8.782 8.782 0 0 0 9.74.56Z" />
                        <path
                          stroke-linecap="round"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                          d="m15.6 15.2 6.05 6.05"
                        />
                      </svg>
                      <input
                        type="text"
                        placeholder="검색어를 입력하세요"
                        className="w-full outline-none border-none text-slate-700"
                      />
                    </div>
                    <hr className="h-1 w-full bg-black" />
                  </div>
                </div>
              </li>
              <li
                className="list-none cursor-pointer text-white text-xl"
                onClick={() => submenu(6)}
              >
                <img src={hamburger} alt={hamburger} />
                <div
                  className={`absolute left-0 right-0 top-[100%] w-full ${
                    hovermenu === 6 ? "visible" : "invisible"
                  }`}
                  onMouseLeave={() => submenu(0)}
                >
                  <div className="w-full bg-[#FFFFFF] relative flex flex-wrap flex-row justify-center items-center py-10 mx-auto before:content-[''] before:absolute before:h-[100%] before:w-[100%] before:bg-[#FFFFFF] before:left-[-100%] before:top-0 after:content-[''] after:absolute after:h-[100%] after:w-[100%] after:bg-[#FFFFFF] after:right-[-100%] after:top-0">
                    <div className="grid grid-cols-4 flex-row justify-center items-start gap-10">
                      <ul className="w-full mx-auto flex flex-col items-start gap-5">
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold group font-normal h-7 overflow-hidden group transition-[height] duration-1000 ease-in-out hover:h-[240px] hover:overflow-auto">
                          회사개요
                          <ul className="flex flex-col gap-3 py-2">
                            <li className="text-slate-500 font-normal ease-in-out hover:font-bold text-xl">
                              회사소개
                            </li>
                            <li className="text-slate-500 font-normal ease-in-out hover:font-bold text-xl">
                              CEO Message
                            </li>
                            <li className="text-slate-500 font-normal ease-in-out hover:font-bold text-xl">
                              History
                            </li>
                            <li className="text-slate-500 font-normal ease-in-out hover:font-bold text-xl">
                              CI
                            </li>
                            <li className="text-slate-500 font-normal ease-in-out hover:font-bold text-xl">
                              Awards
                            </li>
                          </ul>
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold group font-normal h-7 overflow-hidden group transition-[height] duration-1000 ease-in-out hover:h-[120px] hover:overflow-auto">
                          NEWS ROOM
                          <ul className="flex flex-col gap-3 py-2">
                            <li className="text-slate-500 font-normal hover:font-bold text-xl">
                              Notice
                            </li>
                            <li className="text-slate-500 font-normal hover:font-bold text-xl">
                              News
                            </li>
                          </ul>
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold group font-normal h-7 overflow-hidden group transition-[height] duration-1000 ease-in-out hover:h-[240px] hover:overflow-auto">
                          NETWORK
                          <ul className="flex flex-col gap-3 py-2">
                            <li className="text-slate-500 font-normal hover:font-bold text-xl">
                              Network
                            </li>
                            <li className="text-slate-500 font-normal hover:font-bold text-xl">
                              오시는길
                            </li>
                            <li className="text-slate-500 font-normal hover:font-bold text-xl">
                              FAQ
                            </li>
                            <li className="text-slate-500 font-normal hover:font-bold text-xl">
                              Contact
                            </li>
                            <li className="text-slate-500 font-normal hover:font-bold text-xl">
                              Recruit
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="w-full mx-auto flex flex-col items-start gap-5">
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          In The Future
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Smart Logistics & Retail
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Automotive
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Access Control
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Brand Protection
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          NedgeS
                        </li>
                      </ul>
                      <ul className="w-full mx-auto flex flex-col items-start gap-5">
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Reader IC
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Passive Tag IC
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Dynamic Tag IC
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Smart Tag IC
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Specialized Chipset
                        </li>
                      </ul>
                      <ul className="w-full mx-auto flex flex-col items-start gap-5">
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Certification
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Competitivity
                        </li>
                        <li className="list-none text-left text-slate-700 cursor-pointer hover:font-bold transition-all duration-150 group font-normal">
                          Patent
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <Outlet />
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
