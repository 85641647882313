import arrowRight from "./arrow-right.svg";
import atmosfer from "./atmosfer.png";
import bgChip from "./bg-chip.png";
import blog from "./blog.svg";
import bottomHero from "./bottom-hero.png";
import brilian from "./brilian.svg";
import builderCompany from "./builder-company.svg";
import building from "./building.png";
import car from "./car.png";
import carsKeleton from "./cars-skeleton.png";
import category from "./category.svg";
import cellphone from "./cellphone.png";
import checklist from "./checklist.svg";
import chiptnr100 from "./chip-tnr100.svg";
import chip from "./chip.png";
import dashCar from "./dashboard-car.png";
import datePicker from "./datepicker.svg";
import dropdownFoot from "./dropdown-footer.svg";
import electrick from "./electric.png";
import env from "./environment.png";
import factory from "./factory.png";
import family from "./family.png";
import familys from "./familys.png";
import fb from "./fb.svg";
import grafikReport from "./grafik-report.svg";
import hamburger from "./hamburger.svg";
import handShake from "./handshake.svg";
import healtCare from "./healthcare.png";
import heroIndex from "./hero-index.png";
import heroIndexSVG from "./hero-index.svg";
import ig from "./ig.svg";
import injection from "./injection.png";
import interfaces from "./interface.svg";
import iot from "./iot.png";
import labelPrice from "./label-price.png";
import logoSecondary from "./logo-secondary.svg";
import logo from "./logo.svg";
import memory from "./memory.svg";
import motherBoardPNG from "./motherboard.png";
import night from "./night.svg";
import packages from "./package.svg";
import pathFollow from "./path-follow.svg";
import payScanner from "./pay-scanner.png";
import portal from "./portal.svg";
import presentation from "./presentation.png";
import protocol from "./protocol.svg";
import reactor from "./reactor.png";
import reportAnual from "./report-anual.svg";
import reportFinancial from "./report-financial.svg";
import rotation1 from "./rotation-1.png";
import rotation2 from "./rotation-2.png";
import rotation3 from "./rotation-3.png";
import scanBridge from "./scan-bridge.png";
import search from "./search.svg";
import sec1 from "./sec-1.png";
import secondLogo from "./second-logo.svg";
import slicing from "./slicing.svg";
import slicing2 from "./slicing2.svg";
import suitable from "./suitable.png";
import supplyVioltage from "./supply-voltage.svg";
import tag from "./tag.png";
import tnr100 from "./TNR100_CHIP.svg";
import wirelesscharge from "./wireless-charging.png";
import yt from "./yt.svg";

export {
  arrowRight,
  atmosfer,
  bgChip,
  blog,
  bottomHero,
  brilian,
  builderCompany,
  building,
  car,
  carsKeleton,
  category,
  cellphone,
  checklist,
  chiptnr100,
  chip,
  dashCar,
  datePicker,
  dropdownFoot,
  electrick,
  env,
  factory,
  family,
  familys,
  fb,
  grafikReport,
  hamburger,
  handShake,
  healtCare,
  heroIndex,
  heroIndexSVG,
  ig,
  injection,
  interfaces,
  iot,
  labelPrice,
  logoSecondary,
  logo,
  memory,
  motherBoardPNG,
  night,
  packages,
  pathFollow,
  payScanner,
  portal,
  presentation,
  protocol,
  reactor,
  reportAnual,
  reportFinancial,
  rotation1,
  rotation2,
  rotation3,
  scanBridge,
  search,
  sec1,
  secondLogo,
  slicing,
  slicing2,
  suitable,
  supplyVioltage,
  tag,
  tnr100,
  wirelesscharge,
  yt,
};
