import React, { useRef } from "react";
import { suitable } from "../../assets";

import { useInView } from "framer-motion";

const Section4 = () => {
  const homepage = useRef(null);
  const isInView = useInView(homepage, { once: true });
  return (
    <>
      <section className="relative w-full mb-20 bg-backgroud h-auto py-32">
        <div
          style={{
            backgroundImage: `url(${suitable})`,
          }}
          className={`w-full h-[360px]  bg-cover bg-center`}
        ></div>
        <div ref={homepage} className="w-full max-w-[1080px] mx-auto">
          <h2
            className={`mt-[-60px] block font-bold text-[80px] text-white transition-all duration-1000 ${
              isInView
                ? "translate-y-0 opacity-100"
                : "translate-y-[-100px] opacity-0"
            }`}
          >
            Sustainable Growth
          </h2>
          <div
            className={`font-bold text-primary text-[240px] flex mt-[-60px] transition-all duration-1000 delay-300 ${
              isInView
                ? "translate-y-0 opacity-100"
                : "translate-y-[-100px] opacity-0"
            }`}
          >
            <h1 className="text-white">ESG</h1>
            <span className="text-primary">.</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section4;
