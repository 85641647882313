import React from "react";
import { bottomHero } from "../../assets";

const Section8 = () => {
  return (
    <section className="relative flex flex-col justify-center items-center w-full pt-32">
      <div className="w-full max-w-[1080px] mx-auto mb-[-50px] z-[9]">
        <div className="font-bold text-[86px] text-white max-w-7xl leading-none flex flex-col">
          <span className="trs-esg">끊임없이 성장하는</span>
          <span className="trs-esg">3ALOGICS와 함께</span>
          <span className="trs-esg">내일의 IoT 시대를 만나보세요.</span>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${bottomHero})`,
        }}
        className="relative w-full  bg-no-repeat bg-cover bg-center h-[360px]"
      ></div>
    </section>
  );
};

export default Section8;
