import React, { useState, useEffect, useRef } from "react";
import { dropdownFoot } from "../../assets";

const Contact = () => {
  const [autoclose, setAutoclose] = useState(0);
  const [dropdown1, setDropdown1] = useState("문의구분(대분류)");
  const [dropdown2, setDropdown2] = useState("문의구분(소분류)");
  const [data, setData] = useState("");
  const [checked, setChecked] = useState(false);

  const comment = useRef();

  const openDropdown = (num) => {
    if (autoclose === num) {
      setAutoclose(0);
    } else {
      setAutoclose(num);
    }
  };

  const changeChecked = () => {
    if (comment.current.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  const changeContext = (val, index) => {
    if (index === 1) {
      setDropdown1(val);
    }
    if (index === 2) {
      setDropdown2(val);
    }
    openDropdown(index);
  };

  return (
    <section className="grid grid-cols-3 gap-5 justify-center items-center text-white max-w-[1080px] mx-auto w-full h-auto py-32">
      <div className="flex flex-col justify-start items-start max-w-sm gap-5 h-full">
        <h1 className="font-bold text-6xl text-white">CONTACT US</h1>
        <h3 className="text-white text-lg font-normal">
          궁금하신 점이나 계약 관련 문의는 이곳을 이용해주세요.
        </h3>
      </div>
      <div className="col-span-2 flex justify-start items-start flex-row gap-10 h-full">
        <div className="w-full max-w-64 flex flex-col gap-10">
          <div
            class={`border-b-2 border-primary overflow-hidden transition-[height] duration-500 ${
              autoclose === 1 ? "h-[250px]" : "h-16"
            }`}
          >
            <button
              className="text-white text-lg font-medium w-full flex justify-between items-center py-4"
              onClick={() => openDropdown(1)}
            >
              <span className="text-left">{dropdown1} *</span>
              <img
                src={dropdownFoot}
                alt={dropdownFoot}
                className={`${autoclose === 1 ? "rotate-180" : "rotate-0"}`}
              />
            </button>
            <ul className="flex flex-col justify-center items-center gap-5 py-5 w-full">
              <li
                className="bg-backgroud hover:bg-primary py-1 rounded-lg cursor-pointer w-full text-center"
                onClick={() => changeContext("제품문의", 1)}
              >
                제품문의
              </li>
              <li
                className="bg-backgroud hover:bg-primary py-1 rounded-lg cursor-pointer w-full text-center"
                onClick={() => changeContext("채용문의", 1)}
              >
                채용문의
              </li>
              <li
                className="bg-backgroud hover:bg-primary py-1 rounded-lg cursor-pointer w-full text-center"
                onClick={() => changeContext("기타문의", 1)}
              >
                기타문의
              </li>
            </ul>
          </div>
          <div
            class={`border-b-2 border-primary overflow-hidden transition-[height] duration-500 ${
              autoclose === 2 ? "h-[200px]" : "h-16"
            }`}
          >
            <button
              className="text-white text-lg font-medium w-full flex justify-between items-center py-4"
              onClick={() => openDropdown(2)}
            >
              <span className="text-left">{dropdown2} </span>
              <img
                src={dropdownFoot}
                alt={dropdownFoot}
                className={`${autoclose === 2 ? "rotate-180" : "rotate-0"}`}
              />
            </button>
            <ul className="flex flex-col justify-center items-center gap-5 py-5 w-full">
              <li
                className="bg-backgroud hover:bg-primary py-1 rounded-lg cursor-pointer w-full text-center"
                onClick={() => changeContext("APPLICATION", 2)}
              >
                APPLICATION
              </li>
              <li
                className="bg-backgroud hover:bg-primary py-1 rounded-lg cursor-pointer w-full text-center"
                onClick={() => changeContext("PRODUCT", 2)}
              >
                PRODUCT
              </li>
            </ul>
          </div>
          <button className="text-slate-500 text-lg font-medium w-full flex justify-between items-center py-4 border-b-2 border-primary">
            <span className="text-left">성함 입력란 *</span>
          </button>
          <button className="text-slate-500 text-lg font-medium w-full flex justify-between items-center py-4 border-b-2 border-primary">
            <span className="text-left">이메일 입력란 *</span>
          </button>
          <button className="text-slate-500 text-lg font-medium w-full flex justify-between items-center py-4 border-b-2 border-primary">
            <span className="text-left">전화번호 입력란 *</span>
          </button>
          <button className="text-slate-500 text-lg font-medium w-full flex justify-between items-center py-4 border-b-2 border-primary">
            <span className="text-left">회사명 입력란 *</span>
          </button>
        </div>
        <div className="flex flex-col justify-start items-start w-full h-full gap-5">
          <textarea
            name=""
            id=""
            className="w-full px-10 py-[60px] text-slate-500 bg-backgroud outline-none rounded-xl resize-none"
            rows="10"
          >
            문의내용 입력란 *
          </textarea>
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex gap-2 items-center">
              <label
                for="coment"
                className="h-5 w-5 border border-primary flex justify-center items-center p-1"
              >
                <svg
                  width="17"
                  height="12"
                  viewBox="0 0 17 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${checked ? "block" : "hidden"}`}
                >
                  <path
                    d="M1 6L6 11L16 1"
                    stroke="#093CD0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </label>
              <input
                id="coment"
                ref={comment}
                type="checkbox"
                className="h-5 w-5 outline-none bg-backgroud border-none border border-primary hidden"
                onClick={() => changeChecked()}
              />
              <span className="text-white font-medium text-lg">
                개인정보수집에 동의합니다.
              </span>
            </div>
            <span className="px-2 py-2 border-b border-primary text-white font-medium text-2xl">
              접수하기
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
