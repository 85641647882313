import React, { useEffect } from "react";
import {
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8,
  FloatingButton,
} from "../components/homepage";

import { Layout } from "../components/layouts";

const Homepage = () => {
  useEffect(() => {
    const esgSection = document.querySelectorAll(".trs-esg");
    const stckySection = document.querySelectorAll(".animation-sticky-index");
    const chartSection = document.querySelectorAll(".highcharts-figure");

    let options = {
      root: null,
      rootMargin: "20px",
      // threshold: buildThresholdList(),
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entri) => {
        var oldScrollY = window.scrollY;
        if (entri.isIntersecting) {
          if (entri.target.classList.contains("trs-esg")) {
            entri.target.classList.add("show-animate-esg");
          } else {
            entri.target.classList.add("show-animate-sticky");
          }
        } else {
          if (oldScrollY > window.scrollY) {
            entri.target.classList.remove("show-animate-sticky");
          } else {
          }
          entri.target.classList.remove("show-animate-esg");
        }
        oldScrollY = window.scrollY;
      });
    }, options);

    // observe all hidden element
    esgSection.forEach((intercetion) => observer.observe(intercetion));
    stckySection.forEach((intercetion) => observer.observe(intercetion));
    chartSection.forEach((intercetion) => observer.observe(intercetion));
  }, []);
  return (
    <Layout>
      <div className="bg-backgroud relative">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <FloatingButton />
      </div>
    </Layout>
  );
};

export default Homepage;
