import React from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { factory } from "../../assets";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { stagger } from "framer-motion";

const Section7 = () => {
  return (
    <section className="relative w-full flex grid-cols-3 py-32 max-w-[1080px] mx-auto h-auto justify-center items-center">
      <div className="w-full flex flex-col gap-20 max-w-[300px] justify-start items-start">
        <div className="flex flex-col justify-start gap-2">
          <h1 className="font-bold text-5xl text-white max-w-[200px]">
            News & Notice
          </h1>
          <h2 className="text-lg text-white font-medium">
            3ALogics의 새소식 & 공지사항을 전해드립니다.
          </h2>
        </div>
        <span className="w-24 h-24 bg-primary rounded-full flex justify-center items-center">
          <h1 className="font-bold text-lg text-backgroud">more</h1>
        </span>
      </div>
      <div className="w-full col-span-2 p-10 flex gap-10 overflow-x-scroll overflow-y-hidden">
        <Swiper
          // install Swiper modules
          modules={[Scrollbar, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={2.3}
          initialSlide="0"
          // direction="vertical"
          // navigation
          loop
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          autoplay
          mousewheel={{
            invert: false,
          }}

          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
        >
          {[...Array(10)].map((i, key) => (
            <SwiperSlide key={i}>
              <div className="min-w-[303px] w-full min-h-[409px] flex h-full flex-col justify-between items-start">
                <h1 className="text-primary font-bold text-lg block">
                  2023.11.27
                </h1>
                <div className="flex flex-col justify-start gap-5">
                  <h1 className="text-white font-bold text-lg">
                    중국 지사 설립 - Global Business Center 심천 입주
                  </h1>
                  <h2 className="text-white font-bold text-base">
                    쓰리에이로직스(주)는 중소벤처기업진흥공단에서 지원하는
                    Global Business Center 사업에 최종 선정되어, 2023년 11월
                    14일 GBC 심천 사무실로 입주하였습니다. 중국 내 신규 사업 및
                    고객 발굴을 위해 활용...
                  </h2>
                  <img
                    src={factory}
                    alt={factory}
                    className="bg-cover rounded-xl h-[200px]"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* {[...Array(10)].map((i, key) => (
          <div
            key={key}
            className="min-w-[303px] w-full min-h-[409px] flex h-full flex-col justify-between items-start"
          >
            <h1 className="text-primary font-bold text-lg block">
              2023.11.27 {i}
            </h1>
            <div className="flex flex-col justify-start gap-5">
              <h1 className="text-white font-bold text-lg">
                중국 지사 설립 - Global Business Center 심천 입주
              </h1>
              <h2 className="text-white font-bold text-base">
                쓰리에이로직스(주)는 중소벤처기업진흥공단에서 지원하는 Global
                Business Center 사업에 최종 선정되어, 2023년 11월 14일 GBC 심천
                사무실로 입주하였습니다. 중국 내 신규 사업 및 고객 발굴을 위해
                활용...
              </h2>
              <img
                src={factory}
                alt={factory}
                className="bg-cover rounded-xl h-[200px]"
              />
            </div>
          </div>
        ))} */}
      </div>
    </section>
  );
};

export default Section7;
