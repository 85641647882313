import React from "react";
import { slicing, slicing2, portal } from "../../assets";

const Section4 = () => (
  <section className="w-full max-w-[1080px] text-white mx-auto py-32 pl-10">
    <div className="w-full flex flex-col justify-start items-start gap-20">
      <h3 className="font-bold text-lg text-primary">OUR VALUE</h3>
      <div className="flex flex-col justify-start gap-14">
        <h1 className="font-medium text-5xl text-white max-w-screen-md">
          3ALOGICS는 <br />
          행복경영을 1순위 핵심가치로 생각합니다.
        </h1>
        <h3>
          행복경영을 통해 임직원, 주주, 고객, 외부 이해 관계자 모두가 행복한
          선순환구조를 지향합니다.
        </h3>
      </div>
    </div>
    <div className="w-full mt-24 mb-24 relative grid grid-cols-3 min-h-[372px] text-white justify-center items-center rotate-90 lg:rotate-0">
      <div className="relative">
        <div className="flex flex-col justify-start items-center portal-hidden mr-5">
          <span className="py-2 border-b border-primary mb-5">TECHNOLOGY</span>
          <p>국내 최고 기술</p>
        </div>
        <img
          src={portal}
          alt={portal}
          className="absolute bottom-[-130%] left-[3%]"
        />
      </div>
      <div className="relative ml-5">
        <div className="flex flex-col justify-start items-center portal-hidden">
          <span className="py-2 border-b border-primary mb-5">
            ORGANIZATION
          </span>
          <p>행복 경영</p>
        </div>
        <img
          src={portal}
          alt={portal}
          className="absolute bottom-[-130%] left-[3%]"
        />
      </div>
      <div className="relative ml-5">
        <div className="flex flex-col justify-start items-center portal-hidden">
          <span className="py-2 border-b border-primary mb-5">CUSTOMER</span>
          <p>고객 최우선</p>
        </div>
        <img
          src={portal}
          alt={portal}
          className="absolute bottom-[-130%] left-[3%]"
        />
      </div>
      <img src={slicing} alt={slicing} className="absolute left-[-2%]" />
      <img src={slicing2} alt={slicing2} className="absolute right-[-2%]" />
    </div>
  </section>
);

export default Section4;
