import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./style.css";
// import {
//   createBrowserRouter,
//   createRoutesFromElements,
//   RouterProvider,
//   Route,
// } from "react-router-dom";
// import { Homepage, Company } from "./pages";
// import { Header } from "./components/layouts";
// import ErrorPage from "./ErrorPage";
import App from "./App";

import reportWebVitals from "./reportWebVitals";

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route path="/" element={<Header />}>
//       <Route path="dashboard" element={<Homepage />} />
//       {/* ... etc. */}
//     </Route>
//   )
//   [
//   {
//     path: "/",
//     element: <Homepage />,
//     errorElement: <ErrorPage />,
//   },
//   {
//     path: "/company",
//     element: <Company />,
//     errorElement: <ErrorPage />,
//   },
// ]
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
  </>
  // <React.StrictMode>
  // {/* <RouterProvider router={router} /> */}
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
