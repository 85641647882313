import React, { useState, useEffect } from "react";
import { jsonFooter } from "../../constans";
import { yt, blog, ig, fb, secondLogo, dropdownFoot } from "../../assets";

const Footer = () => {
  const [changeSite, setChangeSite] = useState("FAMILY SITE");
  const [drop, setDrop] = useState(false);
  // const [data, setData] = useState([]);

  const dropdownFooter = () => {
    setDrop(!drop);
  };
  useEffect(() => {
    // setData(jsonFooter.kr);
  }, []);
  return (
    <footer className="py-20 bg-backgroud w-full px-10 mx-auto">
      <div className="max-w-[1440px] w-full grid grid-cols-7 justify-between items-start gap-10 mx-auto px-10">
        <div className="col-span-5 flex flex-row justify-start items-start w-full">
          <div className="flex flex-row justify-start items-center gap-2 mr-10">
            <img src={secondLogo} alt={secondLogo} />
            <h2 className="uppercase font-bold text-end text-secondary text-2xl">
              3alogics
            </h2>
          </div>
          <div className="flex flex-col justify-start items-center gap-10">
            <div className="flex flex-row justify-start items-center divide-x-2">
              <h1 className="px-10 text-white font-bold text-lg">
                개인정보처리방침
              </h1>
              <h1 className="px-10 text-white font-bold text-lg">
                제품 및 기술 문의
              </h1>
              <h1 className="px-10 text-white font-bold text-lg">
                제품자료 다운로드
              </h1>
            </div>
            <div className="flex flex-col justify-start items-start text-white w-full gap-5">
              <h1 className="text-white">쓰리에이로직스(주)</h1>
              <div className="flex flex-col gap-1 justify-center items-start">
                <span>사업자등록번호:&nbsp;129-81-71839 대표:이평한</span>
                <span>
                  주소:&nbsp;경기도 성남시 수정구 금토로 40번길 26, 5층(금토동,
                  제2판교테크노밸리 세미플렉스타워 N동) (13453)
                </span>
                <span>
                  대표전화:&nbsp;031-715-7117 이메일: info@3alogics.com
                </span>
              </div>
              <span className="mt-10">
                Copyright {new Date().getFullYear()}. 3ALogics all rights
                reserved.
              </span>
            </div>
          </div>
        </div>
        <div className="relative col-span-2 max-w-sm w-full grid grid-rows-2 h-full items-start">
          <div className="relative w-full h-20 grid grid-rows-1">
            <button
              className="text-lg font-medium bg-transparent text-white px-10 border-b-2 border-x-0 border-t-0 border-blue-500 border w-full text-left flex flex-wrap justify-between items-center"
              onClick={() => dropdownFooter()}
            >
              {changeSite}
              <img
                src={dropdownFoot}
                alt={dropdownFoot}
                className={`${
                  drop ? "rotate-180" : "rotate-0"
                } transition-all duration-500 `}
              />
            </button>
            <div
              className={`absolute z-[99999] top-[100%] mt-2 flex flex-col gap-2 bg-black border-2 border-white rounded-lg max-h-24 w-full overflow-auto px-6 py-3 justify-center items-center ${
                drop ? "visible" : "invisible"
              }`}
            >
              <ul className="w-full" onMouseLeave={() => setDrop(false)}>
                <li
                  className="w-full text-white cursor-pointer hover:bg-primary hover:text-black text-center rounded-full"
                  onClick={() => setChangeSite("FAMILY SITE")}
                >
                  FAMILY SITE
                </li>
                <li
                  className="w-full text-white cursor-pointer hover:bg-primary hover:text-black text-center rounded-full"
                  onClick={() => setChangeSite("NedgeS")}
                >
                  NedgeS
                </li>
              </ul>
            </div>
          </div>
          <div className="absolute bottom-0 flex flex-wrap gap-7">
            <a
              href={jsonFooter.youtube}
              className="w-12 h-12 flex justify-center items-center rounded-full bg-[#969696]"
            >
              <img src={yt} alt={yt} />
            </a>
            <a
              href={jsonFooter.fb}
              className="w-12 h-12 flex justify-center items-center rounded-full bg-[#969696]"
            >
              <img src={fb} alt={fb} />
            </a>
            <a
              href={jsonFooter.ig}
              className="w-12 h-12 flex justify-center items-center rounded-full bg-[#969696]"
            >
              <img src={ig} alt={ig} />
            </a>
            <a
              href={jsonFooter.blog}
              className="w-12 h-12 flex justify-center items-center rounded-full bg-[#969696]"
            >
              <img src={blog} alt={blog} />
            </a>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between items-start"></div>
    </footer>
  );
};

export default Footer;
