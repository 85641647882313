import React, { useEffect } from "react";
import { Section1, Section2, Section3, Section4 } from "../components/company";

import { Layout } from "../components/layouts";

const Company = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entri) => {
        if (entri.isIntersecting) {
          if (entri.target.classList.contains("fade-scroll")) {
            entri.target.classList.add("show-animate-scroll");
          } else {
            entri.target.classList.add("show-animate");
          }
        } else {
          entri.target.classList.remove("show-animate");
        }
      });
    });

    const hiddenSection = document.querySelectorAll(".card-hidden");
    const portalSection = document.querySelectorAll(".portal-hidden");
    const fadeSection = document.querySelectorAll(".fade-scroll");
    // observe all hidden element
    fadeSection.forEach((intercetion) => observer.observe(intercetion));
    hiddenSection.forEach((intercetion) => observer.observe(intercetion));
    portalSection.forEach((intercetion) => observer.observe(intercetion));
  }, []);
  return (
    <Layout>
      <div className="bg-backgroud w-full">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
      </div>
    </Layout>
  );
};

export default Company;
