import React from "react";
import { tnr100 } from "../../../assets";

const TNR = ({ type }) => (
  <div className="max-w-[300px] w-full min-h-[168px] bg-slate-900 grid grid-cols-2 px-3 py-5 rounded-lg">
    <div className="flex flex-col justify-between items-start">
      <div className="flex flex-col gap-2 justify-start items-start">
        <h2 className="font-bold text-md text-white">Reader IC</h2>
        <h1 className="font-bold text-2xl text-white">{type}</h1>
      </div>
      <button className="text-primary font-bold text-xl">view more</button>
    </div>
    <div className="flex justify-center items-center">
      <img src={tnr100} alt={tnr100} />
    </div>
  </div>
);

export default TNR;
