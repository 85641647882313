import React, { useState, useRef, useEffect, useMemo } from "react";
import { car } from "../../assets";

const Section2 = () => {
  const [openTab, setOpenTab] = useState(1);
  const svgIcon = useRef([]);
  const carsTitle = useRef([]);
  const toggleTabs = (val) => {
    setOpenTab(val);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const svgIcon = document.querySelectorAll(".svg");

      entries.forEach((entri) => {
        if (entri.isIntersecting) {
          if (entri.target.classList.contains("hidden-show")) {
            entri.target.classList.add("update-hidden-show");

            carsTitle.current.forEach((val) => {
              val.classList.add("visible-text-cars");
            });

            svgIcon.forEach((el) => {
              el.unpauseAnimations();
            });
          }
        } else {
          entri.target.classList.remove("update-hidden-show");
          svgIcon.forEach((el) => {
            el.pauseAnimations();
          });
        }
      });
    });

    const svgShow = document.querySelectorAll(".hidden-show");
    svgShow.forEach((intercetion) => observer.observe(intercetion));
  }, []);

  return (
    <section className="flex flex-wrap  max-w-[1080px] mx-auto h-auto justify-center items-center py-32">
      <div className="w-full">
        <ul className="flex list-none pt-3 pb-4 gap-6">
          <li className="flex text-center cursor-pointer">
            <span
              className={`text-xs font-bold uppercase py-3 shadow-lg rounded block leading-normal text-white ${
                openTab === 1 ? "border-b border-primary" : ""
              }`}
              onClick={() => toggleTabs(1)}
            >
              automotive
            </span>
          </li>
          <li className="flex text-center cursor-pointer">
            <span
              className={`text-xs font-bold uppercase py-3 shadow-lg rounded block leading-normal text-white ${
                openTab === 2 ? "border-b border-primary" : ""
              }`}
              onClick={() => toggleTabs(2)}
            >
              entrance control
            </span>
          </li>
        </ul>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
          <div className="px-4 py-5 flex-auto">
            <div className="tab-content tab-space text-white">
              <div className="flex flex-row relative max-w-[803px] w-full mx-auto justify-center">
                <div className="flex flex-row relative w-full">
                  <img src={car} alt={car} />
                  <div className="absolute right-[-40px] top-[-10px] hidden-show">
                    <div className="flex gap-2 group cursor-pointer">
                      <div className="flex mt-3 relative">
                        <svg
                          ref={(el) => (svgIcon.current[0] = el)}
                          width="155"
                          height="78"
                          viewBox="0 0 155 78"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mt-[-50px] w-[180px] h-[180px] svg"
                        >
                          <path
                            d="M0.75 77.05L73.2 4.59998H150.15"
                            stroke="#093CD0"
                            stroke-width="0.9"
                            stroke-linecap="round"
                            clip-path="url(#es1)"
                          />
                          <circle
                            cx="149.925"
                            cy="4.59998"
                            r="3.6"
                            fill="#093CD0"
                            stroke="#315EE0"
                            stroke-width="1.8"
                            clip-path="url(#es1)"
                          />
                          <clipPath id="es1" className="">
                            <rect height="100%" width="0%" fill="black">
                              <animate
                                attributeName="width"
                                dur="2s"
                                from="0"
                                to="100%"
                                fill="freeze"
                              />
                            </rect>
                          </clipPath>
                        </svg>
                      </div>

                      <span
                        ref={(el) => (carsTitle.current[0] = el)}
                        className="text-slate-500 hover:text-white opacity-0"
                      >
                        {openTab === 1 ? "TNR100" : "TNR300"}
                      </span>
                    </div>
                    <div className="flex gap-2 mt-[-100px] group cursor-pointer">
                      <div className="flex mt-3 relative">
                        <svg
                          ref={(el) => (svgIcon.current[1] = el)}
                          width="155"
                          height="78"
                          viewBox="0 0 155 78"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mt-[-50px] w-[180px] h-[180px] svg"
                        >
                          <path
                            d="M0.75 77.05L73.2 4.59998H150.15"
                            stroke="#093CD0"
                            stroke-width="0.9"
                            stroke-linecap="round"
                            clip-path="url(#es2)"
                          />
                          <circle
                            cx="149.925"
                            cy="4.59998"
                            r="3.6"
                            fill="#093CD0"
                            stroke="#315EE0"
                            stroke-width="1.8"
                            clip-path="url(#es2)"
                          />
                          <clipPath id="es2">
                            <rect height="100%" width="0%" fill="black">
                              <animate
                                attributeName="width"
                                dur="2s"
                                from="0"
                                to="100%"
                                fill="freeze"
                              />
                            </rect>
                          </clipPath>
                        </svg>
                      </div>

                      <span
                        ref={(el) => (carsTitle.current[1] = el)}
                        className="text-slate-500 hover:text-white opacity-0"
                      >
                        {openTab === 1 ? "TNR200" : "TNR400"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
