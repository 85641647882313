import React, { useEffect } from "react";
import { testDataDummy } from "../../constans";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { SectionWrapper } from "../../hoc";
import { reportFinancial, reportAnual, grafikReport } from "../../assets";

const optionsHighchart = {
  chart: {
    zoomType: "x",
    backgroundColor: {
      // linearGradient: [0, 0, 500, 500],
      stops: [
        [0, "rgb(255, 255, 255)"],
        [1, "rgb(240, 240, 255)"],
      ],
    },
  },
  title: {
    text: null,
  },
  // title: {
  //   text: "USD to EUR exchange rate over time",
  //   align: "left",
  // },
  // subtitle: {
  //   text:
  //     document.ontouchstart === undefined
  //       ? "Click and drag in the plot area to zoom in"
  //       : "Pinch the chart to zoom in",
  //   align: "left",
  // },
  xAxis: {
    // type: "datetime",
    crosshair: false,
    tickLength: 0,
    gridLineWidth: 0,
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      enabled: false,
    },
    tickLength: 0,
    gridLineWidth: 0,
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    area: {
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, Highcharts.getOptions().colors[0]],
          [
            1,
            Highcharts.color(Highcharts.getOptions().colors[0])
              .setOpacity(0)
              .get("rgba"),
          ],
        ],
      },
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
  },

  series: [
    {
      color: {
        pattern: {
          path: "M 0 0 L 10 10 M 9 - 1 L 11 1 M - 1 9 L 1 11",
          width: 10,
          height: 10,
        },
      },
      // keys: ["y", "color.pattern.color"],
      type: "area",
      // name: "USD to EUR",
      data: testDataDummy,
    },
  ],
};

const Section6 = () => {
  useEffect(() => {
    const chartSection = document.querySelectorAll(".highcharts-figure");

    let options = {
      root: null,
      rootMargin: "20px",
      // threshold: buildThresholdList(),
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entri) => {
        if (entri.isIntersecting) {
          if (entri.target.classList.contains("highcharts-figure")) {
          }
        }
      });
    }, options);

    chartSection.forEach((intercetion) => observer.observe(intercetion));
  }, []);
  return (
    <section className="w-full bg-backgroud h-auto pt-32 flex justify-center items-center">
      <div className="relative w-full grid grid-cols-3 max-w-[1080px] mx-auto gap-10">
        <div className="w-full h-full flex flex-col gap-10">
          <div className="flex flex-col justify-between items-start p-8 bg-[#11131A] w-full h-full rounded-3xl">
            <img src={reportFinancial} alt={reportFinancial} />
            <div className="flex flex-col gap-1 items-start">
              <h1 className="text-white font-normal text-2xl">재무제표</h1>
              <h2 className="text-white font-normal text-base">
                Financial Report
              </h2>
            </div>
          </div>
          <div className="flex flex-col justify-between items-start p-8 bg-[#11131A] w-full h-full rounded-3xl">
            <img src={reportAnual} alt={reportAnual} />
            <div className="flex flex-col gap-1 items-start">
              <h1 className="text-white font-normal text-2xl">정기보고서</h1>
              <h2 className="text-white font-normal text-base">
                Annual Report
              </h2>
            </div>
          </div>
          <div className="flex flex-col justify-between items-start p-8 bg-[#11131A] w-full h-full rounded-3xl">
            <img src={grafikReport} alt={grafikReport} />
            <div className="flex flex-col gap-1 items-start">
              <h1 className="text-white font-normal text-2xl">실적발표</h1>
              <h2 className="text-white font-normal text-base">
                Earnings Report
              </h2>
            </div>
          </div>
        </div>
        <div className="col-span-2 p-10 w-full bg-[#11131A] h-full rounded-3xl flex flex-col justify-between items-start">
          <div className="w-full flex flex-wrap justify-between items-center">
            <h3 className="text-primary font-bold text-2xl">3ALOGICS</h3>
            <ul className="flex flex-wrap gap-5 justify-end items-center">
              <li className="list-none text-white font-bold text-lg">DAY</li>
              <li className="list-none text-white font-bold text-lg">WEEK</li>
              <li className="list-none text-white font-bold text-lg">MONTH</li>
            </ul>
          </div>
          <span className="text-white font-bold text-[65px] flex gap-1">
            58,500
            <h1>원</h1>
          </span>
          <h4 className="text-lg font-medium text-white">
            전일 대비 +1,110원 등락률 +1.9%
          </h4>
          <HighchartsReact highcharts={Highcharts} options={optionsHighchart} />
          <figure className="highcharts-figure w-full relative">
            <div id="container"></div>
            <span className="w-full absolute top-[50%] border border-dashed border-[#555555]"></span>
          </figure>
        </div>
      </div>
    </section>
  );
};

export default SectionWrapper(Section6, "");
