import React, { useEffect, useRef } from "react";
// import { SectionWrapper } from "../../hoc/SectionWrapper";
import { rotation1, rotation2, rotation3 } from "../../assets";

const Section2 = () => {
  const sction = useRef();

  useEffect(() => {
    const data = [sction.current];

    window.addEventListener("scroll", (e) => {
      for (let i = 0; i < data.length; i++) {
        tranform(data[i]);
      }
    });

    function tranform(section) {
      const offsetTop = section.parentElement.offsetTop;
      const scrollSection = section.querySelector(".cards-sticky");
      // console.log(offsetTop); //jarak stciki scroll to parent top
      // console.log(window.scrollY); //trigger scroll
      // console.log(window.innerHeight); //trigger scroll
      let percent = ((window.scrollY - offsetTop) / window.innerHeight) * 100;
      percent = percent < 0 ? 0 : percent > 94 ? 94 : percent;
      scrollSection.style.transform = `translateX(${-percent}vw)`;
    }
  }, []);
  return (
    <>
      <section className="w-full py-24 min-h-[200vh]">
        <div
          ref={sction}
          className="flex sticky top-32 justify-end items-start max-w-[1440px] w-full mx-auto h-auto overflow-hidden"
        >
          <div className="flex h-fit w-[200px]">
            <div className="flex absolute h-screen w-[800px] right-[800px] bg-gradient-to-r from-black from-80% to-transparent top-0 z-[99]"></div>
            <div className="absolute top-0 flex flex-col justify-start items-start left-32 w-[600px] z-[999]">
              <p className="text-primary font-bold text-xl">Our Companys</p>
              <h1 className="text-white font-bold text-[60px]">
                Smart NFC Choice For Your Life
              </h1>
            </div>
          </div>
          <div
            id="horizontalSnap"
            className="relative cards-sticky flex flex-row gap-24 max-w-[1100px] items-center mt-20"
          >
            <div className="snap-overview relative translate-x-[500px] min-h-[513px] min-w-[534px]">
              <img
                src={rotation1}
                alt={rotation1}
                className="flex animate-spin-slow w-full h-full"
              />
              <h1 className="text-white text-7xl font-bold absolute top-[55%] right-0">
                Any Time<span className="text-primary">.</span>
              </h1>
            </div>
            <div className="snap-overview relative translate-x-[500px] min-h-[513px] min-w-[534px]">
              <img
                src={rotation2}
                alt={rotation2}
                className="flex animate-spin-slow w-full h-full"
              />
              <h1 className="text-white text-7xl font-bold absolute top-[55%] right-0">
                Anywhere<span className="text-primary">.</span>
              </h1>
            </div>
            <div className="snap-overview relative translate-x-[500px] min-h-[513px] min-w-[534px]">
              <img
                src={rotation3}
                alt={rotation3}
                className="flex animate-spin-slow w-full h-full"
              />
              <h1 className="text-white text-7xl font-bold absolute top-[55%] right-0">
                Any Devices<span className="text-primary">.</span>
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// export default SectionWrapper(Section2, "");

export default Section2;
