import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Header } from "./components/layouts";
import { Homepage, Company, Application, Product } from "./pages";
import ErrorPage from "./ErrorPage";

const App = () => {
  return (
    <Router>
      <Header />
      <Routes errorElement={<ErrorPage />}>
        <Route path="/" element={<Homepage />} />
        <Route path="/company" element={<Company />} />
        <Route path="/application" element={<Application />} />
        <Route path="/product" element={<Product />} />
        <Route path="*" element={<Homepage />} />
      </Routes>
    </Router>
  );
};

export default App;
