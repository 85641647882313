import React from "react";
import { Layout } from "../components/layouts";
import { Contact } from "../components/globals";
import { Section1, Section2, Section3 } from "../components/product";

const Product = () => {
  return (
    <Layout>
      <div className="bg-black">
        <Section1 />
        <Section2 />
        <Section3 />
        <Contact />
      </div>
    </Layout>
  );
};

export default Product;
