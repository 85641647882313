import React from "react";
import {
  reactor,
  builderCompany,
  handShake,
  brilian,
  datePicker,
} from "../../assets";

const Section2 = () => {
  return (
    <section className="w-full max-w-[1920px] text-white mx-auto py-24">
      <div className="grid grid-cols-2 justify-center items-center">
        <div className="flex flex-col justify-center items-end gap-10 pr-[145px]">
          <h1 className="text-7xl font-bold text-white">
            ABOUT <br />
            3ALOGICS
          </h1>
          <p className="font-medium text-2xl text-white text-wrap">
            “Smart NFC Choice for your Life”
          </p>
        </div>
        <div className="w-full">
          <div
            style={{
              backgroundImage: `url(${reactor})`,
            }}
            className="w-full  rounded-3xl rounded-tl-5xl bg-cover bg-center bg-no-repeat h-[450px]"
          ></div>
        </div>
      </div>
      <div className="w-full max-w-[1080px] text-white mx-auto py-20">
        <div className="w-full flex flex-wrap justify-between items-center gap-5 mx-10">
          <div
            data-delay="5.1s"
            className="card-hidden min-h-[221px] max-w-[240px] w-full bg-[#11131A] rounded-2xl flex flex-col justify-start px-8 py-8 gap-10"
          >
            <img
              src={builderCompany}
              alt={builderCompany}
              className="w-10 h-10"
            />
            <div className="flex flex-col justify-start">
              <h1>재무제표</h1>
              <h2>쓰리에이로직스 주식회사</h2>
            </div>
          </div>
          <div
            data-delay="5.1s"
            className="card-hidden min-h-[221px] max-w-[240px] w-full bg-[#11131A] rounded-2xl flex flex-col justify-start px-8 py-8 gap-10"
          >
            <img src={datePicker} alt={datePicker} className="w-10 h-10" />
            <div className="flex flex-col justify-start">
              <h1>재무제표</h1>
              <h2>쓰리에이로직스 주식회사</h2>
            </div>
          </div>
          <div
            data-delay="5.1s"
            className="card-hidden min-h-[221px] max-w-[240px] w-full bg-[#11131A] rounded-2xl flex flex-col justify-start px-8 py-8 gap-10"
          >
            <img src={handShake} alt={handShake} className="w-10 h-10" />
            <div className="flex flex-col justify-start">
              <h1>재무제표</h1>
              <h2>쓰리에이로직스 주식회사</h2>
            </div>
          </div>
          <div
            data-delay="5.1s"
            className="card-hidden min-h-[221px] max-w-[240px] w-full bg-[#11131A] rounded-2xl flex flex-col justify-start px-8 py-8 gap-10"
          >
            <img src={brilian} alt={brilian} className="w-10 h-10" />
            <div className="flex flex-col justify-start">
              <h1>재무제표</h1>
              <h2>쓰리에이로직스 주식회사</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
