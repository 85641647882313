import React, { useEffect, useState, useMemo } from "react";
import { product } from "../../constans";

const Section3 = () => {
  const [otherChip, setOtherChip] = useState([]);
  const [openTabChip, setOpenTabChip] = useState(0);
  const [hoverAnimate, setHoverAnimate] = useState(0);

  useMemo(() => {
    setOtherChip(product.kr.section_three);
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entri) => {
        if (entri.isIntersecting) {
          if (entri.target.classList.contains("slide-left")) {
            entri.target.classList.add("show-animate-slide-left");
          } else {
            entri.target.classList.add("show-animate-reader");
          }
        } else {
          // entri.target.classList.remove("show-animate-slide-left");
          entri.target.classList.remove("show-animate-reader");
        }
      });
    });

    const portalSection = document.querySelectorAll(".portal-hidden-sec");
    const slideSection = document.querySelectorAll(".slide-left");

    // observe all hidden element
    portalSection.forEach((intercetion) => observer.observe(intercetion));
    slideSection.forEach((intercetion) => observer.observe(intercetion));
  }, []);

  const toggleChipTabs = (tabNumber) => {
    setOpenTabChip(tabNumber);
  };

  return (
    <>
      <section className="w-full mx-auto max-w-[1080px] pt-52">
        {otherChip
          ? otherChip.map((val, index) => (
              <div
                key={index}
                className={`grid grid-cols-2  ${
                  openTabChip === index ? "block" : "hidden"
                }`}
              >
                <div className="flex flex-col">
                  <div className="text-primary uppercase">reader ic</div>
                  <div class="text-white text-7xl uppercase py-6">
                    {val.title}
                  </div>
                  <div className="grid grid-cols-3 text-white gap-5 justify-start">
                    {val.specifications.map((data, ky) => (
                      <div
                        key={ky}
                        className="py-4 flex flex-col justify-start items-center gap-2 portal-hidden-sec"
                      >
                        <img className="w-10" src={data.icon} alt={data.icon} />
                        <div className="text-sm">{data.title}</div>
                        <div
                          className="text-center text-xs text-wrap"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mx-auto">
                  <div className="border border-slate-800 rounded-full bg-gradient-to-bl from-primary from-10% via-gray-900 via-35% to-backgroud w-[600px] h-[600px] items-center justify-center flex flex-col pb-2">
                    <img
                      src={val.photo}
                      className="w-[437px] h-[374px]"
                      alt={val.photo}
                    />
                  </div>
                </div>
              </div>
            ))
          : ""}
      </section>
      <section className="max-w-[1080px] w-full mx-auto py-20 flex flex-col mb-52">
        <div className="py-20 flex flex-wrap gap-2 text-5xl">
          <div className="text-white">Other Products In</div>
          <div className="text-primary">.</div>
        </div>
        <div className="grid grid-cols-5 text-2xl text-white h-full justify-start">
          <div className="col-span-2">Automotive</div>
          <div className="col-span-3">Entrance Control</div>
        </div>
        <div className="py-10 text-white grid grid-cols-5 justify-between items-center">
          {otherChip &&
            otherChip.map((i, index) => (
              <span
                key={index}
                className="items-center justify-center flex flex-col pb-2 cursor-pointer hover:border-primary hover:duration-0 slide-left"
                onMouseEnter={() => setHoverAnimate(index)}
                onMouseLeave={() => setHoverAnimate("")}
                onClick={() => toggleChipTabs(index)}
              >
                <div className="">
                  <img src={i.photo} alt="chip" />
                </div>
                <div className="py-10">{i.title}</div>
              </span>
            ))}
        </div>
        <div className="grid grid-cols-5 justify-between items-center">
          {[...Array(5)].map((x, key) => (
            <span
              key={key}
              className={`h-1 w-full hover:bg-primary cursor-pointer ${
                openTabChip === key
                  ? "bg-primary"
                  : hoverAnimate === key
                  ? "bg-primary"
                  : "bg-white"
              }`}
              onClick={() => toggleChipTabs(key)}
            >
              {key}
            </span>
          ))}
        </div>
      </section>
    </>
  );
};

export default Section3;
