import {
  category,
  protocol,
  interfaces,
  packages,
  memory,
  supplyVioltage,
  chiptnr100,
} from "../assets";

const jsonFooter = {
  youtube: "https://youtube.com/",
  facebook: "https://facebook.com/",
  instagram: "https://instagram.com/",
  blog: "https://medium.com/",
  kr: {
    headers: [
      {
        title: "개인정보처리방침",
      },
      {
        title: "제품 및 기술 문의 ",
      },
      {
        title: "제품자료 다운로드",
      },
    ],
    company: "쓰리에이로직스(주)",
    title_compnay_bussiness_number: "사업자등록번호",
    title_compnay_address: "주소",
    compnay_address:
      "경기도 성남시 수정구 금토로 40번길 26, 5층(금토동, 제2판교테크노밸리 세미플렉스타워 N동) (13453)",
    title_compnay_director: "대표",
    compnay_director: "이평한",
    title_compnay_number: "대표전화",
    title_compnay_email: "이메일",
    family_site: [
      {
        title: "FAMILY SITE",
      },
      {
        title: "NedgeS",
      },
    ],
  },
  en: {
    headers: [
      {
        title: "개인정보처리방침",
      },
      {
        title: "제품 및 기술 문의 ",
      },
      {
        title: "제품자료 다운로드",
      },
    ],
    company: "쓰리에이로직스(주)",
    title_compnay_bussiness_number: "사업자등록번호",
    title_compnay_address: "주소",
    compnay_address:
      "경기도 성남시 수정구 금토로 40번길 26, 5층(금토동, 제2판교테크노밸리 세미플렉스타워 N동) (13453)",
    title_compnay_director: "대표",
    compnay_director: "이평한",
    title_compnay_number: "대표전화",
    title_compnay_email: "이메일",
    family_site: [
      {
        title: "FAMILY SITE",
      },
      {
        title: "NedgeS",
      },
    ],
  },
};

const application = {
  kr: {
    section_one: {
      title: "3ALOGICS는 <br/> NFC 4대 핵심 분야에 <br/> 집중 투자합니다.",
      description:
        "3ALogics는 NFC 4대 핵심 분야에 집중 투자하여 세계적 수준의 NFC IC 국산화 및 핵심 IP를 확보하며, 경쟁력 있는 NFC 응용 IC 상용화를 통해 Global Star Fabless로 도약하고 있습니다.",
    },
    section_two: [
      [
        {
          category: "WIRELESS CHARGE",
          title: "무선충전",
          photo: "healthcare",
          description: [
            "소형 폼팩터 제품 및 장치에 케이블이 없는 전원의 편리성을 추가",
            "올인원 무선충전 및 데이터 통신 가능",
            "NFC 무선충전 표준화 및 Qi 무선 충전 플랫폼 보완 (소형 폼팩터, in-band 통신, NFC 기능 병행 사용 등)",
          ],
        },
        {
          category: "HEALTHCARE DEVICE",
          title: "CGMS",
          photo: "healthcare",
          description: [
            "NFC 헬스케어 디바이스 연결 후 정보 수집 및 데이터 디지털화",
            "CGMS는 연속혈당측정기로 Disposable 형태의 제품으로 공급",
            "NFC를 내장하여 시스템 Wakeup, 환자 인증, 혈당 Data 수집",
          ],
        },
      ],
      [
        {
          category: "ITEM LEVEL TAG",
          title: "차세대 스티커 태그",
          photo: "tag",
          description: [
            "UHF RFID는 Item level Tag로 바코드를 대체하는 기술로 자산 관리, 재고 관리, 유통 관리에 사용",
            "RFID로 유통/재고/생산/매장 관리",
            "BLE 통신으로 중간 관리자 별도의 AP 없이 Item 모니터링 가능",
            "BLE 통신으로 고객과의 무선 연결로 경험치 축적",
          ],
        },
        {
          category: "SMART IoT",
          title: "Consumer Device",
          photo: "iot",
          description: [
            "가정 및 직장에서 스마트 기기와 사물을 쉽게 설정하고 연결",
            "친숙한 NFC 탭 상호작용으로 소비자 경험 향상",
            "연결을 통해 에너지를 전송할 수 있으므로 연결될 장치에 전원이 필요 없음",
            "NFC와 연결하여 데이터를 읽고 씀과 동시에 전원 공급",
          ],
        },
      ],
    ],
  },
  en: {
    section_one: {
      title: "3ALOGICS는 <br/> NFC 4대 핵심 분야에 <br/> 집중 투자합니다.",
      description:
        "3ALogics는 NFC 4대 핵심 분야에 집중 투자하여 세계적 수준의 NFC IC 국산화 및 핵심 IP를 확보하며, 경쟁력 있는 NFC 응용 IC 상용화를 통해 Global Star Fabless로 도약하고 있습니다.",
    },
    section_two: [
      [
        {
          category: "WIRELESS CHARGE",
          title: "무선충전",
          photo: "healthcare",
          description: [
            "소형 폼팩터 제품 및 장치에 케이블이 없는 전원의 편리성을 추가",
            "올인원 무선충전 및 데이터 통신 가능",
            "NFC 무선충전 표준화 및 Qi 무선 충전 플랫폼 보완 (소형 폼팩터, in-band 통신, NFC 기능 병행 사용 등)",
          ],
        },
        {
          category: "HEALTHCARE DEVICE",
          title: "CGMS",
          photo: "healthcare",
          description: [
            "NFC 헬스케어 디바이스 연결 후 정보 수집 및 데이터 디지털화",
            "CGMS는 연속혈당측정기로 Disposable 형태의 제품으로 공급",
            "NFC를 내장하여 시스템 Wakeup, 환자 인증, 혈당 Data 수집",
          ],
        },
      ],
      [
        {
          category: "ITEM LEVEL TAG",
          title: "차세대 스티커 태그",
          photo: "tag",
          description: [
            "UHF RFID는 Item level Tag로 바코드를 대체하는 기술로 자산 관리, 재고 관리, 유통 관리에 사용",
            "RFID로 유통/재고/생산/매장 관리",
            "BLE 통신으로 중간 관리자 별도의 AP 없이 Item 모니터링 가능",
            "BLE 통신으로 고객과의 무선 연결로 경험치 축적",
          ],
        },
        {
          category: "SMART IoT",
          title: "Consumer Device",
          photo: "iot",
          description: [
            "가정 및 직장에서 스마트 기기와 사물을 쉽게 설정하고 연결",
            "친숙한 NFC 탭 상호작용으로 소비자 경험 향상",
            "연결을 통해 에너지를 전송할 수 있으므로 연결될 장치에 전원이 필요 없음",
            "NFC와 연결하여 데이터를 읽고 씀과 동시에 전원 공급",
          ],
        },
      ],
    ],
  },
};

const product = {
  kr: {
    section_one: {
      title:
        "기술 표준에 맞는 <br/> 혼합 작업이 가능한 차세대 <br/> Reader IC를 경험해보세요.",
      description:
        "하드웨어/소프트웨어 전원 관리 기능으로 전력 소비를 최소화하고, 두 개의 전송 드라이버를 구성한 3ALogics만의 차세대 Reader IC입니다.",
    },
    section_two: [
      {
        title: "automotive",
        photo: "car",
        part_one: "TNR100",
        part_two: "TNR200",
      },
      {
        title: "entrance control",
        photo: "car",
        part_one: "TNR300",
        part_two: "TNR400",
      },
    ],
    section_three: [
      {
        category: "Automotive",
        title: "TNR100",
        photo: chiptnr100,
        specifications: [
          {
            icon: category,
            title: "category",
            description: "NFC",
          },
          {
            icon: protocol,
            title: "protocol",
            description:
              "ISO/IEC 14443 A/B, <br/> 15693 Standards I-CODE, <br/> Tag-it, Felica, Jewel Protocols, <br/> ISO/IEC 18092",
          },
          {
            icon: interfaces,
            title: "interface",
            description: "SPI/I2C",
          },
          {
            icon: packages,
            title: "package",
            description: "24pin QFN <br/> (4.0mm x 4.0mm)",
          },
          {
            icon: memory,
            title: "memory",
            description: "36KB + 3.6Kbits",
          },
          {
            icon: supplyVioltage,
            title: "supply voltage",
            description:
              "AEC-Q100(Grade1), <br/> Certification Release 13 <br/> (CCC Digital Key Reader Device, <br/> NFC Reader Device)",
          },
        ],
      },
      {
        category: "Automotive",
        title: "TNR200",
        photo: chiptnr100,
        specifications: [
          {
            icon: category,
            title: "category",
            description: "NFC",
          },
          {
            icon: protocol,
            title: "protocol",
            description:
              "ISO/IEC 14443 A/B, <br/> 15693 Standards I-CODE, <br/> Tag-it, Felica, Jewel Protocols, <br/> ISO/IEC 18092",
          },
          {
            icon: interfaces,
            title: "interface",
            description: "SPI/I2C",
          },
          {
            icon: packages,
            title: "package",
            description: "24pin QFN <br/> (4.0mm x 4.0mm)",
          },
          {
            icon: memory,
            title: "memory",
            description: "36KB + 3.6Kbits",
          },
          {
            icon: supplyVioltage,
            title: "supply voltage",
            description:
              "AEC-Q100(Grade1), <br/> Certification Release 13 <br/> (CCC Digital Key Reader Device, <br/> NFC Reader Device)",
          },
        ],
      },
      {
        category: "Entrance Control",
        title: "TRH031M-S",
        photo: chiptnr100,
        specifications: [
          {
            icon: category,
            title: "category",
            description: "NFC",
          },
          {
            icon: protocol,
            title: "protocol",
            description:
              "ISO/IEC 14443 A/B, <br/> 15693 Standards I-CODE, <br/> Tag-it, Felica, Jewel Protocols, <br/> ISO/IEC 18092",
          },
          {
            icon: interfaces,
            title: "interface",
            description: "SPI/I2C",
          },
          {
            icon: packages,
            title: "package",
            description: "24pin QFN <br/> (4.0mm x 4.0mm)",
          },
          {
            icon: memory,
            title: "memory",
            description: "36KB + 3.6Kbits",
          },
          {
            icon: supplyVioltage,
            title: "supply voltage",
            description:
              "AEC-Q100(Grade1), <br/> Certification Release 13 <br/> (CCC Digital Key Reader Device, <br/> NFC Reader Device)",
          },
        ],
      },
      {
        category: "Entrance Control",
        title: "TRH033M-S",
        photo: chiptnr100,
        specifications: [
          {
            icon: category,
            title: "category",
            description: "NFC",
          },
          {
            icon: protocol,
            title: "protocol",
            description:
              "ISO/IEC 14443 A/B, <br/> 15693 Standards I-CODE, <br/> Tag-it, Felica, Jewel Protocols, <br/> ISO/IEC 18092",
          },
          {
            icon: interfaces,
            title: "interface",
            description: "SPI/I2C",
          },
          {
            icon: packages,
            title: "package",
            description: "24pin QFN <br/> (4.0mm x 4.0mm)",
          },
          {
            icon: memory,
            title: "memory",
            description: "36KB + 3.6Kbits",
          },
          {
            icon: supplyVioltage,
            title: "supply voltage",
            description:
              "AEC-Q100(Grade1), <br/> Certification Release 13 <br/> (CCC Digital Key Reader Device, <br/> NFC Reader Device)",
          },
        ],
      },
      {
        category: "Entrance Control",
        title: "TNB85",
        photo: chiptnr100,
        specifications: [
          {
            icon: category,
            title: "category",
            description: "NFC",
          },
          {
            icon: protocol,
            title: "protocol",
            description:
              "ISO/IEC 14443 A/B, <br/> 15693 Standards I-CODE, <br/> Tag-it, Felica, Jewel Protocols, <br/> ISO/IEC 18092",
          },
          {
            icon: interfaces,
            title: "interface",
            description: "SPI/I2C",
          },
          {
            icon: packages,
            title: "package",
            description: "24pin QFN <br/> (4.0mm x 4.0mm)",
          },
          {
            icon: memory,
            title: "memory",
            description: "36KB + 3.6Kbits",
          },
          {
            icon: supplyVioltage,
            title: "supply voltage",
            description:
              "AEC-Q100(Grade1), <br/> Certification Release 13 <br/> (CCC Digital Key Reader Device, <br/> NFC Reader Device)",
          },
        ],
      },
    ],
  },
  en: {
    section_one: {
      title:
        "기술 표준에 맞는 <br/> 혼합 작업이 가능한 차세대 <br/> Reader IC를 경험해보세요.",
      description:
        "하드웨어/소프트웨어 전원 관리 기능으로 전력 소비를 최소화하고, 두 개의 전송 드라이버를 구성한 3ALogics만의 차세대 Reader IC입니다.",
    },
    section_two: [
      {
        title: "automotive",
        photo: "car",
        part_one: "TNR100",
        part_two: "TNR200",
      },
      {
        title: "entrance control",
        photo: "car",
        part_one: "TNR300",
        part_two: "TNR400",
      },
    ],
    section_three: [
      {
        category: "Automotive",
        title: "TNR100",
        photo: chiptnr100,
        specifications: [
          {
            icon: category,
            title: "category",
            description: "NFC",
          },
          {
            icon: protocol,
            title: "protocol",
            description:
              "ISO/IEC 14443 A/B, <br/> 15693 Standards I-CODE, <br/> Tag-it, Felica, Jewel Protocols, <br/> ISO/IEC 18092",
          },
          {
            icon: interfaces,
            title: "interface",
            description: "SPI/I2C",
          },
          {
            icon: packages,
            title: "package",
            description: "24pin QFN <br/> (4.0mm x 4.0mm)",
          },
          {
            icon: memory,
            title: "memory",
            description: "36KB + 3.6Kbits",
          },
          {
            icon: supplyVioltage,
            title: "supply voltage",
            description:
              "AEC-Q100(Grade1), <br/> Certification Release 13 <br/> (CCC Digital Key Reader Device, <br/> NFC Reader Device)",
          },
        ],
      },
      {
        category: "Automotive",
        title: "TNR200",
        photo: chiptnr100,
        specifications: [
          {
            icon: category,
            title: "category",
            description: "NFC",
          },
          {
            icon: protocol,
            title: "protocol",
            description:
              "ISO/IEC 14443 A/B, <br/> 15693 Standards I-CODE, <br/> Tag-it, Felica, Jewel Protocols, <br/> ISO/IEC 18092",
          },
          {
            icon: interfaces,
            title: "interface",
            description: "SPI/I2C",
          },
          {
            icon: packages,
            title: "package",
            description: "24pin QFN <br/> (4.0mm x 4.0mm)",
          },
          {
            icon: memory,
            title: "memory",
            description: "36KB + 3.6Kbits",
          },
          {
            icon: supplyVioltage,
            title: "supply voltage",
            description:
              "AEC-Q100(Grade1), <br/> Certification Release 13 <br/> (CCC Digital Key Reader Device, <br/> NFC Reader Device)",
          },
        ],
      },
      {
        category: "Entrance Control",
        title: "TRH031M-S",
        photo: chiptnr100,
        specifications: [
          {
            icon: category,
            title: "category",
            description: "NFC",
          },
          {
            icon: protocol,
            title: "protocol",
            description:
              "ISO/IEC 14443 A/B, <br/> 15693 Standards I-CODE, <br/> Tag-it, Felica, Jewel Protocols, <br/> ISO/IEC 18092",
          },
          {
            icon: interfaces,
            title: "interface",
            description: "SPI/I2C",
          },
          {
            icon: packages,
            title: "package",
            description: "24pin QFN <br/> (4.0mm x 4.0mm)",
          },
          {
            icon: memory,
            title: "memory",
            description: "36KB + 3.6Kbits",
          },
          {
            icon: supplyVioltage,
            title: "supply voltage",
            description:
              "AEC-Q100(Grade1), <br/> Certification Release 13 <br/> (CCC Digital Key Reader Device, <br/> NFC Reader Device)",
          },
        ],
      },
      {
        category: "Entrance Control",
        title: "TRH033M-S",
        photo: chiptnr100,
        specifications: [
          {
            icon: category,
            title: "category",
            description: "NFC",
          },
          {
            icon: protocol,
            title: "protocol",
            description:
              "ISO/IEC 14443 A/B, <br/> 15693 Standards I-CODE, <br/> Tag-it, Felica, Jewel Protocols, <br/> ISO/IEC 18092",
          },
          {
            icon: interfaces,
            title: "interface",
            description: "SPI/I2C",
          },
          {
            icon: packages,
            title: "package",
            description: "24pin QFN <br/> (4.0mm x 4.0mm)",
          },
          {
            icon: memory,
            title: "memory",
            description: "36KB + 3.6Kbits",
          },
          {
            icon: supplyVioltage,
            title: "supply voltage",
            description:
              "AEC-Q100(Grade1), <br/> Certification Release 13 <br/> (CCC Digital Key Reader Device, <br/> NFC Reader Device)",
          },
        ],
      },
      {
        category: "Entrance Control",
        title: "TNB85",
        photo: chiptnr100,
        specifications: [
          {
            icon: category,
            title: "category",
            description: "NFC",
          },
          {
            icon: protocol,
            title: "protocol",
            description:
              "ISO/IEC 14443 A/B, <br/> 15693 Standards I-CODE, <br/> Tag-it, Felica, Jewel Protocols, <br/> ISO/IEC 18092",
          },
          {
            icon: interfaces,
            title: "interface",
            description: "SPI/I2C",
          },
          {
            icon: packages,
            title: "package",
            description: "24pin QFN <br/> (4.0mm x 4.0mm)",
          },
          {
            icon: memory,
            title: "memory",
            description: "36KB + 3.6Kbits",
          },
          {
            icon: supplyVioltage,
            title: "supply voltage",
            description:
              "AEC-Q100(Grade1), <br/> Certification Release 13 <br/> (CCC Digital Key Reader Device, <br/> NFC Reader Device)",
          },
        ],
      },
    ],
  },
};

const testDataDummy = [
  [1262304000000, 0.7537],
  [1262563200000, 0.6951],
  [1262649600000, 0.6925],
  [1262736000000, 0.697],
  [1262822400000, 0.6992],
  [1262908800000, 0.7007],
  [1263168000000, 0.6884],
  [1263254400000, 0.6907],
  [1263340800000, 0.6868],
  [1263427200000, 0.6904],
  [1263513600000, 0.6958],
  [1263772800000, 0.696],
  [1263859200000, 0.7004],
  [1263945600000, 0.7077],
  [1264032000000, 0.7111],
  [1264118400000, 0.7076],
  [1264377600000, 0.7068],
  [1264464000000, 0.7101],
  [1264550400000, 0.7107],
  [1264636800000, 0.7144],
  [1264723200000, 0.7161],
  [1264982400000, 0.7189],
  [1265068800000, 0.7176],
  [1265155200000, 0.7152],
  [1265241600000, 0.7223],
  [1265328000000, 0.7305],
  [1265587200000, 0.7314],
  [1265673600000, 0.7268],
  [1265760000000, 0.7279],
  [1265846400000, 0.7291],
  [1265932800000, 0.7369],
  [1266192000000, 0.735],
  [1266278400000, 0.7328],
  [1266364800000, 0.7286],
  [1266451200000, 0.7372],
  [1266537600000, 0.7398],
  [1266796800000, 0.734],
  [1266883200000, 0.7366],
  [1266969600000, 0.7383],
  [1267056000000, 0.7414],
  [1267142400000, 0.737],
  [1267401600000, 0.7395],
  [1267488000000, 0.7382],
  [1267574400000, 0.7332],
  [1267660800000, 0.7317],
  [1267747200000, 0.7364],
  [1268006400000, 0.7321],
  [1268092800000, 0.7377],
  [1268179200000, 0.7349],
  [1268265600000, 0.7323],
  [1268352000000, 0.7266],
  [1268611200000, 0.7298],
  [1268697600000, 0.7288],
  [1268784000000, 0.7271],
  [1268870400000, 0.7322],
  [1268956800000, 0.7382],
  [1269216000000, 0.7424],
  [1269302400000, 0.7398],
  [1269388800000, 0.7498],
  [1269475200000, 0.7488],
  [1269561600000, 0.749],
  [1269820800000, 0.7424],
  [1269907200000, 0.7418],
  [1269993600000, 0.742],
  [1270080000000, 0.7426],
  [1270166400000, 0.7426],
  [1270425600000, 0.7426],
  [1270512000000, 0.7466],
  [1270598400000, 0.7497],
  [1270684800000, 0.7522],
  [1270771200000, 0.7473],
  [1271030400000, 0.7362],
  [1271116800000, 0.7363],
  [1271203200000, 0.7346],
  [1271289600000, 0.7384],
  [1271376000000, 0.7389],
  [1271635200000, 0.7446],
  [1271721600000, 0.7416],
  [1271808000000, 0.7479],
  [1271894400000, 0.7498],
  [1271980800000, 0.7514],
  [1272240000000, 0.7508],
  [1272326400000, 0.7525],
  [1272412800000, 0.7551],
  [1272499200000, 0.7545],
  [1272585600000, 0.7511],
  [1272844800000, 0.7555],
  [1272931200000, 0.7641],
  [1273017600000, 0.7739],
  [1273104000000, 0.7858],
  [1273190400000, 0.7847],
  [1273449600000, 0.7712],
  [1273536000000, 0.7876],
  [1273622400000, 0.7884],
  [1273708800000, 0.7946],
  [1273795200000, 0.8006],
  [1274054400000, 0.8099],
  [1274140800000, 0.8047],
  [1274227200000, 0.8151],
  [1274313600000, 0.8109],
  [1274400000000, 0.8003],
  [1274659200000, 0.8092],
  [1274745600000, 0.8182],
  [1274832000000, 0.8125],
  [1274918400000, 0.8161],
  [1275004800000, 0.8076],
  [1275264000000, 0.8126],
  [1275350400000, 0.8228],
  [1275436800000, 0.8186],
  [1275523200000, 0.8152],
  [1275609600000, 0.8293],
  [1275868800000, 0.8363],
  [1275955200000, 0.8375],
  [1276041600000, 0.8327],
  [1276128000000, 0.8303],
  [1276214400000, 0.8247],
  [1276473600000, 0.8165],
  [1276560000000, 0.8159],
  [1276646400000, 0.8146],
  [1276732800000, 0.809],
  [1276819200000, 0.8084],
  [1277078400000, 0.8071],
  [1277164800000, 0.8159],
  [1277251200000, 0.815],
  [1277337600000, 0.8156],
  [1277424000000, 0.8135],
  [1277683200000, 0.8105],
  [1277769600000, 0.8199],
  [1277856000000, 0.815],
  [1277942400000, 0.8113],
  [1278028800000, 0.797],
  [1278288000000, 0.7981],
  [1278374400000, 0.7951],
  [1278460800000, 0.7958],
  [1278547200000, 0.79],
  [1278633600000, 0.7914],
  [1278892800000, 0.7955],
  [1278979200000, 0.7957],
  [1279065600000, 0.7873],
  [1279152000000, 0.7796],
  [1279238400000, 0.7693],
  [1279497600000, 0.7719],
  [1279584000000, 0.7787],
  [1279670400000, 0.7803],
  [1279756800000, 0.7783],
  [1279843200000, 0.7755],
  [1280102400000, 0.7734],
  [1280188800000, 0.7674],
  [1280275200000, 0.7698],
  [1280361600000, 0.7653],
  [1280448000000, 0.7677],
  [1280707200000, 0.765],
  [1280793600000, 0.7565],
  [1280880000000, 0.7573],
  [1280966400000, 0.7586],
  [1281052800000, 0.7591],
  [1281312000000, 0.7546],
  [1281398400000, 0.7615],
  [1281484800000, 0.7684],
  [1281571200000, 0.782],
  [1281657600000, 0.7814],
  [1281916800000, 0.7801],
  [1282003200000, 0.7777],
  [1282089600000, 0.7765],
  [1282176000000, 0.7792],
  [1282262400000, 0.7868],
  [1282521600000, 0.7873],
  [1282608000000, 0.7931],
  [1282694400000, 0.7929],
  [1282780800000, 0.7879],
  [1282867200000, 0.7867],
  [1283126400000, 0.7875],
  [1283212800000, 0.7887],
  [1283299200000, 0.7814],
  [1283385600000, 0.7803],
  [1283472000000, 0.7793],
  [1283731200000, 0.7769],
  [1283817600000, 0.7848],
  [1283904000000, 0.7877],
  [1283990400000, 0.7866],
  [1284076800000, 0.786],
  [1284336000000, 0.7813],
  [1284422400000, 0.7783],
  [1284508800000, 0.77],
  [1284595200000, 0.7647],
  [1284681600000, 0.7658],
  [1284940800000, 0.765],
  [1285027200000, 0.7623],
  [1285113600000, 0.7484],
  [1285200000000, 0.7507],
  [1285286400000, 0.7457],
  [1285545600000, 0.7421],
  [1285632000000, 0.743],
  [1285718400000, 0.7348],
  [1285804800000, 0.7328],
  [1285891200000, 0.7286],
  [1286150400000, 0.7298],
  [1286236800000, 0.7258],
  [1286323200000, 0.7218],
  [1286409600000, 0.7159],
  [1286496000000, 0.7209],
  [1286755200000, 0.7177],
  [1286841600000, 0.723],
  [1286928000000, 0.7165],
  [1287014400000, 0.7093],
  [1287100800000, 0.7099],
  [1287360000000, 0.7197],
  [1287446400000, 0.7217],
  [1287532800000, 0.7215],
  [1287619200000, 0.7136],
  [1287705600000, 0.7178],
  [1287964800000, 0.7128],
  [1288051200000, 0.7189],
  [1288137600000, 0.7246],
  [1288224000000, 0.7218],
  [1288310400000, 0.7218],
  [1288569600000, 0.7182],
  [1288656000000, 0.7135],
  [1288742400000, 0.7137],
  [1288828800000, 0.7021],
  [1288915200000, 0.7101],
  [1289174400000, 0.7186],
  [1289260800000, 0.7172],
  [1289347200000, 0.7263],
  [1289433600000, 0.73],
  [1289520000000, 0.7294],
  [1289779200000, 0.734],
  [1289865600000, 0.7347],
  [1289952000000, 0.7419],
  [1290038400000, 0.7329],
  [1290124800000, 0.7314],
  [1290384000000, 0.7329],
  [1290470400000, 0.7411],
  [1290556800000, 0.7498],
  [1290643200000, 0.7508],
  [1290729600000, 0.7562],
  [1290988800000, 0.7608],
  [1291075200000, 0.7694],
  [1291161600000, 0.7626],
  [1291248000000, 0.7603],
  [1291334400000, 0.755],
  [1291593600000, 0.7531],
  [1291680000000, 0.7484],
  [1291766400000, 0.7577],
  [1291852800000, 0.7569],
  [1291939200000, 0.7552],
  [1292198400000, 0.7538],
  [1292284800000, 0.7444],
  [1292371200000, 0.7486],
  [1292457600000, 0.7555],
  [1292544000000, 0.7542],
  [1292803200000, 0.7607],
  [1292889600000, 0.7603],
  [1292976000000, 0.7628],
  [1293062400000, 0.7656],
  [1293148800000, 0.7635],
  [1293408000000, 0.7614],
  [1293494400000, 0.758],
  [1293580800000, 0.7614],
  [1293667200000, 0.7531],
  [1293753600000, 0.7485],
  [1294012800000, 0.7493],
  [1294099200000, 0.7452],
  [1294185600000, 0.7569],
  [1294272000000, 0.764],
  [1294358400000, 0.7716],
  [1294617600000, 0.7751],
  [1294704000000, 0.7724],
  [1294790400000, 0.7709],
  [1294876800000, 0.7577],
  [1294963200000, 0.7492],
  [1295222400000, 0.7514],
  [1295308800000, 0.748],
  [1295395200000, 0.7405],
  [1295481600000, 0.7424],
  [1295568000000, 0.7397],
  [1295827200000, 0.737],
  [1295913600000, 0.7356],
  [1296000000000, 0.731],
  [1296086400000, 0.7292],
  [1296172800000, 0.7295],
  [1296432000000, 0.7305],
  [1296518400000, 0.7271],
  [1296604800000, 0.7246],
  [1296691200000, 0.7276],
  [1296777600000, 0.7337],
  [1297036800000, 0.7379],
  [1297123200000, 0.7335],
  [1297209600000, 0.7329],
  [1297296000000, 0.7352],
  [1297382400000, 0.7395],
  [1297641600000, 0.7441],
  [1297728000000, 0.7403],
  [1297814400000, 0.7403],
  [1297900800000, 0.7376],
  [1297987200000, 0.7339],
  [1298246400000, 0.7317],
  [1298332800000, 0.7318],
  [1298419200000, 0.7284],
  [1298505600000, 0.7262],
  [1298592000000, 0.7267],
  [1298851200000, 0.723],
  [1298937600000, 0.7234],
  [1299024000000, 0.7243],
  [1299110400000, 0.7221],
  [1299196800000, 0.7166],
  [1299456000000, 0.713],
  [1299542400000, 0.7196],
  [1299628800000, 0.7181],
  [1299715200000, 0.7238],
  [1299801600000, 0.7262],
  [1300060800000, 0.717],
  [1300147200000, 0.7204],
  [1300233600000, 0.7169],
  [1300320000000, 0.7142],
  [1300406400000, 0.7078],
  [1300665600000, 0.7046],
  [1300752000000, 0.7038],
  [1300838400000, 0.7075],
  [1300924800000, 0.7079],
  [1301011200000, 0.7086],
  [1301270400000, 0.7128],
  [1301356800000, 0.711],
  [1301443200000, 0.7098],
  [1301529600000, 0.704],
  [1301616000000, 0.7073],
  [1301875200000, 0.7023],
  [1301961600000, 0.706],
  [1302048000000, 0.6994],
  [1302134400000, 0.7002],
  [1302220800000, 0.6945],
  [1302480000000, 0.6929],
  [1302566400000, 0.6912],
  [1302652800000, 0.6901],
  [1302739200000, 0.6945],
  [1302825600000, 0.6921],
  [1303084800000, 0.7006],
  [1303171200000, 0.6993],
  [1303257600000, 0.689],
  [1303344000000, 0.6858],
  [1303430400000, 0.6858],
  [1303689600000, 0.6858],
  [1303776000000, 0.6842],
  [1303862400000, 0.6819],
  [1303948800000, 0.676],
  [1304035200000, 0.673],
  [1304294400000, 0.6741],
  [1304380800000, 0.6767],
  [1304467200000, 0.6721],
  [1304553600000, 0.6751],
  [1304640000000, 0.6897],
  [1304899200000, 0.6947],
  [1304985600000, 0.6966],
  [1305072000000, 0.6966],
  [1305158400000, 0.7067],
  [1305244800000, 0.7004],
  [1305504000000, 0.7072],
  [1305590400000, 0.7058],
  [1305676800000, 0.703],
  [1305763200000, 0.7011],
  [1305849600000, 0.7025],
  [1306108800000, 0.7134],
  [1306195200000, 0.7099],
  [1306281600000, 0.7109],
  [1306368000000, 0.7059],
  [1306454400000, 0.7011],
  [1306713600000, 0.7008],
  [1306800000000, 0.6953],
  [1306886400000, 0.6942],
  [1306972800000, 0.6917],
  [1307059200000, 0.6903],
  [1307318400000, 0.6852],
  [1307404800000, 0.6826],
  [1307491200000, 0.6847],
  [1307577600000, 0.6844],
  [1307664000000, 0.6904],
  [1307923200000, 0.6968],
  [1308009600000, 0.6922],
  [1308096000000, 0.6998],
  [1308182400000, 0.7099],
  [1308268800000, 0.7009],
  [1308528000000, 0.7026],
  [1308614400000, 0.6958],
  [1308700800000, 0.6947],
  [1308787200000, 0.7037],
  [1308873600000, 0.7033],
  [1309132800000, 0.7041],
  [1309219200000, 0.7013],
  [1309305600000, 0.6933],
  [1309392000000, 0.692],
  [1309478400000, 0.6903],
  [1309737600000, 0.6898],
  [1309824000000, 0.6916],
  [1309910400000, 0.6985],
  [1309996800000, 0.702],
  [1310083200000, 0.7022],
  [1310342400000, 0.7115],
  [1310428800000, 0.7157],
  [1310515200000, 0.7107],
  [1310601600000, 0.7042],
  [1310688000000, 0.707],
  [1310947200000, 0.7121],
  [1311033600000, 0.7063],
  [1311120000000, 0.704],
  [1311206400000, 0.7032],
  [1311292800000, 0.695],
  [1311552000000, 0.6955],
  [1311638400000, 0.6911],
  [1311724800000, 0.6923],
  [1311811200000, 0.7014],
  [1311897600000, 0.7014],
  [1312156800000, 0.6938],
  [1312243200000, 0.7058],
  [1312329600000, 0.6994],
  [1312416000000, 0.7029],
  [1312502400000, 0.7066],
  [1312761600000, 0.7031],
  [1312848000000, 0.701],
  [1312934400000, 0.6961],
  [1313020800000, 0.7072],
  [1313107200000, 0.7019],
  [1313366400000, 0.699],
  [1313452800000, 0.6965],
  [1313539200000, 0.6909],
  [1313625600000, 0.696],
  [1313712000000, 0.6953],
  [1313971200000, 0.6939],
  [1314057600000, 0.6916],
  [1314144000000, 0.693],
  [1314230400000, 0.6934],
  [1314316800000, 0.6944],
  [1314576000000, 0.6904],
  [1314662400000, 0.6944],
  [1314748800000, 0.6921],
  [1314835200000, 0.7001],
  [1314921600000, 0.7016],
  [1315180800000, 0.708],
  [1315267200000, 0.7094],
  [1315353600000, 0.7126],
  [1315440000000, 0.7121],
  [1315526400000, 0.7238],
  [1315785600000, 0.7324],
  [1315872000000, 0.733],
  [1315958400000, 0.7285],
  [1316044800000, 0.725],
  [1316131200000, 0.7268],
  [1316390400000, 0.7332],
  [1316476800000, 0.7295],
  [1316563200000, 0.7335],
  [1316649600000, 0.7437],
  [1316736000000, 0.7447],
  [1316995200000, 0.7408],
  [1317081600000, 0.7365],
  [1317168000000, 0.7337],
  [1317254400000, 0.7346],
  [1317340800000, 0.7407],
  [1317600000000, 0.7505],
  [1317686400000, 0.7588],
  [1317772800000, 0.7499],
  [1317859200000, 0.7537],
  [1317945600000, 0.7445],
  [1318204800000, 0.7358],
  [1318291200000, 0.735],
  [1318377600000, 0.7265],
  [1318464000000, 0.7286],
  [1318550400000, 0.7244],
  [1318809600000, 0.726],
  [1318896000000, 0.7313],
  [1318982400000, 0.7233],
  [1319068800000, 0.7244],
  [1319155200000, 0.7248],
  [1319414400000, 0.7218],
  [1319500800000, 0.7186],
  [1319587200000, 0.7181],
  [1319673600000, 0.7125],
  [1319760000000, 0.7063],
  [1320019200000, 0.7143],
  [1320105600000, 0.7339],
  [1320192000000, 0.7243],
  [1320278400000, 0.7262],
  [1320364800000, 0.7262],
  [1320624000000, 0.7278],
  [1320710400000, 0.7254],
  [1320796800000, 0.7336],
  [1320883200000, 0.7345],
  [1320969600000, 0.7327],
  [1321228800000, 0.7322],
  [1321315200000, 0.7391],
  [1321401600000, 0.7417],
  [1321488000000, 0.7419],
  [1321574400000, 0.7367],
  [1321833600000, 0.7432],
  [1321920000000, 0.7389],
  [1322006400000, 0.7471],
  [1322092800000, 0.7479],
  [1322179200000, 0.756],
  [1322438400000, 0.7493],
  [1322524800000, 0.75],
  [1322611200000, 0.7454],
  [1322697600000, 0.7413],
  [1322784000000, 0.7402],
  [1323043200000, 0.744],
  [1323129600000, 0.7467],
  [1323216000000, 0.7477],
  [1323302400000, 0.7458],
  [1323388800000, 0.7473],
  [1323648000000, 0.7548],
  [1323734400000, 0.7588],
  [1323820800000, 0.7697],
  [1323907200000, 0.7682],
  [1323993600000, 0.7656],
  [1324252800000, 0.767],
  [1324339200000, 0.765],
  [1324425600000, 0.7661],
  [1324512000000, 0.7666],
  [1324598400000, 0.766],
  [1324857600000, 0.766],
  [1324944000000, 0.7653],
  [1325030400000, 0.765],
  [1325116800000, 0.776],
  [1325203200000, 0.773],
  [1325462400000, 0.7732],
  [1325548800000, 0.7685],
  [1325635200000, 0.7724],
  [1325721600000, 0.7794],
  [1325808000000, 0.7828],
  [1326067200000, 0.7858],
  [1326153600000, 0.7809],
  [1326240000000, 0.7864],
  [1326326400000, 0.7853],
  [1326412800000, 0.7831],
  [1326672000000, 0.7894],
  [1326758400000, 0.782],
  [1326844800000, 0.7795],
  [1326931200000, 0.7746],
  [1327017600000, 0.7752],
  [1327276800000, 0.7683],
  [1327363200000, 0.7692],
  [1327449600000, 0.7728],
  [1327536000000, 0.7608],
  [1327622400000, 0.7608],
  [1327881600000, 0.7629],
  [1327968000000, 0.7591],
  [1328054400000, 0.7591],
  [1328140800000, 0.7638],
  [1328227200000, 0.76],
  [1328486400000, 0.7669],
  [1328572800000, 0.7627],
  [1328659200000, 0.7535],
  [1328745600000, 0.7527],
  [1328832000000, 0.7583],
  [1329091200000, 0.7546],
  [1329177600000, 0.7595],
  [1329264000000, 0.7639],
  [1329350400000, 0.7704],
  [1329436800000, 0.76],
  [1329696000000, 0.7539],
  [1329782400000, 0.7564],
  [1329868800000, 0.756],
  [1329955200000, 0.752],
  [1330041600000, 0.7457],
  [1330300800000, 0.747],
  [1330387200000, 0.7434],
  [1330473600000, 0.744],
  [1330560000000, 0.7513],
  [1330646400000, 0.7567],
  [1330905600000, 0.7565],
  [1330992000000, 0.7604],
  [1331078400000, 0.7623],
  [1331164800000, 0.7553],
  [1331251200000, 0.7582],
  [1331510400000, 0.7624],
  [1331596800000, 0.766],
  [1331683200000, 0.7657],
  [1331769600000, 0.766],
  [1331856000000, 0.7625],
  [1332115200000, 0.7606],
  [1332201600000, 0.7578],
  [1332288000000, 0.7562],
  [1332374400000, 0.7596],
  [1332460800000, 0.7553],
  [1332720000000, 0.7533],
  [1332806400000, 0.7501],
  [1332892800000, 0.7499],
  [1332979200000, 0.7536],
  [1333065600000, 0.7488],
  [1333324800000, 0.7509],
  [1333411200000, 0.7511],
  [1333497600000, 0.761],
  [1333584000000, 0.7653],
  [1333670400000, 0.7653],
  [1333929600000, 0.7653],
  [1334016000000, 0.7626],
  [1334102400000, 0.7617],
  [1334188800000, 0.7604],
  [1334275200000, 0.7607],
  [1334534400000, 0.7679],
  [1334620800000, 0.7616],
  [1334707200000, 0.7639],
  [1334793600000, 0.7643],
  [1334880000000, 0.7581],
  [1335139200000, 0.7617],
  [1335225600000, 0.7599],
  [1335312000000, 0.7573],
  [1335398400000, 0.7568],
  [1335484800000, 0.756],
  [1335744000000, 0.7569],
  [1335830400000, 0.7569],
  [1335916800000, 0.7617],
  [1336003200000, 0.7621],
  [1336089600000, 0.7616],
  [1336348800000, 0.7674],
  [1336435200000, 0.7679],
  [1336521600000, 0.7723],
  [1336608000000, 0.7716],
  [1336694400000, 0.7727],
  [1336953600000, 0.7775],
  [1337040000000, 0.7787],
  [1337126400000, 0.7852],
  [1337212800000, 0.7886],
  [1337299200000, 0.7862],
  [1337558400000, 0.7844],
  [1337644800000, 0.7833],
  [1337731200000, 0.7901],
  [1337817600000, 0.7965],
  [1337904000000, 0.7972],
  [1338163200000, 0.7959],
  [1338249600000, 0.7986],
  [1338336000000, 0.8041],
  [1338422400000, 0.8064],
  [1338508800000, 0.8117],
  [1338768000000, 0.8042],
  [1338854400000, 0.8047],
  [1338940800000, 0.8011],
  [1339027200000, 0.7941],
  [1339113600000, 0.8022],
  [1339372800000, 0.7973],
  [1339459200000, 0.8006],
  [1339545600000, 0.7979],
  [1339632000000, 0.7968],
  [1339718400000, 0.794],
  [1339977600000, 0.7926],
  [1340064000000, 0.7926],
  [1340150400000, 0.7873],
  [1340236800000, 0.7894],
  [1340323200000, 0.7976],
  [1340582400000, 0.8009],
  [1340668800000, 0.8017],
  [1340755200000, 0.8015],
  [1340841600000, 0.8054],
  [1340928000000, 0.7944],
  [1341187200000, 0.7942],
  [1341273600000, 0.7953],
  [1341360000000, 0.7963],
  [1341446400000, 0.8049],
  [1341532800000, 0.8081],
  [1341792000000, 0.8136],
  [1341878400000, 0.8141],
  [1341964800000, 0.8158],
  [1342051200000, 0.8213],
  [1342137600000, 0.8208],
  [1342396800000, 0.8213],
  [1342483200000, 0.8144],
  [1342569600000, 0.8175],
  [1342656000000, 0.814],
  [1342742400000, 0.8198],
  [1343001600000, 0.8262],
  [1343088000000, 0.8273],
  [1343174400000, 0.8242],
  [1343260800000, 0.8158],
  [1343347200000, 0.812],
  [1343606400000, 0.8167],
  [1343692800000, 0.8142],
  [1343779200000, 0.8132],
  [1343865600000, 0.8101],
  [1343952000000, 0.8168],
  [1344211200000, 0.8079],
  [1344297600000, 0.8042],
  [1344384000000, 0.8107],
  [1344470400000, 0.813],
  [1344556800000, 0.8156],
  [1344816000000, 0.8105],
  [1344902400000, 0.8097],
  [1344988800000, 0.8147],
  [1345075200000, 0.8145],
  [1345161600000, 0.8107],
  [1345420800000, 0.8131],
  [1345507200000, 0.8047],
  [1345593600000, 0.8034],
  [1345680000000, 0.7968],
  [1345766400000, 0.7997],
  [1346025600000, 0.7982],
  [1346112000000, 0.797],
  [1346198400000, 0.7972],
  [1346284800000, 0.7973],
  [1346371200000, 0.7931],
  [1346630400000, 0.7958],
  [1346716800000, 0.7951],
  [1346803200000, 0.7951],
  [1346889600000, 0.7914],
  [1346976000000, 0.7871],
  [1347235200000, 0.7828],
  [1347321600000, 0.7821],
  [1347408000000, 0.7755],
  [1347494400000, 0.7747],
  [1347580800000, 0.7638],
  [1347840000000, 0.7643],
  [1347926400000, 0.7661],
  [1348012800000, 0.7692],
  [1348099200000, 0.7721],
  [1348185600000, 0.77],
  [1348444800000, 0.7743],
  [1348531200000, 0.7734],
  [1348617600000, 0.7786],
  [1348704000000, 0.7769],
  [1348790400000, 0.7735],
  [1349049600000, 0.7767],
  [1349136000000, 0.7735],
  [1349222400000, 0.7751],
  [1349308800000, 0.7722],
  [1349395200000, 0.7692],
  [1349654400000, 0.7718],
  [1349740800000, 0.7721],
  [1349827200000, 0.776],
  [1349913600000, 0.7742],
  [1350000000000, 0.7711],
  [1350259200000, 0.7711],
  [1350345600000, 0.7666],
  [1350432000000, 0.7623],
  [1350518400000, 0.7624],
  [1350604800000, 0.7673],
  [1350864000000, 0.7656],
  [1350950400000, 0.769],
  [1351036800000, 0.7728],
  [1351123200000, 0.7697],
  [1351209600000, 0.7748],
  [1351468800000, 0.7754],
  [1351555200000, 0.7716],
  [1351641600000, 0.7697],
  [1351728000000, 0.7708],
  [1351814400000, 0.7783],
  [1352073600000, 0.7828],
  [1352160000000, 0.7814],
  [1352246400000, 0.7847],
  [1352332800000, 0.7853],
  [1352419200000, 0.7879],
  [1352678400000, 0.7853],
  [1352764800000, 0.7877],
  [1352851200000, 0.7859],
  [1352937600000, 0.784],
  [1353024000000, 0.7847],
  [1353283200000, 0.7837],
  [1353369600000, 0.7808],
  [1353456000000, 0.781],
  [1353542400000, 0.7757],
  [1353628800000, 0.7748],
  [1353888000000, 0.7715],
  [1353974400000, 0.7716],
  [1354060800000, 0.7758],
  [1354147200000, 0.7697],
  [1354233600000, 0.7702],
  [1354492800000, 0.766],
  [1354579200000, 0.7639],
  [1354665600000, 0.7655],
  [1354752000000, 0.7651],
  [1354838400000, 0.775],
  [1355097600000, 0.7735],
  [1355184000000, 0.7697],
  [1355270400000, 0.767],
  [1355356800000, 0.7648],
  [1355443200000, 0.7646],
  [1355702400000, 0.76],
  [1355788800000, 0.7589],
  [1355875200000, 0.7519],
  [1355961600000, 0.755],
  [1356048000000, 0.7572],
  [1356307200000, 0.7566],
  [1356566400000, 0.7539],
  [1356652800000, 0.7587],
  [1356912000000, 0.758],
  [1357084800000, 0.7541],
  [1357171200000, 0.7633],
  [1357257600000, 0.7686],
  [1357516800000, 0.767],
  [1357603200000, 0.7643],
  [1357689600000, 0.766],
  [1357776000000, 0.7627],
  [1357862400000, 0.7535],
  [1358121600000, 0.7497],
  [1358208000000, 0.7505],
  [1358294400000, 0.7533],
  [1358380800000, 0.7482],
  [1358467200000, 0.7506],
  [1358726400000, 0.7507],
  [1358812800000, 0.751],
  [1358899200000, 0.7503],
  [1358985600000, 0.7492],
  [1359072000000, 0.7425],
  [1359331200000, 0.7439],
  [1359417600000, 0.7445],
  [1359504000000, 0.7386],
  [1359590400000, 0.7381],
  [1359676800000, 0.733],
  [1359936000000, 0.738],
  [1360022400000, 0.7388],
  [1360108800000, 0.7399],
  [1360195200000, 0.7383],
  [1360281600000, 0.7478],
  [1360540800000, 0.7469],
  [1360627200000, 0.7443],
  [1360713600000, 0.7419],
  [1360800000000, 0.7505],
  [1360886400000, 0.7506],
  [1361145600000, 0.7491],
  [1361232000000, 0.7492],
  [1361318400000, 0.748],
  [1361404800000, 0.7585],
  [1361491200000, 0.7585],
  [1361750400000, 0.7518],
  [1361836800000, 0.7648],
  [1361923200000, 0.7636],
  [1362009600000, 0.7618],
  [1362096000000, 0.7693],
  [1362355200000, 0.7689],
  [1362441600000, 0.7673],
  [1362528000000, 0.7673],
  [1362614400000, 0.7687],
  [1362700800000, 0.764],
  [1362960000000, 0.7697],
  [1363046400000, 0.7662],
  [1363132800000, 0.7705],
  [1363219200000, 0.7731],
  [1363305600000, 0.7643],
  [1363564800000, 0.7736],
  [1363651200000, 0.7727],
  [1363737600000, 0.7726],
  [1363824000000, 0.7747],
  [1363910400000, 0.7724],
  [1364169600000, 0.7732],
  [1364256000000, 0.7776],
  [1364342400000, 0.7833],
  [1364428800000, 0.781],
  [1364860800000, 0.7789],
  [1364947200000, 0.7796],
  [1365033600000, 0.7803],
  [1365120000000, 0.7727],
  [1365379200000, 0.768],
  [1365465600000, 0.767],
  [1365552000000, 0.7643],
  [1365638400000, 0.7624],
  [1365724800000, 0.7663],
  [1365984000000, 0.7646],
  [1366070400000, 0.7618],
  [1366156800000, 0.7618],
  [1366243200000, 0.7667],
  [1366329600000, 0.7626],
  [1366588800000, 0.7671],
  [1366675200000, 0.7699],
  [1366761600000, 0.769],
  [1366848000000, 0.7646],
  [1366934400000, 0.7694],
  [1367193600000, 0.7627],
  [1367280000000, 0.7651],
  [1367452800000, 0.7582],
  [1367539200000, 0.7626],
  [1367798400000, 0.7631],
  [1367884800000, 0.7631],
  [1367971200000, 0.7614],
  [1368057600000, 0.761],
  [1368144000000, 0.77],
  [1368403200000, 0.7709],
  [1368489600000, 0.7707],
  [1368576000000, 0.7775],
  [1368662400000, 0.7759],
  [1368748800000, 0.7772],
  [1369008000000, 0.7781],
  [1369094400000, 0.7773],
  [1369180800000, 0.7739],
  [1369267200000, 0.776],
  [1369353600000, 0.773],
  [1369612800000, 0.773],
  [1369699200000, 0.773],
  [1369785600000, 0.7722],
  [1369872000000, 0.7727],
  [1369958400000, 0.769],
  [1370217600000, 0.7689],
  [1370304000000, 0.7639],
  [1370390400000, 0.7654],
  [1370476800000, 0.7624],
  [1370563200000, 0.7542],
  [1370822400000, 0.7572],
  [1370908800000, 0.7535],
  [1370995200000, 0.7533],
  [1371081600000, 0.7511],
  [1371168000000, 0.7518],
  [1371427200000, 0.7499],
  [1371513600000, 0.7478],
  [1371600000000, 0.746],
  [1371686400000, 0.7577],
  [1371772800000, 0.7588],
  [1372032000000, 0.7643],
  [1372118400000, 0.7615],
  [1372204800000, 0.7679],
  [1372291200000, 0.7674],
  [1372377600000, 0.7646],
  [1372636800000, 0.7671],
  [1372723200000, 0.7683],
  [1372809600000, 0.7718],
  [1372896000000, 0.7703],
  [1372982400000, 0.7763],
  [1373241600000, 0.7783],
  [1373328000000, 0.7779],
  [1373414400000, 0.7806],
  [1373500800000, 0.7667],
  [1373587200000, 0.7673],
  [1373846400000, 0.7686],
  [1373932800000, 0.7624],
  [1374019200000, 0.7614],
  [1374105600000, 0.7639],
  [1374192000000, 0.7621],
  [1374451200000, 0.7596],
  [1374537600000, 0.7588],
  [1374624000000, 0.755],
  [1374710400000, 0.7576],
  [1374796800000, 0.7542],
  [1375056000000, 0.7537],
  [1375142400000, 0.7529],
  [1375228800000, 0.7534],
  [1375315200000, 0.7556],
  [1375401600000, 0.7575],
  [1375660800000, 0.7544],
  [1375747200000, 0.7531],
  [1375833600000, 0.7517],
  [1375920000000, 0.7486],
  [1376006400000, 0.7479],
  [1376265600000, 0.7531],
  [1376352000000, 0.7525],
  [1376438400000, 0.7552],
  [1376524800000, 0.7521],
  [1376611200000, 0.7497],
  [1376870400000, 0.7495],
  [1376956800000, 0.7468],
  [1377043200000, 0.7473],
  [1377129600000, 0.7507],
  [1377216000000, 0.7489],
  [1377475200000, 0.7485],
  [1377561600000, 0.7498],
  [1377648000000, 0.7493],
  [1377734400000, 0.7539],
  [1377820800000, 0.7557],
  [1378080000000, 0.7573],
  [1378166400000, 0.7593],
  [1378252800000, 0.7593],
  [1378339200000, 0.7576],
  [1378425600000, 0.7625],
  [1378684800000, 0.758],
  [1378771200000, 0.7554],
  [1378857600000, 0.7538],
  [1378944000000, 0.7525],
  [1379030400000, 0.7523],
  [1379289600000, 0.7488],
  [1379376000000, 0.7488],
  [1379462400000, 0.7491],
  [1379548800000, 0.7384],
  [1379635200000, 0.7401],
  [1379894400000, 0.7404],
  [1379980800000, 0.7423],
  [1380067200000, 0.7406],
  [1380153600000, 0.7409],
  [1380240000000, 0.7388],
  [1380499200000, 0.7406],
  [1380585600000, 0.7379],
  [1380672000000, 0.74],
  [1380758400000, 0.7357],
  [1380844800000, 0.7358],
  [1381104000000, 0.7369],
  [1381190400000, 0.7367],
  [1381276800000, 0.74],
  [1381363200000, 0.7391],
  [1381449600000, 0.7372],
  [1381708800000, 0.7373],
  [1381795200000, 0.7412],
  [1381881600000, 0.7375],
  [1381968000000, 0.7321],
  [1382054400000, 0.7309],
  [1382313600000, 0.7318],
  [1382400000000, 0.7314],
  [1382486400000, 0.7273],
  [1382572800000, 0.7245],
  [1382659200000, 0.7259],
  [1382918400000, 0.7256],
  [1383004800000, 0.7264],
  [1383091200000, 0.7271],
  [1383177600000, 0.7332],
  [1383264000000, 0.7406],
  [1383523200000, 0.7405],
  [1383609600000, 0.7412],
  [1383696000000, 0.7399],
  [1383782400000, 0.7483],
  [1383868800000, 0.7446],
  [1384128000000, 0.7467],
  [1384214400000, 0.7446],
  [1384300800000, 0.7455],
  [1384387200000, 0.7444],
  [1384473600000, 0.743],
  [1384732800000, 0.7399],
  [1384819200000, 0.7407],
  [1384905600000, 0.7394],
  [1384992000000, 0.7424],
  [1385078400000, 0.7399],
  [1385337600000, 0.7401],
  [1385424000000, 0.7383],
  [1385510400000, 0.7356],
  [1385596800000, 0.7358],
  [1385683200000, 0.7348],
  [1385942400000, 0.7389],
  [1386028800000, 0.7366],
  [1386115200000, 0.7358],
  [1386201600000, 0.7357],
  [1386288000000, 0.7321],
  [1386547200000, 0.7289],
  [1386633600000, 0.7274],
  [1386720000000, 0.7265],
  [1386806400000, 0.7261],
  [1386892800000, 0.7286],
  [1387152000000, 0.726],
  [1387238400000, 0.7274],
  [1387324800000, 0.7274],
  [1387411200000, 0.7318],
  [1387497600000, 0.7324],
  [1387756800000, 0.7299],
  [1387843200000, 0.7309],
  [1388102400000, 0.724],
  [1388361600000, 0.7256],
  [1388448000000, 0.7252],
  [1388620800000, 0.7323],
  [1388707200000, 0.7336],
  [1388966400000, 0.7353],
  [1389052800000, 0.7332],
  [1389139200000, 0.7357],
  [1389225600000, 0.7347],
  [1389312000000, 0.7361],
  [1389571200000, 0.7325],
  [1389657600000, 0.7318],
  [1389744000000, 0.7351],
  [1389830400000, 0.7356],
  [1389916800000, 0.7363],
  [1390176000000, 0.7372],
  [1390262400000, 0.7394],
  [1390348800000, 0.7372],
  [1390435200000, 0.7333],
  [1390521600000, 0.7307],
  [1390780800000, 0.7323],
  [1390867200000, 0.7328],
  [1390953600000, 0.735],
  [1391040000000, 0.7368],
  [1391126400000, 0.74],
  [1391385600000, 0.741],
  [1391472000000, 0.7398],
  [1391558400000, 0.7385],
  [1391644800000, 0.7411],
  [1391731200000, 0.7368],
  [1391990400000, 0.7333],
  [1392076800000, 0.7313],
  [1392163200000, 0.7369],
  [1392249600000, 0.7314],
  [1392336000000, 0.7297],
  [1392595200000, 0.7301],
  [1392681600000, 0.7284],
  [1392768000000, 0.7276],
  [1392854400000, 0.7297],
  [1392940800000, 0.7297],
  [1393200000000, 0.7282],
  [1393286400000, 0.7272],
  [1393372800000, 0.7286],
  [1393459200000, 0.7324],
  [1393545600000, 0.7241],
  [1393804800000, 0.7264],
  [1393891200000, 0.7264],
  [1393977600000, 0.7283],
  [1394064000000, 0.7276],
  [1394150400000, 0.7198],
  [1394409600000, 0.7205],
  [1394496000000, 0.7221],
  [1394582400000, 0.7202],
  [1394668800000, 0.7174],
  [1394755200000, 0.7204],
  [1395014400000, 0.7192],
  [1395100800000, 0.7194],
  [1395187200000, 0.7189],
  [1395273600000, 0.7267],
  [1395360000000, 0.7258],
  [1395619200000, 0.7261],
  [1395705600000, 0.7253],
  [1395792000000, 0.7252],
  [1395878400000, 0.7269],
  [1395964800000, 0.7269],
  [1396224000000, 0.7254],
  [1396310400000, 0.7253],
  [1396396800000, 0.725],
  [1396483200000, 0.7263],
  [1396569600000, 0.73],
  [1396828800000, 0.7288],
  [1396915200000, 0.7261],
  [1397001600000, 0.7251],
  [1397088000000, 0.7212],
  [1397174400000, 0.721],
  [1397433600000, 0.7233],
  [1397520000000, 0.7246],
  [1397606400000, 0.7226],
  [1397692800000, 0.7219],
  [1398124800000, 0.7238],
  [1398211200000, 0.723],
  [1398297600000, 0.7237],
  [1398384000000, 0.7231],
  [1398643200000, 0.7215],
  [1398729600000, 0.7234],
  [1398816000000, 0.7221],
  [1398988800000, 0.7215],
  [1399248000000, 0.7209],
  [1399334400000, 0.7172],
  [1399420800000, 0.7181],
  [1399507200000, 0.7168],
  [1399593600000, 0.7257],
  [1399852800000, 0.7266],
  [1399939200000, 0.7299],
  [1400025600000, 0.729],
  [1400112000000, 0.7322],
  [1400198400000, 0.7302],
  [1400457600000, 0.7292],
  [1400544000000, 0.7299],
  [1400630400000, 0.7313],
  [1400716800000, 0.7317],
  [1400803200000, 0.7338],
  [1401062400000, 0.7335],
  [1401148800000, 0.7333],
  [1401235200000, 0.735],
  [1401321600000, 0.7347],
  [1401408000000, 0.735],
  [1401667200000, 0.7348],
  [1401753600000, 0.733],
  [1401840000000, 0.7339],
  [1401926400000, 0.7372],
  [1402012800000, 0.7331],
  [1402272000000, 0.735],
  [1402358400000, 0.7383],
  [1402444800000, 0.7383],
  [1402531200000, 0.7393],
  [1402617600000, 0.739],
  [1402876800000, 0.7391],
  [1402963200000, 0.7371],
  [1403049600000, 0.7374],
  [1403136000000, 0.7343],
  [1403222400000, 0.736],
  [1403481600000, 0.7356],
  [1403568000000, 0.7344],
  [1403654400000, 0.7346],
  [1403740800000, 0.7351],
  [1403827200000, 0.7343],
  [1404086400000, 0.7323],
  [1404172800000, 0.7307],
  [1404259200000, 0.7324],
  [1404345600000, 0.7329],
  [1404432000000, 0.736],
  [1404691200000, 0.7358],
  [1404777600000, 0.736],
  [1404864000000, 0.7352],
  [1404950400000, 0.7352],
  [1405036800000, 0.7357],
  [1405296000000, 0.7339],
  [1405382400000, 0.7347],
  [1405468800000, 0.7391],
  [1405555200000, 0.7395],
  [1405641600000, 0.7395],
  [1405900800000, 0.7399],
  [1405987200000, 0.7419],
  [1406073600000, 0.7428],
  [1406160000000, 0.7424],
  [1406246400000, 0.7441],
  [1406505600000, 0.7445],
  [1406592000000, 0.7448],
  [1406678400000, 0.7463],
  [1406764800000, 0.7475],
  [1406851200000, 0.7466],
  [1407110400000, 0.7451],
  [1407196800000, 0.7474],
  [1407283200000, 0.7494],
  [1407369600000, 0.7482],
  [1407456000000, 0.747],
  [1407715200000, 0.7471],
  [1407801600000, 0.7494],
  [1407888000000, 0.7486],
  [1407974400000, 0.7479],
  [1408060800000, 0.747],
  [1408320000000, 0.7473],
  [1408406400000, 0.7489],
  [1408492800000, 0.7529],
  [1408579200000, 0.7541],
  [1408665600000, 0.7538],
  [1408924800000, 0.7577],
  [1409011200000, 0.7581],
  [1409097600000, 0.759],
  [1409184000000, 0.7589],
  [1409270400000, 0.7584],
  [1409529600000, 0.7615],
  [1409616000000, 0.7626],
  [1409702400000, 0.7605],
  [1409788800000, 0.7684],
  [1409875200000, 0.7724],
  [1410134400000, 0.7725],
  [1410220800000, 0.7752],
  [1410307200000, 0.7736],
  [1410393600000, 0.7736],
  [1410480000000, 0.7734],
  [1410739200000, 0.7746],
  [1410825600000, 0.7724],
  [1410912000000, 0.7719],
  [1410998400000, 0.777],
  [1411084800000, 0.7782],
  [1411344000000, 0.7786],
  [1411430400000, 0.7758],
  [1411516800000, 0.7798],
  [1411603200000, 0.7868],
  [1411689600000, 0.7855],
  [1411948800000, 0.7874],
  [1412035200000, 0.7948],
  [1412121600000, 0.7936],
  [1412208000000, 0.7918],
  [1412294400000, 0.7927],
  [1412553600000, 0.796],
  [1412640000000, 0.7933],
  [1412726400000, 0.7909],
  [1412812800000, 0.7836],
  [1412899200000, 0.7914],
  [1413158400000, 0.7888],
  [1413244800000, 0.7909],
  [1413331200000, 0.7896],
  [1413417600000, 0.7845],
  [1413504000000, 0.7799],
  [1413763200000, 0.783],
  [1413849600000, 0.7837],
  [1413936000000, 0.7879],
  [1414022400000, 0.7894],
  [1414108800000, 0.7901],
  [1414368000000, 0.7888],
  [1414454400000, 0.7845],
  [1414540800000, 0.7852],
  [1414627200000, 0.7939],
  [1414713600000, 0.7986],
  [1414972800000, 0.8005],
  [1415059200000, 0.7992],
  [1415145600000, 0.8014],
  [1415232000000, 0.799],
  [1415318400000, 0.807],
  [1415577600000, 0.801],
  [1415664000000, 0.805],
  [1415750400000, 0.8022],
  [1415836800000, 0.8029],
  [1415923200000, 0.8042],
  [1416182400000, 0.8004],
  [1416268800000, 0.7992],
  [1416355200000, 0.7979],
  [1416441600000, 0.7976],
  [1416528000000, 0.8051],
  [1416787200000, 0.8059],
  [1416873600000, 0.805],
  [1416960000000, 0.8017],
  [1417046400000, 0.8014],
  [1417132800000, 0.8012],
  [1417392000000, 0.8021],
  [1417478400000, 0.805],
  [1417564800000, 0.8111],
  [1417651200000, 0.8124],
  [1417737600000, 0.809],
  [1417996800000, 0.8159],
  [1418083200000, 0.8086],
  [1418169600000, 0.8071],
  [1418256000000, 0.8047],
  [1418342400000, 0.8033],
  [1418601600000, 0.8049],
  [1418688000000, 0.7977],
  [1418774400000, 0.8034],
  [1418860800000, 0.8141],
  [1418947200000, 0.8145],
  [1419206400000, 0.8158],
  [1419292800000, 0.8189],
  [1419379200000, 0.8185],
  [1419811200000, 0.82],
  [1419897600000, 0.8225],
  [1419984000000, 0.8238],
  [1420156800000, 0.8305],
  [1420416000000, 0.8394],
  [1420502400000, 0.8394],
  [1420588800000, 0.8453],
  [1420675200000, 0.8499],
  [1420761600000, 0.8466],
  [1421020800000, 0.8473],
  [1421107200000, 0.8489],
  [1421193600000, 0.8494],
  [1421280000000, 0.8542],
  [1421366400000, 0.8631],
  [1421625600000, 0.8618],
  [1421712000000, 0.8637],
  [1421798400000, 0.8627],
  [1421884800000, 0.8608],
  [1421971200000, 0.8931],
  [1422230400000, 0.8895],
  [1422316800000, 0.8846],
  [1422403200000, 0.8816],
  [1422489600000, 0.8839],
  [1422576000000, 0.8847],
  [1422835200000, 0.8843],
  [1422921600000, 0.8791],
  [1423008000000, 0.8738],
  [1423094400000, 0.8765],
  [1423180800000, 0.8737],
  [1423440000000, 0.887],
  [1423526400000, 0.8853],
  [1423612800000, 0.884],
  [1423699200000, 0.8829],
  [1423785600000, 0.8788],
  [1424044800000, 0.8767],
  [1424131200000, 0.8761],
  [1424217600000, 0.8795],
  [1424304000000, 0.8783],
  [1424390400000, 0.8852],
  [1424649600000, 0.8852],
  [1424736000000, 0.8829],
  [1424822400000, 0.8815],
  [1424908800000, 0.8837],
  [1424995200000, 0.8898],
  [1425254400000, 0.8908],
  [1425340800000, 0.8955],
  [1425427200000, 0.8991],
  [1425513600000, 0.9035],
  [1425600000000, 0.9123],
  [1425859200000, 0.9209],
  [1425945600000, 0.9314],
  [1426032000000, 0.9455],
  [1426118400000, 0.9423],
  [1426204800000, 0.946],
  [1426464000000, 0.9473],
  [1426550400000, 0.9404],
  [1426636800000, 0.9442],
  [1426723200000, 0.9367],
  [1426809600000, 0.9281],
  [1427068800000, 0.9165],
  [1427155200000, 0.9133],
  [1427241600000, 0.9104],
  [1427328000000, 0.9114],
  [1427414400000, 0.9212],
  [1427673600000, 0.9222],
  [1427760000000, 0.9296],
  [1427846400000, 0.9299],
  [1427932800000, 0.9235],
  [1428364800000, 0.922],
  [1428451200000, 0.9207],
  [1428537600000, 0.9283],
  [1428624000000, 0.9462],
  [1428883200000, 0.9478],
  [1428969600000, 0.9467],
  [1429056000000, 0.9454],
  [1429142400000, 0.9337],
  [1429228800000, 0.9248],
  [1429488000000, 0.9327],
  [1429574400000, 0.9347],
  [1429660800000, 0.9309],
  [1429747200000, 0.9284],
  [1429833600000, 0.924],
  [1430092800000, 0.9241],
  [1430179200000, 0.9153],
  [1430265600000, 0.909],
  [1430352000000, 0.8918],
  [1430697600000, 0.8968],
  [1430784000000, 0.8996],
  [1430870400000, 0.8906],
  [1430956800000, 0.8847],
  [1431043200000, 0.8913],
  [1431302400000, 0.8976],
  [1431388800000, 0.8899],
  [1431475200000, 0.8913],
  [1431561600000, 0.8758],
  [1431648000000, 0.8829],
  [1431907200000, 0.8781],
  [1431993600000, 0.8946],
  [1432080000000, 0.8995],
  [1432166400000, 0.8983],
  [1432252800000, 0.8958],
  [1432512000000, 0.911],
  [1432598400000, 0.9153],
  [1432684800000, 0.9207],
  [1432771200000, 0.9179],
  [1432857600000, 0.9117],
  [1433116800000, 0.9138],
  [1433203200000, 0.9068],
  [1433289600000, 0.8982],
  [1433376000000, 0.8837],
  [1433462400000, 0.8915],
  [1433721600000, 0.896],
  [1433808000000, 0.8891],
  [1433894400000, 0.8867],
  [1433980800000, 0.8904],
  [1434067200000, 0.8914],
  [1434326400000, 0.8915],
  [1434412800000, 0.8918],
  [1434499200000, 0.8867],
  [1434585600000, 0.877],
  [1434672000000, 0.8851],
  [1434931200000, 0.8815],
  [1435017600000, 0.8926],
  [1435104000000, 0.8919],
  [1435190400000, 0.8925],
  [1435276800000, 0.8928],
  [1435536000000, 0.8983],
  [1435622400000, 0.8938],
  [1435708800000, 0.901],
  [1435795200000, 0.9038],
  [1435881600000, 0.9013],
  [1436140800000, 0.9085],
  [1436227200000, 0.9149],
  [1436313600000, 0.9072],
  [1436400000000, 0.9047],
  [1436486400000, 0.8942],
  [1436745600000, 0.9052],
  [1436832000000, 0.9066],
  [1436918400000, 0.9084],
  [1437004800000, 0.9203],
  [1437091200000, 0.9185],
  [1437350400000, 0.9216],
  [1437436800000, 0.9203],
  [1437523200000, 0.9174],
  [1437609600000, 0.9093],
  [1437696000000, 0.9143],
  [1437955200000, 0.9044],
  [1438041600000, 0.9071],
  [1438128000000, 0.9067],
  [1438214400000, 0.9129],
  [1438300800000, 0.9119],
  [1438560000000, 0.9133],
  [1438646400000, 0.9114],
  [1438732800000, 0.919],
  [1438819200000, 0.9188],
  [1438905600000, 0.9141],
  [1439164800000, 0.9125],
  [1439251200000, 0.9047],
  [1439337600000, 0.8966],
  [1439424000000, 0.9003],
  [1439510400000, 0.8953],
  [1439769600000, 0.901],
  [1439856000000, 0.9043],
  [1439942400000, 0.9058],
  [1440028800000, 0.8943],
  [1440115200000, 0.8865],
  [1440374400000, 0.8699],
  [1440460800000, 0.8692],
  [1440547200000, 0.8771],
  [1440633600000, 0.8863],
  [1440720000000, 0.8876],
  [1440979200000, 0.8918],
  [1441065600000, 0.8901],
  [1441152000000, 0.8886],
  [1441238400000, 0.8907],
  [1441324800000, 0.8979],
  [1441584000000, 0.8973],
  [1441670400000, 0.896],
  [1441756800000, 0.8978],
  [1441843200000, 0.8942],
  [1441929600000, 0.8876],
  [1442188800000, 0.8847],
  [1442275200000, 0.8835],
  [1442361600000, 0.8907],
  [1442448000000, 0.8841],
  [1442534400000, 0.8758],
  [1442793600000, 0.889],
  [1442880000000, 0.8966],
  [1442966400000, 0.897],
  [1443052800000, 0.8897],
  [1443139200000, 0.8969],
  [1443398400000, 0.8954],
  [1443484800000, 0.8926],
  [1443571200000, 0.8927],
  [1443657600000, 0.8967],
  [1443744000000, 0.8962],
  [1444003200000, 0.8901],
  [1444089600000, 0.891],
  [1444176000000, 0.8877],
  [1444262400000, 0.8887],
  [1444348800000, 0.8802],
  [1444608000000, 0.8794],
  [1444694400000, 0.8793],
  [1444780800000, 0.8765],
  [1444867200000, 0.8743],
  [1444953600000, 0.8804],
  [1445212800000, 0.8825],
  [1445299200000, 0.8794],
  [1445385600000, 0.8808],
  [1445472000000, 0.884],
  [1445558400000, 0.9023],
  [1445817600000, 0.9083],
  [1445904000000, 0.9042],
  [1445990400000, 0.9022],
  [1446076800000, 0.915],
  [1446163200000, 0.9078],
  [1446422400000, 0.9066],
  [1446508800000, 0.9112],
  [1446595200000, 0.9146],
  [1446681600000, 0.919],
  [1446768000000, 0.9206],
  [1447027200000, 0.9281],
  [1447113600000, 0.9337],
  [1447200000000, 0.9333],
  [1447286400000, 0.9324],
  [1447372800000, 0.9291],
  [1447632000000, 0.9327],
  [1447718400000, 0.9373],
  [1447804800000, 0.9377],
  [1447891200000, 0.9358],
  [1447977600000, 0.9357],
  [1448236800000, 0.9407],
  [1448323200000, 0.939],
  [1448409600000, 0.9447],
  [1448496000000, 0.9424],
  [1448582400000, 0.9453],
  [1448841600000, 0.9454],
  [1448928000000, 0.9435],
  [1449014400000, 0.9424],
  [1449100800000, 0.9372],
  [1449187200000, 0.9174],
  [1449446400000, 0.9253],
  [1449532800000, 0.9196],
  [1449619200000, 0.9141],
  [1449705600000, 0.9139],
  [1449792000000, 0.9133],
  [1450051200000, 0.9106],
  [1450137600000, 0.91],
  [1450224000000, 0.9148],
  [1450310400000, 0.9225],
  [1450396800000, 0.9229],
  [1450656000000, 0.9201],
  [1450742400000, 0.9132],
  [1450828800000, 0.9162],
  [1450915200000, 0.9136],
  [1451260800000, 0.9123],
  [1451347200000, 0.9132],
  [1451433600000, 0.9153],
  [1451520000000, 0.9186],
  [1451865600000, 0.9177],
  [1451952000000, 0.9307],
  [1452038400000, 0.931],
  [1452124800000, 0.9202],
  [1452211200000, 0.9208],
  [1452470400000, 0.9185],
  [1452556800000, 0.9229],
  [1452643200000, 0.9247],
  [1452729600000, 0.9181],
  [1452816000000, 0.9164],
  [1453075200000, 0.9182],
  [1453161600000, 0.9202],
  [1453248000000, 0.9169],
  [1453334400000, 0.9181],
  [1453420800000, 0.9253],
  [1453680000000, 0.9247],
  [1453766400000, 0.9229],
  [1453852800000, 0.9185],
  [1453939200000, 0.9173],
  [1454025600000, 0.9159],
  [1454284800000, 0.9189],
  [1454371200000, 0.9159],
  [1454457600000, 0.9148],
  [1454544000000, 0.8925],
  [1454630400000, 0.8928],
  [1454889600000, 0.9009],
  [1454976000000, 0.8901],
  [1455062400000, 0.8884],
  [1455148800000, 0.8814],
  [1455235200000, 0.887],
  [1455494400000, 0.8946],
  [1455580800000, 0.8957],
  [1455667200000, 0.8981],
  [1455753600000, 0.9023],
  [1455840000000, 0.9013],
  [1456099200000, 0.907],
  [1456185600000, 0.909],
  [1456272000000, 0.9108],
  [1456358400000, 0.907],
  [1456444800000, 0.9087],
  [1456704000000, 0.9185],
  [1456790400000, 0.9199],
  [1456876800000, 0.9212],
  [1456963200000, 0.9174],
  [1457049600000, 0.9117],
  [1457308800000, 0.9131],
  [1457395200000, 0.9069],
  [1457481600000, 0.9114],
  [1457568000000, 0.9212],
  [1457654400000, 0.9018],
  [1457913600000, 0.8995],
  [1458000000000, 0.9003],
  [1458086400000, 0.9039],
  [1458172800000, 0.8842],
  [1458259200000, 0.8867],
  [1458518400000, 0.8873],
  [1458604800000, 0.892],
  [1458691200000, 0.8953],
  [1458777600000, 0.8966],
  [1459209600000, 0.8934],
  [1459296000000, 0.8832],
  [1459382400000, 0.8784],
  [1459468800000, 0.8748],
  [1459728000000, 0.8788],
  [1459814400000, 0.8798],
  [1459900800000, 0.8822],
  [1459987200000, 0.8801],
  [1460073600000, 0.8801],
  [1460332800000, 0.8781],
  [1460419200000, 0.8776],
  [1460505600000, 0.8852],
  [1460592000000, 0.8888],
  [1460678400000, 0.8863],
  [1460937600000, 0.8846],
  [1461024000000, 0.8817],
  [1461110400000, 0.8789],
  [1461196800000, 0.8808],
  [1461283200000, 0.888],
  [1461542400000, 0.8879],
  [1461628800000, 0.8861],
  [1461715200000, 0.8848],
  [1461801600000, 0.8805],
  [1461888000000, 0.8771],
  [1462147200000, 0.8702],
  [1462233600000, 0.8645],
  [1462320000000, 0.8693],
  [1462406400000, 0.8743],
  [1462492800000, 0.8752],
  [1462752000000, 0.8777],
  [1462838400000, 0.8792],
  [1462924800000, 0.8766],
  [1463011200000, 0.8781],
  [1463097600000, 0.8813],
  [1463356800000, 0.8832],
  [1463443200000, 0.8836],
  [1463529600000, 0.8867],
  [1463616000000, 0.8932],
  [1463702400000, 0.8914],
  [1463961600000, 0.8918],
  [1464048000000, 0.8955],
  [1464134400000, 0.8973],
  [1464220800000, 0.8955],
  [1464307200000, 0.8955],
  [1464566400000, 0.8978],
  [1464652800000, 0.8966],
  [1464739200000, 0.895],
  [1464825600000, 0.8939],
  [1464912000000, 0.8966],
  [1465171200000, 0.8812],
  [1465257600000, 0.8813],
  [1465344000000, 0.879],
  [1465430400000, 0.8817],
  [1465516800000, 0.8847],
  [1465776000000, 0.8876],
  [1465862400000, 0.891],
  [1465948800000, 0.8906],
  [1466035200000, 0.895],
  [1466121600000, 0.8887],
  [1466380800000, 0.8826],
  [1466467200000, 0.884],
  [1466553600000, 0.8864],
  [1466640000000, 0.8781],
  [1466726400000, 0.9038],
  [1466985600000, 0.9094],
  [1467072000000, 0.9032],
  [1467158400000, 0.9018],
  [1467244800000, 0.9008],
  [1467331200000, 0.8982],
  [1467590400000, 0.8979],
  [1467676800000, 0.8973],
  [1467763200000, 0.9035],
  [1467849600000, 0.9026],
  [1467936000000, 0.9034],
  [1468195200000, 0.9052],
  [1468281600000, 0.9017],
  [1468368000000, 0.9033],
  [1468454400000, 0.8964],
  [1468540800000, 0.8987],
  [1468800000000, 0.9048],
  [1468886400000, 0.9063],
  [1468972800000, 0.9081],
  [1469059200000, 0.908],
  [1469145600000, 0.908],
  [1469404800000, 0.9107],
  [1469491200000, 0.9094],
  [1469577600000, 0.9099],
  [1469664000000, 0.9018],
  [1469750400000, 0.8999],
  [1470009600000, 0.8958],
  [1470096000000, 0.8935],
  [1470182400000, 0.893],
  [1470268800000, 0.8981],
  [1470355200000, 0.8965],
  [1470614400000, 0.9021],
  [1470700800000, 0.9028],
  [1470787200000, 0.8942],
  [1470873600000, 0.8967],
  [1470960000000, 0.8963],
  [1471219200000, 0.8946],
  [1471305600000, 0.8854],
  [1471392000000, 0.8869],
  [1471478400000, 0.8834],
  [1471564800000, 0.883],
  [1471824000000, 0.8846],
  [1471910400000, 0.882],
  [1471996800000, 0.8876],
  [1472083200000, 0.8858],
  [1472169600000, 0.8858],
  [1472428800000, 0.8954],
  [1472515200000, 0.8955],
  [1472601600000, 0.8984],
  [1472688000000, 0.8973],
  [1472774400000, 0.8935],
  [1473033600000, 0.8965],
  [1473120000000, 0.8962],
  [1473206400000, 0.89],
  [1473292800000, 0.8854],
  [1473379200000, 0.8876],
  [1473638400000, 0.8909],
  [1473724800000, 0.8892],
  [1473811200000, 0.8915],
  [1473897600000, 0.8887],
  [1473984000000, 0.8909],
  [1474243200000, 0.8958],
  [1474329600000, 0.8942],
  [1474416000000, 0.897],
  [1474502400000, 0.8899],
  [1474588800000, 0.8918],
  [1474848000000, 0.888],
  [1474934400000, 0.8914],
  [1475020800000, 0.891],
  [1475107200000, 0.8913],
  [1475193600000, 0.8961],
  [1475452800000, 0.8901],
  [1475539200000, 0.8961],
  [1475625600000, 0.8921],
  [1475712000000, 0.8942],
  [1475798400000, 0.8978],
  [1476057600000, 0.8962],
  [1476144000000, 0.9027],
  [1476230400000, 0.9075],
  [1476316800000, 0.9061],
  [1476403200000, 0.909],
  [1476662400000, 0.9097],
  [1476748800000, 0.9098],
  [1476835200000, 0.9109],
  [1476921600000, 0.9108],
  [1477008000000, 0.9187],
  [1477267200000, 0.9183],
  [1477353600000, 0.9199],
  [1477440000000, 0.9154],
  [1477526400000, 0.9153],
  [1477612800000, 0.9157],
  [1477872000000, 0.9137],
  [1477958400000, 0.9071],
  [1478044800000, 0.9014],
  [1478131200000, 0.9039],
  [1478217600000, 0.9016],
  [1478476800000, 0.9041],
  [1478563200000, 0.9061],
  [1478649600000, 0.9074],
  [1478736000000, 0.918],
  [1478822400000, 0.9172],
  [1479081600000, 0.928],
  [1479168000000, 0.929],
  [1479254400000, 0.9345],
  [1479340800000, 0.9332],
  [1479427200000, 0.9409],
  [1479686400000, 0.9407],
  [1479772800000, 0.942],
  [1479859200000, 0.9433],
  [1479945600000, 0.9481],
  [1480032000000, 0.9442],
  [1480291200000, 0.9446],
  [1480377600000, 0.9456],
  [1480464000000, 0.9404],
  [1480550400000, 0.9411],
  [1480636800000, 0.9398],
  [1480896000000, 0.9345],
  [1480982400000, 0.9317],
  [1481068800000, 0.9321],
  [1481155200000, 0.9293],
  [1481241600000, 0.9472],
  [1481500800000, 0.9439],
  [1481587200000, 0.9426],
  [1481673600000, 0.9396],
  [1481760000000, 0.9599],
  [1481846400000, 0.958],
  [1482105600000, 0.9596],
  [1482192000000, 0.965],
  [1482278400000, 0.9597],
  [1482364800000, 0.9576],
  [1482451200000, 0.9574],
  [1482796800000, 0.9575],
  [1482883200000, 0.9615],
  [1482969600000, 0.9568],
  [1483056000000, 0.9488],
  [1483315200000, 0.9557],
  [1483401600000, 0.963],
  [1483488000000, 0.9582],
  [1483574400000, 0.9524],
  [1483660800000, 0.9445],
  [1483920000000, 0.951],
  [1484006400000, 0.9464],
  [1484092800000, 0.9522],
  [1484179200000, 0.9365],
  [1484265600000, 0.9381],
  [1484524800000, 0.944],
  [1484611200000, 0.9361],
  [1484697600000, 0.9378],
  [1484784000000, 0.9375],
  [1484870400000, 0.9407],
  [1485129600000, 0.9334],
  [1485216000000, 0.9305],
  [1485302400000, 0.9309],
  [1485388800000, 0.9347],
  [1485475200000, 0.9363],
  [1485734400000, 0.9408],
  [1485820800000, 0.9299],
  [1485907200000, 0.9269],
  [1485993600000, 0.9253],
  [1486080000000, 0.9311],
  [1486339200000, 0.9336],
  [1486425600000, 0.9369],
  [1486512000000, 0.9377],
  [1486598400000, 0.9354],
  [1486684800000, 0.9409],
  [1486944000000, 0.9409],
  [1487030400000, 0.9415],
  [1487116800000, 0.9475],
  [1487203200000, 0.9389],
  [1487289600000, 0.9391],
  [1487548800000, 0.9421],
  [1487635200000, 0.9491],
  [1487721600000, 0.9513],
  [1487808000000, 0.9459],
  [1487894400000, 0.9427],
  [1488153600000, 0.9447],
  [1488240000000, 0.9438],
  [1488326400000, 0.9495],
  [1488412800000, 0.9512],
  [1488499200000, 0.9466],
  [1488758400000, 0.9442],
  [1488844800000, 0.9456],
  [1488931200000, 0.9474],
  [1489017600000, 0.9479],
  [1489104000000, 0.943],
  [1489363200000, 0.9379],
  [1489449600000, 0.9407],
  [1489536000000, 0.9415],
  [1489622400000, 0.9324],
  [1489708800000, 0.9315],
  [1489968000000, 0.9302],
  [1490054400000, 0.9259],
  [1490140800000, 0.9254],
  [1490227200000, 0.9272],
  [1490313600000, 0.9256],
  [1490572800000, 0.9185],
  [1490659200000, 0.921],
  [1490745600000, 0.9305],
  [1490832000000, 0.9315],
  [1490918400000, 0.9355],
  [1491177600000, 0.9381],
  [1491264000000, 0.939],
  [1491350400000, 0.9366],
  [1491436800000, 0.9377],
  [1491523200000, 0.9408],
  [1491782400000, 0.9455],
  [1491868800000, 0.9421],
  [1491955200000, 0.9431],
  [1492041600000, 0.9408],
  [1492473600000, 0.9363],
  [1492560000000, 0.9325],
  [1492646400000, 0.9308],
  [1492732800000, 0.9349],
  [1492992000000, 0.9219],
  [1493078400000, 0.9183],
  [1493164800000, 0.9181],
  [1493251200000, 0.9191],
  [1493337600000, 0.915],
  [1493683200000, 0.9163],
  [1493769600000, 0.9159],
  [1493856000000, 0.9153],
  [1493942400000, 0.9124],
  [1494201600000, 0.9143],
  [1494288000000, 0.9185],
  [1494374400000, 0.919],
  [1494460800000, 0.9209],
  [1494547200000, 0.9196],
  [1494806400000, 0.9115],
  [1494892800000, 0.9043],
  [1494979200000, 0.8996],
  [1495065600000, 0.8987],
  [1495152000000, 0.8946],
  [1495411200000, 0.8895],
  [1495497600000, 0.8918],
  [1495584000000, 0.8935],
  [1495670400000, 0.8918],
  [1495756800000, 0.8933],
  [1496016000000, 0.8939],
  [1496102400000, 0.8951],
  [1496188800000, 0.8913],
  [1496275200000, 0.8914],
  [1496361600000, 0.8916],
  [1496620800000, 0.8891],
  [1496707200000, 0.8884],
  [1496793600000, 0.8916],
  [1496880000000, 0.8907],
  [1496966400000, 0.8949],
  [1497225600000, 0.8913],
  [1497312000000, 0.8916],
  [1497398400000, 0.8927],
  [1497484800000, 0.8957],
  [1497571200000, 0.8956],
  [1497830400000, 0.893],
  [1497916800000, 0.8965],
  [1498003200000, 0.8972],
  [1498089600000, 0.8954],
  [1498176000000, 0.8951],
  [1498435200000, 0.894],
  [1498521600000, 0.8868],
  [1498608000000, 0.8792],
  [1498694400000, 0.8763],
  [1498780800000, 0.8764],
  [1499040000000, 0.8797],
  [1499126400000, 0.8809],
  [1499212800000, 0.8828],
  [1499299200000, 0.8784],
  [1499385600000, 0.8764],
  [1499644800000, 0.8783],
  [1499731200000, 0.8769],
  [1499817600000, 0.8735],
  [1499904000000, 0.876],
  [1499990400000, 0.8761],
  [1500249600000, 0.8725],
  [1500336000000, 0.8655],
  [1500422400000, 0.8672],
  [1500508800000, 0.8708],
  [1500595200000, 0.8591],
  [1500854400000, 0.8586],
  [1500940800000, 0.8552],
  [1501027200000, 0.8589],
  [1501113600000, 0.8552],
  [1501200000000, 0.8527],
  [1501459200000, 0.8528],
  [1501545600000, 0.8467],
  [1501632000000, 0.8455],
  [1501718400000, 0.8433],
  [1501804800000, 0.8427],
  [1502064000000, 0.8478],
  [1502150400000, 0.8466],
  [1502236800000, 0.8525],
  [1502323200000, 0.8525],
  [1502409600000, 0.8501],
  [1502668800000, 0.8478],
  [1502755200000, 0.8516],
  [1502841600000, 0.8541],
  [1502928000000, 0.855],
  [1503014400000, 0.8519],
  [1503273600000, 0.8504],
  [1503360000000, 0.8496],
  [1503446400000, 0.8476],
  [1503532800000, 0.8471],
  [1503619200000, 0.847],
  [1503878400000, 0.8387],
  [1503964800000, 0.8301],
  [1504051200000, 0.8393],
  [1504137600000, 0.8458],
  [1504224000000, 0.839],
  [1504483200000, 0.8401],
  [1504569600000, 0.8411],
  [1504656000000, 0.8383],
  [1504742400000, 0.8355],
  [1504828800000, 0.8293],
  [1505088000000, 0.8336],
  [1505174400000, 0.8381],
  [1505260800000, 0.8349],
  [1505347200000, 0.8415],
  [1505433600000, 0.836],
  [1505692800000, 0.8371],
  [1505779200000, 0.8354],
  [1505865600000, 0.8329],
  [1505952000000, 0.8401],
  [1506038400000, 0.8362],
  [1506297600000, 0.8428],
  [1506384000000, 0.8485],
  [1506470400000, 0.8518],
  [1506556800000, 0.8491],
  [1506643200000, 0.8471],
  [1506902400000, 0.8516],
  [1506988800000, 0.8509],
  [1507075200000, 0.8485],
  [1507161600000, 0.8517],
  [1507248000000, 0.8543],
  [1507507200000, 0.8515],
  [1507593600000, 0.8478],
  [1507680000000, 0.8454],
  [1507766400000, 0.8436],
  [1507852800000, 0.8468],
  [1508112000000, 0.8473],
  [1508198400000, 0.8505],
  [1508284800000, 0.8512],
  [1508371200000, 0.8451],
  [1508457600000, 0.8463],
  [1508716800000, 0.8519],
  [1508803200000, 0.8504],
  [1508889600000, 0.8486],
  [1508976000000, 0.8509],
  [1509062400000, 0.8618],
  [1509321600000, 0.8613],
  [1509408000000, 0.8594],
  [1509494400000, 0.8613],
  [1509580800000, 0.8588],
  [1509667200000, 0.858],
  [1509926400000, 0.8629],
  [1510012800000, 0.865],
  [1510099200000, 0.8629],
  [1510185600000, 0.8599],
  [1510272000000, 0.8582],
  [1510531200000, 0.858],
  [1510617600000, 0.8515],
  [1510704000000, 0.8447],
  [1510790400000, 0.8496],
  [1510876800000, 0.8479],
  [1511136000000, 0.8489],
  [1511222400000, 0.8535],
  [1511308800000, 0.8512],
  [1511395200000, 0.8441],
  [1511481600000, 0.8421],
  [1511740800000, 0.8368],
  [1511827200000, 0.8413],
  [1511913600000, 0.8456],
  [1512000000000, 0.8441],
  [1512086400000, 0.8415],
  [1512345600000, 0.8429],
  [1512432000000, 0.8442],
  [1512518400000, 0.8463],
  [1512604800000, 0.8486],
  [1512691200000, 0.8517],
  [1512950400000, 0.8478],
  [1513036800000, 0.85],
  [1513123200000, 0.8522],
  [1513209600000, 0.8443],
  [1513296000000, 0.8471],
  [1513555200000, 0.8479],
  [1513641600000, 0.8459],
  [1513728000000, 0.8443],
  [1513814400000, 0.8433],
  [1513900800000, 0.8438],
  [1514332800000, 0.8408],
  [1514419200000, 0.838],
  [1514505600000, 0.8339],
  [1514851200000, 0.8289],
  [1514937600000, 0.8318],
  [1515024000000, 0.8289],
  [1515110400000, 0.8303],
  [1515369600000, 0.8353],
  [1515456000000, 0.8382],
  [1515542400000, 0.834],
  [1515628800000, 0.8323],
  [1515715200000, 0.824],
  [1515974400000, 0.8146],
  [1516060800000, 0.8178],
  [1516147200000, 0.8196],
  [1516233600000, 0.8174],
  [1516320000000, 0.8161],
  [1516579200000, 0.8172],
  [1516665600000, 0.8165],
  [1516752000000, 0.8097],
  [1516838400000, 0.8061],
  [1516924800000, 0.8042],
  [1517184000000, 0.8079],
  [1517270400000, 0.8052],
  [1517356800000, 0.8029],
  [1517443200000, 0.8027],
  [1517529600000, 0.8006],
  [1517788800000, 0.804],
  [1517875200000, 0.8112],
  [1517961600000, 0.8106],
  [1518048000000, 0.8163],
  [1518134400000, 0.8149],
  [1518393600000, 0.8156],
  [1518480000000, 0.8109],
  [1518566400000, 0.8099],
  [1518652800000, 0.8005],
  [1518739200000, 0.8024],
  [1518998400000, 0.8059],
  [1519084800000, 0.8105],
  [1519171200000, 0.8123],
  [1519257600000, 0.8147],
  [1519344000000, 0.8132],
  [1519603200000, 0.8118],
  [1519689600000, 0.813],
  [1519776000000, 0.8188],
  [1519862400000, 0.8217],
  [1519948800000, 0.8123],
  [1520208000000, 0.8126],
  [1520294400000, 0.8058],
  [1520380800000, 0.8054],
  [1520467200000, 0.8052],
  [1520553600000, 0.8137],
  [1520812800000, 0.813],
  [1520899200000, 0.808],
  [1520985600000, 0.8086],
  [1521072000000, 0.8104],
  [1521158400000, 0.813],
  [1521417600000, 0.8125],
  [1521504000000, 0.8147],
  [1521590400000, 0.814],
  [1521676800000, 0.8121],
  [1521763200000, 0.8101],
  [1522022400000, 0.8058],
  [1522108800000, 0.8081],
  [1522195200000, 0.8067],
  [1522281600000, 0.8117],
  [1522713600000, 0.8126],
  [1522800000000, 0.8147],
  [1522886400000, 0.8158],
  [1522972800000, 0.8175],
  [1523232000000, 0.8128],
  [1523318400000, 0.8091],
  [1523404800000, 0.8076],
  [1523491200000, 0.8116],
  [1523577600000, 0.812],
  [1523836800000, 0.8085],
  [1523923200000, 0.8094],
  [1524009600000, 0.8073],
  [1524096000000, 0.8077],
  [1524182400000, 0.8125],
  [1524441600000, 0.8172],
  [1524528000000, 0.8189],
  [1524614400000, 0.8208],
  [1524700800000, 0.8219],
  [1524787200000, 0.8286],
  [1525046400000, 0.828],
  [1525219200000, 0.8329],
  [1525305600000, 0.834],
  [1525392000000, 0.8356],
  [1525651200000, 0.8403],
  [1525737600000, 0.8426],
  [1525824000000, 0.8419],
  [1525910400000, 0.842],
  [1525996800000, 0.838],
  [1526256000000, 0.8343],
  [1526342400000, 0.8416],
  [1526428800000, 0.8487],
  [1526515200000, 0.8472],
  [1526601600000, 0.8489],
  [1526860800000, 0.8505],
  [1526947200000, 0.848],
  [1527033600000, 0.8542],
  [1527120000000, 0.8528],
  [1527206400000, 0.8566],
  [1527465600000, 0.8589],
  [1527552000000, 0.8653],
  [1527638400000, 0.8598],
  [1527724800000, 0.8549],
  [1527811200000, 0.8571],
  [1528070400000, 0.8521],
  [1528156800000, 0.8566],
  [1528243200000, 0.8501],
  [1528329600000, 0.845],
  [1528416000000, 0.8509],
  [1528675200000, 0.8483],
  [1528761600000, 0.8484],
  [1528848000000, 0.8502],
  [1528934400000, 0.8526],
  [1529020800000, 0.8625],
  [1529280000000, 0.8612],
  [1529366400000, 0.8671],
  [1529452800000, 0.8638],
  [1529539200000, 0.8668],
  [1529625600000, 0.8586],
  [1529884800000, 0.8548],
  [1529971200000, 0.8569],
  [1530057600000, 0.861],
  [1530144000000, 0.8634],
  [1530230400000, 0.8579],
  [1530489600000, 0.8593],
  [1530576000000, 0.8574],
  [1530662400000, 0.8591],
  [1530748800000, 0.8541],
  [1530835200000, 0.8531],
  [1531094400000, 0.8483],
  [1531180800000, 0.8539],
  [1531267200000, 0.8523],
  [1531353600000, 0.8579],
  [1531440000000, 0.859],
  [1531699200000, 0.8533],
  [1531785600000, 0.8543],
  [1531872000000, 0.8614],
  [1531958400000, 0.8631],
  [1532044800000, 0.857],
  [1532304000000, 0.8536],
  [1532390400000, 0.8544],
  [1532476800000, 0.8555],
  [1532563200000, 0.8536],
  [1532649600000, 0.8603],
  [1532908800000, 0.856],
  [1532995200000, 0.8522],
  [1533081600000, 0.8551],
  [1533168000000, 0.8609],
  [1533254400000, 0.8631],
  [1533513600000, 0.8664],
  [1533600000000, 0.862],
  [1533686400000, 0.863],
  [1533772800000, 0.8627],
  [1533859200000, 0.873],
  [1534118400000, 0.8771],
  [1534204800000, 0.8768],
  [1534291200000, 0.8834],
  [1534377600000, 0.8796],
  [1534464000000, 0.878],
  [1534723200000, 0.8758],
  [1534809600000, 0.8695],
  [1534896000000, 0.861],
  [1534982400000, 0.8637],
  [1535068800000, 0.8631],
  [1535328000000, 0.8597],
  [1535414400000, 0.8541],
  [1535500800000, 0.8577],
  [1535587200000, 0.8554],
  [1535673600000, 0.8584],
  [1535932800000, 0.8615],
  [1536019200000, 0.865],
  [1536105600000, 0.8635],
  [1536192000000, 0.8596],
  [1536278400000, 0.8611],
  [1536537600000, 0.8643],
  [1536624000000, 0.8641],
  [1536710400000, 0.8633],
  [1536796800000, 0.8607],
  [1536883200000, 0.8556],
  [1537142400000, 0.8569],
  [1537228800000, 0.855],
  [1537315200000, 0.8572],
  [1537401600000, 0.8498],
  [1537488000000, 0.8505],
  [1537747200000, 0.8495],
  [1537833600000, 0.8492],
  [1537920000000, 0.8521],
  [1538006400000, 0.8543],
  [1538092800000, 0.864],
  [1538352000000, 0.8617],
  [1538438400000, 0.8664],
  [1538524800000, 0.8661],
  [1538611200000, 0.8695],
  [1538697600000, 0.8692],
  [1538956800000, 0.8713],
  [1539043200000, 0.8746],
  [1539129600000, 0.8697],
  [1539216000000, 0.864],
  [1539302400000, 0.8641],
  [1539561600000, 0.8636],
  [1539648000000, 0.8631],
  [1539734400000, 0.8674],
  [1539820800000, 0.8693],
  [1539907200000, 0.8719],
  [1540166400000, 0.8701],
  [1540252800000, 0.8713],
  [1540339200000, 0.8781],
  [1540425600000, 0.8761],
  [1540512000000, 0.8815],
  [1540771200000, 0.8788],
  [1540857600000, 0.8795],
  [1540944000000, 0.8836],
  [1541030400000, 0.8778],
  [1541116800000, 0.876],
  [1541376000000, 0.8796],
  [1541462400000, 0.8751],
  [1541548800000, 0.8706],
  [1541635200000, 0.8755],
  [1541721600000, 0.8815],
  [1541980800000, 0.8878],
  [1542067200000, 0.8881],
  [1542153600000, 0.8854],
  [1542240000000, 0.8847],
  [1542326400000, 0.8815],
  [1542585600000, 0.8752],
  [1542672000000, 0.8757],
  [1542758400000, 0.8766],
  [1542844800000, 0.8771],
  [1542931200000, 0.881],
  [1543190400000, 0.8801],
  [1543276800000, 0.8829],
  [1543363200000, 0.8863],
  [1543449600000, 0.8783],
  [1543536000000, 0.8805],
  [1543795200000, 0.8826],
  [1543881600000, 0.8766],
  [1543968000000, 0.8808],
  [1544054400000, 0.8811],
  [1544140800000, 0.8795],
  [1544400000000, 0.8754],
  [1544486400000, 0.8789],
  [1544572800000, 0.8815],
  [1544659200000, 0.8795],
  [1544745600000, 0.8862],
  [1545004800000, 0.8819],
  [1545091200000, 0.8791],
  [1545177600000, 0.8769],
  [1545264000000, 0.8734],
  [1545350400000, 0.8762],
  [1545609600000, 0.8767],
  [1545868800000, 0.8791],
  [1545955200000, 0.8732],
  [1546214400000, 0.8735],
  [1546387200000, 0.8775],
  [1546473600000, 0.8813],
  [1546560000000, 0.8771],
  [1546819200000, 0.8738],
  [1546905600000, 0.8742],
  [1546992000000, 0.8731],
  [1547078400000, 0.867],
  [1547164800000, 0.8672],
  [1547424000000, 0.8722],
  [1547510400000, 0.8755],
  [1547596800000, 0.8781],
  [1547683200000, 0.8776],
  [1547769600000, 0.8771],
  [1548028800000, 0.8802],
  [1548115200000, 0.8808],
  [1548201600000, 0.8798],
  [1548288000000, 0.8819],
  [1548374400000, 0.8815],
  [1548633600000, 0.8759],
  [1548720000000, 0.8756],
  [1548806400000, 0.8751],
  [1548892800000, 0.8706],
  [1548979200000, 0.8719],
  [1549238400000, 0.8738],
  [1549324800000, 0.8755],
  [1549411200000, 0.8778],
  [1549497600000, 0.8815],
  [1549584000000, 0.8815],
  [1549843200000, 0.8844],
  [1549929600000, 0.8854],
  [1550016000000, 0.8847],
  [1550102400000, 0.8876],
  [1550188800000, 0.8882],
  [1550448000000, 0.8829],
  [1550534400000, 0.8855],
  [1550620800000, 0.8818],
  [1550707200000, 0.8808],
  [1550793600000, 0.8831],
  [1551052800000, 0.8808],
  [1551139200000, 0.8803],
  [1551225600000, 0.8784],
  [1551312000000, 0.8761],
  [1551398400000, 0.8786],
  [1551657600000, 0.8822],
  [1551744000000, 0.8828],
  [1551830400000, 0.8847],
  [1551916800000, 0.8873],
  [1552003200000, 0.8912],
  [1552262400000, 0.8895],
  [1552348800000, 0.887],
  [1552435200000, 0.8848],
  [1552521600000, 0.8854],
  [1552608000000, 0.8844],
  [1552867200000, 0.8812],
  [1552953600000, 0.8805],
  [1553040000000, 0.8808],
  [1553126400000, 0.8783],
  [1553212800000, 0.8849],
  [1553472000000, 0.8831],
  [1553558400000, 0.8858],
  [1553644800000, 0.8881],
  [1553731200000, 0.8915],
  [1553817600000, 0.8902],
  [1554076800000, 0.8901],
  [1554163200000, 0.893],
  [1554249600000, 0.8895],
  [1554336000000, 0.8914],
  [1554422400000, 0.8903],
  [1554681600000, 0.8893],
  [1554768000000, 0.8869],
  [1554854400000, 0.8867],
  [1554940800000, 0.8879],
  [1555027200000, 0.8834],
  [1555286400000, 0.884],
  [1555372800000, 0.8847],
  [1555459200000, 0.885],
  [1555545600000, 0.889],
  [1555977600000, 0.8894],
  [1556064000000, 0.8922],
  [1556150400000, 0.8991],
  [1556236800000, 0.8983],
  [1556496000000, 0.897],
  [1556582400000, 0.8915],
  [1556755200000, 0.892],
  [1556841600000, 0.8966],
  [1557100800000, 0.893],
  [1557187200000, 0.8942],
  [1557273600000, 0.8928],
  [1557360000000, 0.8935],
  [1557446400000, 0.8906],
  [1557705600000, 0.8894],
  [1557792000000, 0.8909],
  [1557878400000, 0.8943],
  [1557964800000, 0.8927],
  [1558051200000, 0.8952],
  [1558310400000, 0.8956],
  [1558396800000, 0.8961],
  [1558483200000, 0.8953],
  [1558569600000, 0.8978],
  [1558656000000, 0.894],
  [1558915200000, 0.8931],
  [1559001600000, 0.8936],
  [1559088000000, 0.8965],
  [1559174400000, 0.8982],
  [1559260800000, 0.8969],
  [1559520000000, 0.8942],
  [1559606400000, 0.8895],
  [1559692800000, 0.8884],
  [1559779200000, 0.8877],
  [1559865600000, 0.8872],
  [1560124800000, 0.885],
  [1560211200000, 0.8835],
  [1560297600000, 0.8833],
  [1560384000000, 0.8859],
  [1560470400000, 0.8878],
  [1560729600000, 0.8903],
  [1560816000000, 0.894],
  [1560902400000, 0.8924],
  [1560988800000, 0.8845],
  [1561075200000, 0.8838],
  [1561334400000, 0.8778],
  [1561420800000, 0.8782],
  [1561507200000, 0.8802],
  [1561593600000, 0.8796],
  [1561680000000, 0.8788],
  [1561939200000, 0.8812],
  [1562025600000, 0.885],
  [1562112000000, 0.8856],
  [1562198400000, 0.886],
  [1562284800000, 0.8882],
  [1562544000000, 0.8918],
  [1562630400000, 0.8926],
  [1562716800000, 0.8914],
  [1562803200000, 0.8862],
  [1562889600000, 0.8888],
  [1563148800000, 0.8875],
  [1563235200000, 0.8911],
  [1563321600000, 0.8918],
  [1563408000000, 0.8917],
  [1563494400000, 0.8909],
  [1563753600000, 0.8918],
  [1563840000000, 0.8951],
  [1563926400000, 0.8978],
  [1564012800000, 0.8998],
  [1564099200000, 0.8979],
  [1564358400000, 0.8995],
  [1564444800000, 0.8966],
  [1564531200000, 0.8969],
  [1564617600000, 0.9061],
  [1564704000000, 0.9005],
  [1564963200000, 0.8944],
  [1565049600000, 0.894],
  [1565136000000, 0.8928],
  [1565222400000, 0.8935],
  [1565308800000, 0.8931],
  [1565568000000, 0.8934],
  [1565654400000, 0.8912],
  [1565740800000, 0.8939],
  [1565827200000, 0.897],
  [1565913600000, 0.903],
  [1566172800000, 0.9008],
  [1566259200000, 0.903],
  [1566345600000, 0.9007],
  [1566432000000, 0.9024],
  [1566518400000, 0.9039],
  [1566777600000, 0.8997],
  [1566864000000, 0.9007],
  [1566950400000, 0.9024],
  [1567036800000, 0.9033],
  [1567123200000, 0.9062],
  [1567382400000, 0.9118],
  [1567468800000, 0.9144],
  [1567555200000, 0.9077],
  [1567641600000, 0.9044],
  [1567728000000, 0.907],
  [1567987200000, 0.9065],
  [1568073600000, 0.9059],
  [1568160000000, 0.9089],
  [1568246400000, 0.9123],
  [1568332800000, 0.9013],
  [1568592000000, 0.9066],
  [1568678400000, 0.907],
  [1568764800000, 0.9048],
  [1568851200000, 0.9037],
  [1568937600000, 0.9067],
  [1569196800000, 0.9104],
  [1569283200000, 0.9089],
  [1569369600000, 0.9107],
  [1569456000000, 0.9143],
  [1569542400000, 0.9146],
  [1569801600000, 0.9185],
  [1569888000000, 0.9177],
  [1569974400000, 0.9154],
  [1570060800000, 0.9133],
  [1570147200000, 0.9109],
  [1570406400000, 0.9098],
  [1570492800000, 0.9103],
  [1570579200000, 0.9108],
  [1570665600000, 0.9067],
  [1570752000000, 0.9057],
  [1571011200000, 0.9066],
  [1571097600000, 0.9086],
  [1571184000000, 0.9071],
  [1571270400000, 0.8999],
  [1571356800000, 0.8974],
  [1571616000000, 0.8951],
  [1571702400000, 0.8986],
  [1571788800000, 0.8991],
  [1571875200000, 0.8987],
  [1571961600000, 0.9004],
  [1572220800000, 0.9021],
  [1572307200000, 0.9014],
  [1572393600000, 0.9005],
  [1572480000000, 0.8966],
  [1572566400000, 0.8978],
  [1572825600000, 0.8963],
  [1572912000000, 0.9003],
  [1572998400000, 0.9018],
  [1573084800000, 0.9029],
  [1573171200000, 0.9064],
  [1573430400000, 0.9058],
  [1573516800000, 0.908],
  [1573603200000, 0.9087],
  [1573689600000, 0.9094],
  [1573776000000, 0.9064],
  [1574035200000, 0.9042],
  [1574121600000, 0.9029],
  [1574208000000, 0.9043],
  [1574294400000, 0.9017],
  [1574380800000, 0.9044],
  [1574640000000, 0.9085],
  [1574726400000, 0.9075],
  [1574812800000, 0.9084],
  [1574899200000, 0.9088],
  [1574985600000, 0.9107],
  [1575244800000, 0.9073],
  [1575331200000, 0.9034],
  [1575417600000, 0.9025],
  [1575504000000, 0.9015],
  [1575590400000, 0.9015],
  [1575849600000, 0.903],
  [1575936000000, 0.9029],
  [1576022400000, 0.903],
  [1576108800000, 0.898],
  [1576195200000, 0.895],
  [1576454400000, 0.8973],
  [1576540800000, 0.896],
  [1576627200000, 0.8998],
  [1576713600000, 0.8996],
  [1576800000000, 0.9012],
  [1577059200000, 0.903],
  [1577145600000, 0.9026],
  [1577404800000, 0.8967],
  [1577664000000, 0.8938],
  [1577750400000, 0.8903],
  [1577923200000, 0.8935],
  [1578009600000, 0.8972],
  [1578268800000, 0.8934],
  [1578355200000, 0.8952],
  [1578441600000, 0.8998],
  [1578528000000, 0.9002],
  [1578614400000, 0.9017],
  [1578873600000, 0.8989],
  [1578960000000, 0.8998],
  [1579046400000, 0.8976],
  [1579132800000, 0.8954],
  [1579219200000, 0.9004],
  [1579478400000, 0.9022],
  [1579564800000, 0.8998],
  [1579651200000, 0.902],
  [1579737600000, 0.9017],
  [1579824000000, 0.9063],
  [1580083200000, 0.9071],
  [1580169600000, 0.9088],
  [1580256000000, 0.9091],
  [1580342400000, 0.9068],
  [1580428800000, 0.9049],
  [1580688000000, 0.9038],
  [1580774400000, 0.9052],
  [1580860800000, 0.9073],
  [1580947200000, 0.9089],
  [1581033600000, 0.9118],
  [1581292800000, 0.9133],
  [1581379200000, 0.9174],
  [1581465600000, 0.9164],
  [1581552000000, 0.9203],
  [1581638400000, 0.9224],
  [1581897600000, 0.923],
  [1581984000000, 0.9247],
  [1582070400000, 0.926],
  [1582156800000, 0.9269],
  [1582243200000, 0.9259],
  [1582502400000, 0.9245],
  [1582588800000, 0.9226],
  [1582675200000, 0.9196],
  [1582761600000, 0.9122],
  [1582848000000, 0.9111],
  [1583107200000, 0.8992],
  [1583193600000, 0.8996],
  [1583280000000, 0.899],
  [1583366400000, 0.894],
  [1583452800000, 0.8822],
  [1583712000000, 0.873],
  [1583798400000, 0.8781],
  [1583884800000, 0.8822],
  [1583971200000, 0.8898],
  [1584057600000, 0.9007],
  [1584316800000, 0.8964],
  [1584403200000, 0.9107],
  [1584489600000, 0.9147],
  [1584576000000, 0.9259],
  [1584662400000, 0.9341],
  [1584921600000, 0.9275],
  [1585008000000, 0.9224],
  [1585094400000, 0.9237],
  [1585180800000, 0.9108],
  [1585267200000, 0.9111],
  [1585526400000, 0.9064],
  [1585612800000, 0.9128],
  [1585699200000, 0.9145],
  [1585785600000, 0.917],
  [1585872000000, 0.9273],
  [1586131200000, 0.9268],
  [1586217600000, 0.9188],
  [1586304000000, 0.92],
  [1586390400000, 0.9203],
  [1586822400000, 0.9123],
  [1586908800000, 0.9173],
  [1586995200000, 0.9185],
  [1587081600000, 0.9209],
  [1587340800000, 0.9209],
  [1587427200000, 0.9229],
  [1587513600000, 0.9203],
  [1587600000000, 0.9284],
  [1587686400000, 0.926],
  [1587945600000, 0.9216],
  [1588032000000, 0.9195],
  [1588118400000, 0.9224],
  [1588204800000, 0.9196],
  [1588550400000, 0.914],
  [1588636800000, 0.9224],
  [1588723200000, 0.9254],
  [1588809600000, 0.9275],
  [1588896000000, 0.9224],
  [1589155200000, 0.924],
  [1589241600000, 0.9211],
  [1589328000000, 0.9196],
  [1589414400000, 0.9267],
  [1589500800000, 0.9262],
  [1589760000000, 0.9233],
  [1589846400000, 0.9133],
  [1589932800000, 0.9127],
  [1590019200000, 0.9092],
  [1590105600000, 0.9172],
  [1590364800000, 0.9167],
  [1590451200000, 0.9113],
  [1590537600000, 0.9099],
  [1590624000000, 0.9079],
  [1590710400000, 0.8981],
  [1590969600000, 0.8997],
  [1591056000000, 0.895],
  [1591142400000, 0.8934],
  [1591228800000, 0.889],
  [1591315200000, 0.8827],
  [1591574400000, 0.8862],
  [1591660800000, 0.8855],
  [1591747200000, 0.8792],
  [1591833600000, 0.8813],
  [1591920000000, 0.8847],
  [1592179200000, 0.8888],
  [1592265600000, 0.8844],
  [1592352000000, 0.8904],
  [1592438400000, 0.8912],
  [1592524800000, 0.8922],
  [1592784000000, 0.8919],
  [1592870400000, 0.8836],
  [1592956800000, 0.8866],
  [1593043200000, 0.893],
  [1593129600000, 0.8919],
  [1593388800000, 0.8863],
  [1593475200000, 0.8931],
  [1593561600000, 0.893],
  [1593648000000, 0.8862],
  [1593734400000, 0.891],
  [1593993600000, 0.8831],
  [1594080000000, 0.8858],
  [1594166400000, 0.8862],
  [1594252800000, 0.8818],
  [1594339200000, 0.8869],
  [1594598400000, 0.8828],
  [1594684800000, 0.8792],
  [1594771200000, 0.8739],
  [1594857600000, 0.8762],
  [1594944000000, 0.8751],
  [1595203200000, 0.8736],
  [1595289600000, 0.874],
  [1595376000000, 0.8638],
  [1595462400000, 0.8645],
  [1595548800000, 0.8616],
  [1595808000000, 0.8504],
  [1595894400000, 0.8536],
  [1595980800000, 0.853],
  [1596067200000, 0.8517],
  [1596153600000, 0.8441],
  [1596412800000, 0.8529],
  [1596499200000, 0.8501],
  [1596585600000, 0.8437],
  [1596672000000, 0.8445],
  [1596758400000, 0.8463],
  [1597017600000, 0.8502],
  [1597104000000, 0.8488],
  [1597190400000, 0.8496],
  [1597276800000, 0.8452],
  [1597363200000, 0.8466],
  [1597622400000, 0.8438],
  [1597708800000, 0.84],
  [1597795200000, 0.8381],
  [1597881600000, 0.844],
  [1597968000000, 0.8498],
  [1598227200000, 0.8442],
  [1598313600000, 0.8466],
  [1598400000000, 0.8483],
  [1598486400000, 0.8471],
  [1598572800000, 0.8394],
  [1598832000000, 0.8376],
  [1598918400000, 0.8343],
  [1599004800000, 0.8432],
  [1599091200000, 0.8466],
  [1599177600000, 0.8446],
  [1599436800000, 0.8458],
  [1599523200000, 0.8486],
  [1599609600000, 0.8495],
  [1599696000000, 0.8441],
  [1599782400000, 0.8437],
  [1600041600000, 0.8421],
  [1600128000000, 0.841],
  [1600214400000, 0.8426],
  [1600300800000, 0.8478],
  [1600387200000, 0.8452],
  [1600646400000, 0.8485],
  [1600732800000, 0.8519],
  [1600819200000, 0.8554],
  [1600905600000, 0.8588],
  [1600992000000, 0.8596],
  [1601251200000, 0.857],
  [1601337600000, 0.8547],
  [1601424000000, 0.8542],
  [1601510400000, 0.851],
  [1601596800000, 0.8526],
  [1601856000000, 0.8499],
  [1601942400000, 0.8479],
  [1602028800000, 0.8497],
  [1602115200000, 0.8501],
  [1602201600000, 0.8479],
  [1602460800000, 0.8476],
  [1602547200000, 0.8485],
  [1602633600000, 0.8512],
  [1602720000000, 0.8549],
  [1602806400000, 0.8518],
  [1603065600000, 0.8486],
  [1603152000000, 0.8468],
  [1603238400000, 0.8438],
  [1603324800000, 0.8461],
  [1603411200000, 0.8436],
  [1603670400000, 0.8462],
  [1603756800000, 0.8453],
  [1603843200000, 0.8528],
  [1603929600000, 0.8545],
  [1604016000000, 0.8549],
  [1604275200000, 0.8583],
  [1604361600000, 0.8547],
  [1604448000000, 0.8533],
  [1604534400000, 0.8436],
  [1604620800000, 0.8426],
  [1604880000000, 0.8416],
  [1604966400000, 0.847],
  [1605052800000, 0.85],
  [1605139200000, 0.8482],
  [1605225600000, 0.8465],
  [1605484800000, 0.8454],
  [1605571200000, 0.8417],
  [1605657600000, 0.8427],
  [1605744000000, 0.8453],
  [1605830400000, 0.8431],
  [1606089600000, 0.8404],
  [1606176000000, 0.8429],
  [1606262400000, 0.8411],
  [1606348800000, 0.8404],
  [1606435200000, 0.8389],
  [1606694400000, 0.8348],
  [1606780800000, 0.8357],
  [1606867200000, 0.8289],
  [1606953600000, 0.8231],
  [1607040000000, 0.8225],
  [1607299200000, 0.8246],
  [1607385600000, 0.8256],
  [1607472000000, 0.8259],
  [1607558400000, 0.8255],
  [1607644800000, 0.8247],
  [1607904000000, 0.8223],
  [1607990400000, 0.8238],
  [1608076800000, 0.8205],
  [1608163200000, 0.8167],
  [1608249600000, 0.8158],
  [1608508800000, 0.8216],
  [1608595200000, 0.8172],
  [1608681600000, 0.8221],
  [1608768000000, 0.8202],
  [1609113600000, 0.8185],
  [1609200000000, 0.8158],
  [1609286400000, 0.8144],
  [1609372800000, 0.815],
  [1609718400000, 0.8134],
  [1609804800000, 0.815],
  [1609891200000, 0.8106],
  [1609977600000, 0.8147],
  [1610064000000, 0.8164],
  [1610323200000, 0.8223],
  [1610409600000, 0.8224],
  [1610496000000, 0.8221],
  [1610582400000, 0.8249],
  [1610668800000, 0.825],
  [1610928000000, 0.829],
  [1611014400000, 0.8244],
  [1611100800000, 0.8265],
  [1611187200000, 0.8226],
  [1611273600000, 0.8226],
  [1611532800000, 0.823],
  [1611619200000, 0.8236],
  [1611705600000, 0.8256],
  [1611792000000, 0.8272],
  [1611878400000, 0.8241],
  [1612137600000, 0.8276],
  [1612224000000, 0.8304],
  [1612310400000, 0.8323],
  [1612396800000, 0.8337],
  [1612483200000, 0.8346],
  [1612742400000, 0.8317],
  [1612828800000, 0.8263],
  [1612915200000, 0.8247],
  [1613001600000, 0.8233],
  [1613088000000, 0.826],
  [1613347200000, 0.8246],
  [1613433600000, 0.8236],
  [1613520000000, 0.8293],
  [1613606400000, 0.8276],
  [1613692800000, 0.8239],
  [1613952000000, 0.8243],
  [1614038400000, 0.8236],
  [1614124800000, 0.8234],
  [1614211200000, 0.8181],
  [1614297600000, 0.8251],
  [1614556800000, 0.8298],
  [1614643200000, 0.8315],
  [1614729600000, 0.8301],
  [1614816000000, 0.8311],
  [1614902400000, 0.8378],
  [1615161600000, 0.8428],
  [1615248000000, 0.8409],
  [1615334400000, 0.841],
  [1615420800000, 0.8356],
  [1615507200000, 0.8381],
  [1615766400000, 0.839],
  [1615852800000, 0.8386],
  [1615939200000, 0.8399],
  [1616025600000, 0.8396],
  [1616112000000, 0.8411],
  [1616371200000, 0.8386],
  [1616457600000, 0.8416],
  [1616544000000, 0.8458],
  [1616630400000, 0.8474],
  [1616716800000, 0.8489],
  [1616976000000, 0.8487],
  [1617062400000, 0.8518],
  [1617148800000, 0.853],
  [1617235200000, 0.8515],
  [1617667200000, 0.8467],
  [1617753600000, 0.8416],
  [1617840000000, 0.8423],
  [1617926400000, 0.8413],
  [1618185600000, 0.8402],
  [1618272000000, 0.8407],
  [1618358400000, 0.8359],
  [1618444800000, 0.8355],
  [1618531200000, 0.8344],
  [1618790400000, 0.831],
  [1618876800000, 0.8299],
  [1618963200000, 0.8329],
  [1619049600000, 0.8303],
  [1619136000000, 0.8289],
  [1619395200000, 0.8276],
  [1619481600000, 0.8274],
  [1619568000000, 0.8286],
  [1619654400000, 0.8246],
  [1619740800000, 0.8278],
  [1620000000000, 0.8304],
  [1620086400000, 0.832],
  [1620172800000, 0.8331],
  [1620259200000, 0.8293],
  [1620345600000, 0.8294],
  [1620604800000, 0.8219],
  [1620691200000, 0.8218],
  [1620777600000, 0.8253],
  [1620864000000, 0.8278],
  [1620950400000, 0.825],
  [1621209600000, 0.8236],
  [1621296000000, 0.8183],
  [1621382400000, 0.819],
  [1621468800000, 0.8196],
  [1621555200000, 0.8206],
  [1621814400000, 0.819],
  [1621900800000, 0.8155],
  [1621987200000, 0.8178],
  [1622073600000, 0.8199],
  [1622160000000, 0.8237],
  [1622419200000, 0.8197],
  [1622505600000, 0.8181],
  [1622592000000, 0.8207],
  [1622678400000, 0.8206],
  [1622764800000, 0.8254],
  [1623024000000, 0.8223],
  [1623110400000, 0.821],
  [1623196800000, 0.8201],
  [1623283200000, 0.8215],
  [1623369600000, 0.8248],
  [1623628800000, 0.8257],
  [1623715200000, 0.826],
  [1623801600000, 0.8249],
  [1623888000000, 0.8378],
  [1623974400000, 0.8406],
  [1624233600000, 0.8411],
  [1624320000000, 0.8409],
  [1624406400000, 0.8369],
  [1624492800000, 0.8379],
  [1624579200000, 0.8369],
  [1624838400000, 0.8397],
  [1624924800000, 0.8413],
  [1625011200000, 0.8416],
  [1625097600000, 0.8416],
  [1625184000000, 0.8459],
  [1625443200000, 0.8428],
  [1625529600000, 0.8448],
  [1625616000000, 0.8453],
  [1625702400000, 0.8448],
  [1625788800000, 0.8434],
  [1626048000000, 0.8438],
  [1626134400000, 0.8444],
  [1626220800000, 0.8467],
  [1626307200000, 0.8469],
  [1626393600000, 0.8474],
  [1626652800000, 0.85],
  [1626739200000, 0.8494],
  [1626825600000, 0.8496],
  [1626912000000, 0.8494],
  [1626998400000, 0.8499],
  [1627257600000, 0.8485],
  [1627344000000, 0.8468],
  [1627430400000, 0.8471],
  [1627516800000, 0.8423],
  [1627603200000, 0.8411],
  [1627862400000, 0.8414],
  [1627948800000, 0.8415],
  [1628035200000, 0.8432],
  [1628121600000, 0.844],
  [1628208000000, 0.8471],
  [1628467200000, 0.8504],
  [1628553600000, 0.8532],
  [1628640000000, 0.8535],
  [1628726400000, 0.852],
  [1628812800000, 0.8501],
  [1629072000000, 0.8496],
  [1629158400000, 0.8499],
  [1629244800000, 0.8531],
  [1629331200000, 0.8551],
  [1629417600000, 0.8569],
  [1629676800000, 0.8535],
  [1629763200000, 0.8519],
  [1629849600000, 0.8522],
  [1629936000000, 0.8499],
  [1630022400000, 0.8504],
  [1630281600000, 0.8475],
  [1630368000000, 0.8451],
  [1630454400000, 0.8463],
  [1630540800000, 0.8443],
  [1630627200000, 0.8424],
  [1630886400000, 0.843],
  [1630972800000, 0.8433],
  [1631059200000, 0.8456],
  [1631145600000, 0.8448],
  [1631232000000, 0.8446],
  [1631491200000, 0.849],
  [1631577600000, 0.8466],
  [1631664000000, 0.8458],
  [1631750400000, 0.8502],
  [1631836800000, 0.849],
  [1632096000000, 0.854],
  [1632182400000, 0.852],
  [1632268800000, 0.8527],
  [1632355200000, 0.8537],
  [1632441600000, 0.8534],
  [1632700800000, 0.8549],
  [1632787200000, 0.8564],
  [1632873600000, 0.8582],
  [1632960000000, 0.8637],
  [1633046400000, 0.8622],
  [1633305600000, 0.8595],
  [1633392000000, 0.862],
  [1633478400000, 0.8665],
  [1633564800000, 0.865],
  [1633651200000, 0.8645],
  [1633910400000, 0.8641],
  [1633996800000, 0.8655],
  [1634083200000, 0.865],
  [1634169600000, 0.862],
  [1634256000000, 0.862],
  [1634515200000, 0.8619],
  [1634601600000, 0.8581],
  [1634688000000, 0.8605],
  [1634774400000, 0.8594],
  [1634860800000, 0.8599],
  [1635120000000, 0.8619],
  [1635206400000, 0.8608],
  [1635292800000, 0.8609],
  [1635379200000, 0.8627],
  [1635465600000, 0.8588],
  [1635724800000, 0.8638],
  [1635811200000, 0.8619],
  [1635897600000, 0.8638],
  [1635984000000, 0.8645],
  [1636070400000, 0.8682],
  [1636329600000, 0.8637],
  [1636416000000, 0.8639],
  [1636502400000, 0.8653],
  [1636588800000, 0.8727],
  [1636675200000, 0.8736],
  [1636934400000, 0.8739],
  [1637020800000, 0.8798],
  [1637107200000, 0.8838],
  [1637193600000, 0.8815],
  [1637280000000, 0.8873],
  [1637539200000, 0.8868],
  [1637625600000, 0.8883],
  [1637712000000, 0.8925],
  [1637798400000, 0.8911],
  [1637884800000, 0.8858],
  [1638144000000, 0.8869],
  [1638230400000, 0.8801],
  [1638316800000, 0.884],
  [1638403200000, 0.882],
  [1638489600000, 0.8858],
  [1638748800000, 0.8861],
  [1638835200000, 0.8885],
  [1638921600000, 0.8851],
  [1639008000000, 0.8842],
  [1639094400000, 0.8872],
  [1639353600000, 0.8868],
  [1639440000000, 0.8844],
  [1639526400000, 0.888],
  [1639612800000, 0.8822],
  [1639699200000, 0.8827],
  [1639958400000, 0.8872],
  [1640044800000, 0.8854],
  [1640131200000, 0.885],
  [1640217600000, 0.8843],
  [1640304000000, 0.8837],
  [1640563200000, 0.8841],
  [1640649600000, 0.8826],
  [1640736000000, 0.8848],
  [1640822400000, 0.8824],
  [1640908800000, 0.883],
];

export { jsonFooter, testDataDummy, application, product };
