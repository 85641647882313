import React from "react";
import { arrowRight, bgChip } from "../../assets";

const Section1 = () => (
  <section
    style={{
      backgroundImage: `url(${bgChip})`,
    }}
    className="w-full h-[879px] bg-no-repeat bg-cover bg-center pt-36"
  >
    <div className="max-w-[1080px] mx-auto">
      <div className="text-primary text-2xl flex gap-6 pl-4">
        <p>MAIN</p>
        <img src={arrowRight} alt={arrowRight} />
        <p>PRODUCT</p>
      </div>
      <div className="text-white uppercase pl-4 pt-14 text-7xl py-8 border-white border-b border-spacing-4">
        reader ic
      </div>
      <h1 className="text-white pt-36 text-6xl">
        기술 표준에 맞는 <br /> 혼합 작업이 가능한 차세대 <br /> Reader IC를
        경험해보세요.
      </h1>
      <p className="text-white py-24 text-lg text-wrap mr-[620px]">
        하드웨어/소프트웨어 전원 관리 기능으로 전력 소비를 최소화하고, 두 개의
        전송 드라이버를 구성한 3ALogics만의 차세대 Reader IC입니다.
      </p>
    </div>
  </section>
);

export default Section1;
