import React from "react";
import { arrowRight } from "../../assets";

const Section1 = () => (
  <section className="relative w-full pt-48 pb-36">
    <div className="max-w-[1080px] mx-auto flex flex-col gap-10 px-10 xl:px-10">
      <div className="text-primary text-2xl flex gap-6">
        <p className="font-medium text-xl text-primary">MAIN</p>
        <img src={arrowRight} alt={arrowRight} />
        <p className="font-medium text-xl text-primary">COMPANY</p>
      </div>
      <h1 className="font-medium text-7xl text-white">회사개요</h1>
      <div className="relative border-b border-white">
        <ul className="flex">
          <li className="relative font-medium text-white text-lg px-10 py-3">
            회사소개
            <span className="w-full h-1 bg-primary absolute bottom-[-3px] rounded-full left-0"></span>
          </li>
          <li className="relative font-medium text-white text-lg px-10 py-3">
            CEO Message
            <span className="w-full h-1 bg-primary opacity-0 absolute bottom-[-3px] rounded-full left-0"></span>
          </li>
          <li className="relative font-medium text-white text-lg px-10 py-3">
            History
            <span className="w-full h-1 bg-primary opacity-0 absolute bottom-[-3px] rounded-full left-0"></span>
          </li>
          <li className="relative font-medium text-white text-lg px-10 py-3">
            CI
            <span className="w-full h-1 bg-primary opacity-0 absolute bottom-[-3px] rounded-full left-0"></span>
          </li>
          <li className="relative font-medium text-white text-lg px-10 py-3">
            Awards
            <span className="w-full h-1 bg-primary opacity-0 absolute bottom-[-3px] rounded-full left-0"></span>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Section1;
