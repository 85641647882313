import React from "react";
import { logoSecondary } from "../../assets";
import { Sticky } from "./childComponent";

const Section5 = () => {
  return (
    <section className="relative w-full grid grid-cols-2 bg-backgroud max-w-[1080px] mx-auto h-auto py-32">
      <div className="h-fit sticky top-28 w-fit flex flex-col justify-start items-start px-2 py-2">
        <img src={logoSecondary} alt={logoSecondary} />
        <span className="max-w-sm bg-black">
          <h2 className="font-bold text-[32px] text-white flex flex-col">
            <span className="trs-esg">3ALOGICS는</span>
            <span className="trs-esg">경제적 가치와 사회적 가치를</span>
            <span className="trs-esg">함께 추구합니다.</span>
            <br />
          </h2>
        </span>
      </div>
      <Sticky />
    </section>
  );
};

export default Section5;
