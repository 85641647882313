import React from "react";
// import { SectionWrapper } from "../../hoc/SectionWrapper";
import { heroIndex } from "../../assets";

const Section1 = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${heroIndex})`,
        }}
        className="w-full  bg-no-repeat bg-cover bg-center flex justify-center items-center h-[945px]"
      >
        <div className="flex flex-row max-w-screen-2xl h-10 w-full mx-aut justify-between items-center">
          <span className="text-2xl text-white font-bold">Smart NFC</span>
          <span className="text-[240px] text-white font-bold">CHOICE</span>
          <span className="text-2xl text-white font-bold">For Your Life</span>
        </div>
      </section>
    </>
  );
};

export default Section1;

// export default SectionWrapper(Section1, "");
