import React, { useState, useEffect, useRef } from "react";
import {
  carsKeleton,
  motherBoardPNG,
  chip,
  electrick,
  cellphone,
} from "../../assets";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { factory } from "../../assets";
import { Swiper, SwiperSlide } from "swiper/react";

import { useGSAP } from "@gsap/react";
import gsap from "gsap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import { TNR } from "./childComponent";

const Section3 = () => {
  useGSAP(() => {
    gsap.to("#text", {
      ease: "power1.inOut",
      opacity: 1,
      y: 0,
    });

    gsap.fromTo(
      ".para",
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        delay: 1,
        stagger: 0.3,
      }
    );
  });

  return (
    <>
      <section className="w-full bg-backgroud py-32 mb-20 flex justify-center items-center h-auto">
        <div className="relative w-full max-w-screen-xl">
          <Swiper
            modules={[A11y, Autoplay, Pagination]}
            spaceBetween={600}
            slidesPerView={1}
            initialSlide="0"
            direction={"vertical"}
            // navigation
            loop
            pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            autoplay
            mousewheel={{
              invert: false,
            }}
            className="mySwiper w-full overflow-y-hidden overflow-x-hidden h-[600px] py-3 flex flex-col relative custom-pag"
          >
            <SwiperSlide>
              <div className=" w-full grid grid-cols-2 text-white px-10 max-w-[1150px] h-screen mx-auto gap-5 ">
                <div className="flex flex-col justify-center items-start gap-10 max-w-md h-fit  mt-20 ">
                  <h1 className="text-white font-bold text-7xl trs-esg">
                    Automotive
                  </h1>
                  <p className="font-normal text-white text-xl trs-esg">
                    근거리 무선통신 Reader IC를 활용한 국내 유일의 차량용
                    Digital Key와, <br />
                    HBM(Human Body Model) 테스트를 통해 세계 최고 성능(8kV)의
                    ESD 검증을 거친 집적 회로 설계를 제공합니다.
                  </p>
                  <span className="h-[90px] w-[90px] flex justify-center items-center bg-primary rounded-full mt-16">
                    <a href="/#" className="text-black">
                      More
                    </a>
                  </span>
                </div>
                <div className="flex flex-col justify-start items-start gap-10 h-fit mt-2">
                  <div
                    style={{
                      backgroundImage: `url(${carsKeleton})`,
                    }}
                    className="max-w-[640px] min-h-[360px] w-full h-full bg-slate-600 bg-no-repeat bg-cover rounded-xl"
                  ></div>
                  <div className="w-full flex gap-10 h-auto">
                    <TNR type={`TNR100`} />
                    <TNR type={`TNR200`} />
                  </div>
                  <div className="flex flex-row w-full bg-secondary"></div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=" w-full grid grid-cols-2 text-white px-10 max-w-[1150px] h-screen mx-auto gap-5">
                <div className="flex flex-col justify-center items-start gap-10 max-w-md h-fit  mt-20 ">
                  <h1 className="text-white font-bold text-7xl trs-esg">
                    Chipset
                  </h1>
                  <p className="font-normal text-white text-xl trs-esg">
                    근거리 무선통신 Reader IC를 활용한 국내 유일의 차량용
                    Digital Key와, <br />
                    HBM(Human Body Model) 테스트를 통해 세계 최고 성능(8kV)의
                    ESD 검증을 거친 집적 회로 설계를 제공합니다.
                  </p>
                  <span className="h-[90px] w-[90px] flex justify-center items-center bg-primary rounded-full mt-16">
                    <a href="/#" className="text-black">
                      More
                    </a>
                  </span>
                </div>
                <div className="flex flex-col justify-start items-start gap-10 h-fit mt-2">
                  <div
                    style={{
                      backgroundImage: `url(${chip})`,
                    }}
                    className="max-w-[640px] min-h-[360px] w-full h-full bg-slate-600 bg-no-repeat bg-cover rounded-xl"
                  ></div>
                  <div className="w-full flex gap-10 h-auto">
                    <TNR type={`TNR100`} />
                    <TNR type={`TNR200`} />
                  </div>
                  <div className="flex flex-row w-full bg-secondary"></div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=" w-full grid grid-cols-2 text-white px-10 max-w-[1150px] h-screen mx-auto gap-5">
                <div className="flex flex-col justify-center items-start gap-10 max-w-md h-fit  mt-20 ">
                  <h1 className="text-white font-bold text-7xl trs-esg">
                    Motherboard
                  </h1>
                  <p className="font-normal text-white text-xl trs-esg">
                    근거리 무선통신 Reader IC를 활용한 국내 유일의 차량용
                    Digital Key와, <br />
                    HBM(Human Body Model) 테스트를 통해 세계 최고 성능(8kV)의
                    ESD 검증을 거친 집적 회로 설계를 제공합니다.
                  </p>
                  <span className="h-[90px] w-[90px] flex justify-center items-center bg-primary rounded-full mt-16">
                    <a href="/#" className="text-black">
                      More
                    </a>
                  </span>
                </div>
                <div className="flex flex-col justify-start items-start gap-10 h-fit mt-2">
                  <div
                    style={{
                      backgroundImage: `url(${motherBoardPNG})`,
                    }}
                    className="max-w-[640px] min-h-[360px] w-full h-full bg-slate-600 bg-no-repeat bg-cover rounded-xl"
                  ></div>
                  <div className="w-full flex gap-10 h-auto">
                    <TNR type={`TNR100`} />
                    <TNR type={`TNR200`} />
                  </div>
                  <div className="flex flex-row w-full bg-secondary"></div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=" w-full grid grid-cols-2 text-white px-10 max-w-[1150px] h-screen mx-auto gap-5">
                <div className="flex flex-col justify-center items-start gap-10 max-w-md h-fit  mt-20 ">
                  <h1 className="text-white font-bold text-7xl trs-esg">
                    Electric Car
                  </h1>
                  <p className="font-normal text-white text-xl trs-esg">
                    근거리 무선통신 Reader IC를 활용한 국내 유일의 차량용
                    Digital Key와, <br />
                    HBM(Human Body Model) 테스트를 통해 세계 최고 성능(8kV)의
                    ESD 검증을 거친 집적 회로 설계를 제공합니다.
                  </p>
                  <span className="h-[90px] w-[90px] flex justify-center items-center bg-primary rounded-full mt-16">
                    <a href="/#" className="text-black">
                      More
                    </a>
                  </span>
                </div>
                <div className="flex flex-col justify-start items-start gap-10 h-fit mt-2">
                  <div
                    style={{
                      backgroundImage: `url(${electrick})`,
                    }}
                    className="max-w-[640px] min-h-[360px] w-full h-full bg-slate-600 bg-no-repeat bg-cover rounded-xl"
                  ></div>
                  <div className="w-full flex gap-10 h-auto">
                    <TNR type={`TNR100`} />
                    <TNR type={`TNR200`} />
                  </div>
                  <div className="flex flex-row w-full bg-secondary"></div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=" w-full grid grid-cols-2 text-white px-10 max-w-[1150px] h-screen mx-auto gap-5">
                <div className="flex flex-col justify-center items-start gap-10 max-w-md h-fit  mt-20 ">
                  <h1 className="text-white font-bold text-7xl trs-esg">
                    Cellphone
                  </h1>
                  <p className="font-normal text-white text-xl trs-esg">
                    근거리 무선통신 Reader IC를 활용한 국내 유일의 차량용
                    Digital Key와, <br />
                    HBM(Human Body Model) 테스트를 통해 세계 최고 성능(8kV)의
                    ESD 검증을 거친 집적 회로 설계를 제공합니다.
                  </p>
                  <span className="h-[90px] w-[90px] flex justify-center items-center bg-primary rounded-full mt-16">
                    <a href="/#" className="text-black">
                      More
                    </a>
                  </span>
                </div>
                <div className="flex flex-col justify-start items-start gap-10 h-fit mt-2">
                  <div
                    style={{
                      backgroundImage: `url(${cellphone})`,
                    }}
                    className="max-w-[640px] min-h-[360px] w-full h-full bg-slate-600 bg-no-repeat bg-cover rounded-xl"
                  ></div>
                  <div className="w-full flex gap-10 h-auto">
                    <TNR type={`TNR100`} />
                    <TNR type={`TNR200`} />
                  </div>
                  <div className="flex flex-row w-full bg-secondary"></div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="absolute top-0 w-full flex flex-col justify-center items-start">
            <span className="w-full max-w-screen-xl mx-auto h-10 inline-block">
              <h1 className="text-primary font-bold text-xl uppercase ml-[100px]">
                What We Have Product
              </h1>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section3;
