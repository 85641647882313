import React, { useEffect, useState } from "react";
import { wirelesscharge, healtCare, iot, tag } from "../../assets";
import { application } from "../../constans";

const Section2 = () => {
  const [data_section_two, setData_section_two] = useState([]);
  useEffect(() => {
    setData_section_two(application.kr.section_two);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entri) => {
        if (entri.isIntersecting) {
          entri.target.classList.add("show-animate-scroll");
        } else {
          // entri.target.classList.remove("show-animate-scroll");
        }
      });
    });

    const fadeSection = document.querySelectorAll(".fade-scroll");
    // observe all hidden element
    fadeSection.forEach((intercetion) => observer.observe(intercetion));
  }, []);
  return (
    <>
      <section className="max-w-[1080px] w-full mx-auto">
        <section className="grid grid-cols-1 w-full h-auto pt-32">
          <div className="flex flex-col w-full mb-10 fade-scroll">
            <div className="rounded-md shadow-md">
              <div
                className="md:grid flex flex-col-reverse md:grid-cols-2 w-full"
                dir="ltr"
              >
                <div className="w-full pr-20 h-fit">
                  <h1 className="mb-6 text-primary font-semibold text-left text-xl">
                    WIRELESS CHARGE
                  </h1>
                  <p className="mb-6 text-white font-semibold text-left text-6xl">
                    무선충전
                  </p>
                  <div className="mb-6 text-white font-semibold">
                    <ul className="list-disc list-inside">
                      소형 폼팩터 제품 및 장치에 케이블이 없는 전원의 편리성을
                      추가
                    </ul>
                    <ul className="list-disc list-inside">
                      올인원 무선충전 및 데이터 통신 가능
                    </ul>
                    <ul className="list-disc list-inside">
                      NFC 무선충전 표준화 및 Qi 무선 충전 플랫폼 보완 (소형
                      폼팩터, in-band 통신, NFC 기능 병행 사용 등)
                    </ul>
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${wirelesscharge})`,
                  }}
                  className="flex items-center w-full h-full min-h-[341px] min-w-[512px] bg-no-repeat bg-cover"
                ></div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full mb-10 fade-scroll">
            <div className="rounded-md shadow-md">
              <div
                className="md:grid flex flex-col-reverse md:grid-cols-2 w-full"
                dir="rtl"
              >
                <div className="w-full pl-20" dir="ltr">
                  <h1 className="mb-6 text-primary font-semibold text-left pb-[30px] text-xl">
                    HEALTHCARE DEVICE
                  </h1>
                  <p className="mb-6 text-white font-semibold text-left text-6xl pb-[60px]">
                    CGMS
                  </p>
                  <div className="mb-6 text-white font-semibold">
                    <ul className="list-disc list-inside">
                      NFC 헬스케어 디바이스 연결 후 정보 수집 및 데이터 디지털화
                    </ul>
                    <ul className="list-disc list-inside">
                      CGMS는 연속혈당측정기로 Disposable 형태의 제품으로 공급
                    </ul>
                    <ul className="list-disc list-inside">
                      NFC를 내장하여 시스템 Wakeup, 환자 인증, 혈당 Data 수집
                    </ul>
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${healtCare})`,
                  }}
                  className="flex items-center w-full h-full min-h-[341px] min-w-[512px] bg-no-repeat bg-cover"
                ></div>
              </div>
            </div>
          </div>
        </section>
        <section className="grid grid-cols-1 w-full h-auto pt-32">
          <div className="flex flex-col w-full mb-10 fade-scroll">
            <div className="rounded-md shadow-md">
              <div
                className="md:grid flex flex-col-reverse md:grid-cols-2 w-full"
                dir="ltr"
              >
                <div dir="ltr" className="w-full pr-20 h-fit">
                  <h1 className="mb-6 text-primary font-semibold text-left text-xl">
                    ITEM LEVEL TAG
                  </h1>
                  <p className="mb-6 text-white font-semibold text-left text-6xl">
                    차세대 스티커 태그
                  </p>
                  <div className="mb-6 text-white font-semibold">
                    <ul className="list-disc list-inside">
                      UHF RFID는 Item level Tag로 바코드를 대체하는 기술로 자산
                      관리, 재고 관리, 유통 관리에 사용
                    </ul>
                    <ul className="list-disc list-inside">
                      RFID로 유통/재고/생산/매장 관리
                    </ul>
                    <ul className="list-disc list-inside">
                      BLE 통신으로 중간 관리자 별도의 AP 없이 Item 모니터링 가능
                    </ul>
                    <ul className="list-disc list-inside">
                      BLE 통신으로 고객과의 무선 연결로 경험치 축적
                    </ul>
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${tag})`,
                  }}
                  className="flex items-center w-full h-full min-h-[341px] min-w-[512px] bg-no-repeat bg-cover"
                ></div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full mb-10 fade-scroll">
            <div className="rounded-md shadow-md">
              <div
                className="md:grid flex flex-col-reverse md:grid-cols-2 w-full"
                dir="rtl"
              >
                <div className="w-full pl-20" dir="ltr">
                  <h1 className="mb-6 text-primary font-semibold text-left pb-[30px] text-xl">
                    SMART IoT
                  </h1>
                  <p className="mb-6 text-white font-semibold text-left text-6xl pb-[60px]">
                    Consumer Device
                  </p>
                  <div className="mb-6 text-white font-semibold">
                    <ul className="list-disc list-inside">
                      가정 및 직장에서 스마트 기기와 사물을 쉽게 설정하고 연결
                    </ul>
                    <ul className="list-disc list-inside">
                      친숙한 NFC 탭 상호작용으로 소비자 경험 향상
                    </ul>
                    <ul className="list-disc list-inside">
                      연결을 통해 에너지를 전송할 수 있으므로 연결될 장치에
                      전원이 필요 없음
                    </ul>
                    <ul className="list-disc list-inside">
                      NFC와 연결하여 데이터를 읽고 씀과 동시에 전원 공급
                    </ul>
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${iot})`,
                  }}
                  className="flex items-center w-full h-full min-h-[341px] min-w-[512px] bg-no-repeat bg-cover"
                ></div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Section2;
