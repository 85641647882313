import React from "react";
import { StickyCard } from "./childCompany";

const Section3 = () => (
  <section className="w-full max-w-[1080px] text-white mx-auto py-24 pl-10">
    <div className="relative w-full grid grid-cols-2 bg-backgroud mx-auto">
      <div className="h-fit sticky top-28 w-fit flex flex-col justify-start items-start px-2 py-2">
        <h3 className="text-sm font-bold text-primary pb-10">OUR VISION</h3>
        <span className="max-w-sm bg-black">
          <h2 className="font-bold text-[32px] text-white">
            3ALOGICS는 <br />
            경제적 가치와 사회적 가치를 <br />
            함께 추구합니다.
          </h2>
        </span>
        <p className="font-medium text-base text-white max-w-[400px] mt-10">
          3ALogics는 독보적인 기술 경쟁력을 바탕으로, <br />
          IoT 핵심 근거리 통신부품의 국산화에 성공한 Korea Leading
          Company입니다. 창조적인 생각과 행동, 그리고 혁신적인 기술 개발로
          IoT-World를 선도합니다.
        </p>
      </div>
      <StickyCard />
    </div>
  </section>
);

export default Section3;
