import React from "react";
import { env, familys, building } from "../../../assets";

const Sticky = () => {
  return (
    <div className="relative w-full flex flex-col gap-24 justify-end items-end py-10">
      <div className="relative animation-sticky-index flex mb-[-100px]">
        <img src={env} alt={env} className="rounded-xl" />
        <span className="absolute left-0 px-10 bottom-[-10%]">
          <h1 className="text-white text-[65px] font-bold">Environtment</h1>
        </span>
      </div>
      <div className="relative animation-sticky-index flex pt-28 mb-[-100px]">
        <img src={familys} alt={familys} className="rounded-xl" />
        <span className="absolute left-0 px-10 bottom-[-10%]">
          <h1 className="text-white text-[65px] font-bold">Society</h1>
        </span>
      </div>
      <div className="relative animation-sticky-index flex pt-28">
        <img src={building} alt={building} className="rounded-xl" />
        <span className="absolute left-0 px-10 bottom-[-10%]">
          <h1 className="text-white text-[65px] font-bold">Governance</h1>
        </span>
      </div>
    </div>
  );
};

export default Sticky;
