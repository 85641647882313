import React from "react";
import { Section1, Section2 } from "../components/application";
import { Contact } from "../components/globals";
import { Layout } from "../components/layouts";
const Application = () => {
  return (
    <Layout>
      <div className="bg-backgroud w-full">
        <div className="bg-backgroud mx-auto">
          <Section1 />
          <Section2 />
          <Contact />
        </div>
      </div>
    </Layout>
  );
};

export default Application;
